import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import dayjs from 'dayjs';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { SelectProperty } from 'common/select-property';
import { useTranslation } from 'react-i18next';
import {
  useContext, useEffect, useReducer, useState,
} from 'react';
import { Modal } from '@mapix/common/src/common/modal';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from 'helpers/logger';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { Pagination } from '@mapix/common/src/common/paginator';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { FilterByDate } from 'common/filter-by-date';
import { Menu } from 'common/menu';
import { AppLink, RouteName, goToPage } from 'routes';
import { EmptyState } from 'common/empty-state';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { AppContext } from 'context';
import { FiltersApplied } from 'common/filters-applied';
import styles from './claims.module.scss';
import {
  initialState,
  ClaimsReducer,
} from './claims-reducer';

type List = {
  name: string,
  route: RouteName,
};

type CardProps = {
  category: string,
  creationDate: string,
  status: string,
  id: number,
  mobile: boolean,
  t: (key: string) => string,
  creationDateInfo: (date: string) => string,
  getIcon: (status: string) => JSX.Element,
};

const Card = ({
  id, category, creationDate, status, mobile, t, creationDateInfo, getIcon,
}: CardProps) => (
  <>
    {mobile
      ? (
        <AppLink routeName={RouteName.ClaimDetail} pathParams={{ id }} className={styles.link}>
          <div className={styles.cardMobile}>
            <div className={styles.column}>
              <p className={classnames(styles.bold, 'text__heading3__textNeutral40')}>
                {t(`claims.filters.category.${category}`)}
              </p>
              <div className="row">
                <p className={classnames(styles.created, 'text__body__medium__textNeutral40')}>
                  {`${t('claims.created')}:`}
                </p>
                <p className="text__body__small__textNeutral40">
                  {creationDateInfo(creationDate)}
                </p>
              </div>
              {getIcon(status)}
            </div>
            <ChevronRight className={styles.chevron} />
          </div>
        </AppLink>
      )
      : (
        <AppLink routeName={RouteName.ClaimDetail} pathParams={{ id }} className={styles.link}>
          <div className={styles.card}>
            <div className={styles.column}>
              <div className="row">
                <p className="text__heading4__textNeutral50">
                  {t(`claims.filters.category.${category}`)}
                </p>
                {getIcon(status)}
              </div>
              <div className="row">
                <p className={classnames(styles.created, 'text__body__small__textNeutral40')}>
                  {t('claims.created')}
                </p>
                <p className="text__body__small__textNeutral40">
                  {creationDateInfo(creationDate)}
                </p>
              </div>
            </div>
            <ChevronRight className={styles.chevron} />
          </div>
        </AppLink>
      )}
  </>
);

const Claims = () => {
  const { t } = useTranslation();
  const [stateReducer, dispatch] = useReducer(ClaimsReducer, initialState);
  const { state } = useContext(AppContext);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);

  const listPath: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
  ];
  const getClaims = async () => {
    try {
      setFetching(true);
      const response = await MaintenanceController
        .getClaimsOfTenant(
          stateReducer.paginator?.currentPage || 1,
          4,
          stateReducer.filterDate,
          state.property,
          stateReducer.query,
          stateReducer.appliedFilters,
        );
      dispatch({ type: 'TASKS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setErrorServer(true);
      setFetching(false);
    }
  };

  useEffect(() => {
    getClaims();
  }, [stateReducer.paginator?.currentPage, stateReducer.appliedFilters,
    stateReducer.query, stateReducer.filterDate, state.property]);

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !stateReducer.show });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== stateReducer.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const applyFilterDate = (startDate: string, endDate: string, code: string) => {
    dispatch({
      type: 'APPLY_FILTER_DATE',
      filter: {
        startDate,
        endDate,
        code,
      },
    });
  };

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(stateReducer.appliedFilters, filter, 'code');
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const getIcon = (status: string) => {
    if (status === 'New') {
      return (
        <p className={styles.iconNew}>{t('claims.filters.status.new')}</p>);
    }
    if (status === 'In Progress') {
      return (
        <p className={styles.iconInProgress}>{t('claims.filters.status.inProgress')}</p>);
    }
    if (status === 'Solved') {
      return (
        <p className={styles.iconSolved}>{t('claims.filters.status.solved')}</p>);
    }
    return (
      <p className={styles.iconRejected}>{t('claims.filters.status.rejected')}</p>);
  };

  const creationDateInfo = (creationDate: string) => {
    const formatDate = `${dayjs(creationDate).format('DD/MM/YYYY')}`;
    return formatDate;
  };

  const goToCreateClaim = () => {
    if (state.property.unitId) {
      goToPage(RouteName.CreateClaimUnit, {
        propertyId: Number(state.property.id),
        unitId: Number(state.property.unitId),
      });
    } else {
      goToPage(RouteName.CreateClaim, { propertyId: Number(state.property.id) });
    }
  };

  const getData = () => {
    if (stateReducer.paginator.results.length === 0 && stateReducer.appliedFilters.length === 0
      && stateReducer.filterDate.startDate === ''
  && stateReducer.filterDate.endDate === '') {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Tool}
            title={t('emptyState.claims.title')}
            subtitle={t('emptyState.claims.subtitle')}
            iconStyle={styles.toolIcon}
            withPlusIcon
            handleOnClick={() => goToCreateClaim()}
          />
        </div>
      );
    }
    return (
      <div className={styles.data}>
        {stateReducer.paginator.results.map(
          (elem) => (
            <Card
              category={elem.category}
              creationDate={elem.startDate}
              status={elem.status}
              id={elem.id}
              creationDateInfo={creationDateInfo}
              getIcon={getIcon}
              mobile={mobile}
              t={t}
            />
          ),
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {mobile
      && <Menu list={listPath} className={styles.menu} />}
      {stateReducer.show && (
      <Modal>
        <FilterModal
          appliedFilters={stateReducer.appliedFilters}
          filters={stateReducer.data.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix="claims"
        />
      </Modal>
      )}

      <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral40'}>
        {t('claims.title')}
      </div>
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t('claims.subtitle')}
      </p>
      <div className={styles.showingContainer}>
        <p className={classnames(styles.showing, mobile ? 'text__body__medium__textNeutral40' : 'text__body__small__textNeutral50')}>
          {`${t('claims.showing')}: `}
        </p>
        <div className={styles.select}>
          <SelectProperty />
        </div>
      </div>
      { mobile
        ? (
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonsLeft}>
              <Button
                onClick={() => showModal()}
                buttonStyle={ButtonStyle.Secondary}
              >
                <div className={styles.filterContainer}>
                  {stateReducer.appliedFilters.length > 0 && (
                  <div className={styles.quantityFilters}>
                    <div className={styles.number}>
                      {stateReducer.appliedFilters.length}
                    </div>
                  </div>
                  )}
                  <Filter className={styles.filterIcon} />
                </div>
              </Button>
              <div className={styles.filter}>
                <FilterByDate
                  onClickOption={applyFilterDate}
                  mobile={mobile}
                />
              </div>
            </div>
            <Button
              className={styles.claimButton}
              buttonStyle={ButtonStyle.Primary}
              onClick={() => goToCreateClaim()}
            >
              <div className="row align-justify-center">
                <PlusCircle className={styles.plusIcon} />
                {t('claims.claim')}
              </div>
            </Button>
          </div>
        )
        : (
          <div className={styles.buttonsContainer}>
            <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
              <div className="row justify-between">
                {t('maintenanceDashboard.buttons.filter')}
                <Filter className={styles.filterIcon} />
              </div>
            </Button>
            {stateReducer.appliedFilters.length > 0
              && (
              <FiltersApplied
                filters={stateReducer.appliedFilters}
                clearFn={clearFilters}
                removeOneFilterFn={removeFilter}
                prefix="claims"
              />
              )}
            <div className={styles.buttonsRight}>
              <div className={styles.filter}>
                <FilterByDate
                  onClickOption={applyFilterDate}
                />
              </div>
              <Button
                className={styles.claimButton}
                buttonStyle={ButtonStyle.Primary}
                onClick={() => goToCreateClaim()}
              >
                <div className="row align-justify-center">
                  <PlusCircle className={styles.plusIcon} />
                  {t('claims.claim')}
                </div>
              </Button>
            </div>
          </div>
        )}
      { fetching
        ? <div className={styles.spinner}><Spinner fixed={false} /></div>
        : getData()}
      {stateReducer.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={stateReducer.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={stateReducer.paginator.currentPage}
              />
            </div>
          )}
    </div>
  );
};

export { Claims };
