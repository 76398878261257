import { Paginator } from 'models/paginator';
import { ModalFilterItem, ModalFilterObject } from 'common/filter-modal/filter-modal';
import { RentalDashboard } from 'models/rental-dashboard';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { DateCodes } from 'common/enums';

enum RentalDashboardTabs {
  Building = 'building',
  CondoHouse = 'condoHouse',
}

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: DateCodes.All,
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  dateFilters: DateFilter,
  tab: string | undefined,
};

const headerCondo = ['address', 'landlord', 'tenant', 'billedAmount', 'dueDate', 'paidOn', 'status'];
const headerBuilding = ['unit', 'address', 'landlord', 'tenant', 'billedAmount', 'dueDate', 'paidOn', 'status'];

const filters: ModalFilterObject[] = [
  {
    title: 'Status',
    type: 'status',
    items: [
      { name: 'Pending', code: 'pending', parentType: 'status' },
      { name: 'Overdue', code: 'overdue', parentType: 'status' },
      { name: 'Paid', code: 'paid', parentType: 'status' },
    ],
  },
];

type RentalState = {
  tableDataProperties: string[],
  tableHeaders: string[],
  paginator: Paginator<RentalDashboard>,
  appliedFilters: ModalFilterItem[],
  filters: ModalFilterObject[],
  code: string,
  show: boolean,
  query: string,
  input: string,
  showRegister: boolean,
  rental?: RentalDashboard,
  showDetails: boolean,
  showEdit: boolean,
  filterModal: boolean,
  infoModal: boolean,
  dateFilter: DateFilter,
};

const defineTableHeaders = (tableHeaders: string[], building?: boolean): string[] => {
  if (tableHeaders.length > 0) {
    return tableHeaders;
  }
  return building ? headerBuilding : headerCondo;
};

const defineTableDataProperties = (
  tableDataProperties: string[],
  building?: boolean,
): string[] => {
  if (tableDataProperties.length > 0) {
    return tableDataProperties;
  }
  return building ? headerBuilding : headerCondo;
};

const initialStateBuilding = (
  tableHeaders: string[],
  tableDataProperties: string[],
  building?: boolean,
  urlFilters?: URLFilters,
):
RentalState => ({
  tableHeaders: defineTableHeaders(tableHeaders, building),
  tableDataProperties: defineTableDataProperties(tableDataProperties, building),
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: urlFilters?.appliedFilters || [],
  show: false,
  query: '',
  input: '',
  showRegister: false,
  showDetails: false,
  showEdit: false,
  filters,
  filterModal: false,
  infoModal: false,
  dateFilter: urlFilters?.dateFilters || initDateFilter,
});

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'RENTALS_FETCHED', paginator: Paginator<RentalDashboard> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'SHOW_REGISTER', rental: RentalDashboard }
  | { type: 'SHOW_DETAIL', rental: RentalDashboard }
  | { type: 'SHOW_EDIT', rental: RentalDashboard }
  | { type: 'HIDE_REGISTER' }
  | { type: 'HIDE_DETAIL' }
  | { type: 'HIDE_EDIT' }
  | { type: 'TOGGLE_FILTER_MODAL' }
  | { type: 'TOGGLE_INFO_MODAL' }
  | { type: 'APPLY_DATE_FILTERS', dateFilter: DateFilter }
  | { type: 'INPUT_CHANGED', input: string };

const changePage = (
  currentPage: number,
  paginator: Paginator<RentalDashboard>,
): Paginator<RentalDashboard> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

function CommonReducer(state: RentalState, action: Action): RentalState {
  switch (action.type) {
    case 'RENTALS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
        filterModal: false,
        paginator: changePage(1, state.paginator),
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        paginator: changePage(1, state.paginator),
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'SHOW_REGISTER':
      return {
        ...state,
        showRegister: true,
        rental: action.rental,
      };
    case 'HIDE_REGISTER':
      return {
        ...state,
        showRegister: false,
      };
    case 'HIDE_DETAIL':
      return {
        ...state,
        showDetails: false,
      };
    case 'SHOW_DETAIL':
      return {
        ...state,
        showDetails: true,
        rental: action.rental,
      };
    case 'SHOW_EDIT':
      return {
        ...state,
        showEdit: true,
        rental: action.rental,
      };
    case 'HIDE_EDIT':
      return {
        ...state,
        showEdit: false,
      };
    case 'TOGGLE_FILTER_MODAL':
      return {
        ...state,
        filterModal: !state.filterModal,
      };
    case 'TOGGLE_INFO_MODAL':
      return {
        ...state,
        infoModal: !state.infoModal,
      };
    case 'APPLY_DATE_FILTERS':
      return {
        ...state,
        dateFilter: action.dateFilter,
      };
    default:
      return {
        ...state,
      };
  }
}

export { CommonReducer, RentalDashboardTabs, initialStateBuilding };
export type { RentalState };
