import { Breakpoints, Currency } from 'common/enums';
import { ChangeEvent, FormEvent, SetStateAction } from 'react';
import { RentInfo } from '../../rent-info';
import { Input, InputStyle } from '../../input';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { classnames, HTMLValidationError } from '../../../helpers/utils';
import { RentalTenant } from '../../../types';
import { Select } from '../../select';
import styles from './make-payment-information.module.scss';
import { BottomButtonsMakePayment } from '../bottom-buttons-make-payment/bottom-buttons-make-payment';

type SelectPaymentMethodProps = {
  t: (text: string) => string,
  onCancel: () => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  formErrors: HTMLValidationError,
  rentalInfo: RentalTenant | null,
  makePaymentInformation: MakePaymentInformationValues
  toggleShowInfoModal: () => void,
  setMakePaymentInformation: (
    makePaymentInformation: SetStateAction<MakePaymentInformationValues>) => void
};

export type MakePaymentInformationValues = {
  amount: string,
  reference: string,
  currency: string,
};

const translPrefix = 'paymentMethod';
const MakePaymentInformation = ({
  t, onCancel, rentalInfo, formErrors, onSubmit,
  makePaymentInformation, setMakePaymentInformation, toggleShowInfoModal,
}: SelectPaymentMethodProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setMakePaymentInformation((prevState: MakePaymentInformationValues) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const disabledNext = !makePaymentInformation.amount || !makePaymentInformation.reference
  || !makePaymentInformation.currency;

  return (

    <form noValidate onSubmit={onSubmit} className={styles.container}>
      <RentInfo payModal rental={rentalInfo} translPrefix="tenantPayments.card" t={t} toggleShowInfoModal={toggleShowInfoModal} />
      {mobile && (
      <div className={classnames('text__heading2__textNeutral40')}>
        {t(`${translPrefix}.payTitle`)}
      </div>
      )}
      <div className={classnames(styles.inputContainer, styles.storedCard)}>
        <Select
          required
          formError={formErrors}
          id="currency"
          value={`${rentalInfo?.currency}$`}
          label={t(`${translPrefix}.currency`)}
          placeholder={t(`${translPrefix}.currency`)}
          options={Object.values(Currency)}
          onClickOption={(option) => setMakePaymentInformation(
            (prevState: MakePaymentInformationValues) => ({
              ...prevState,
              currency: option,
            }),
          )}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          containerClass={classnames(styles.select, styles.input)}
          translationPrefix={`${translPrefix}.status`}
          t={t}
          disabled
        />

        <Input
          type="number"
          inputStyle={InputStyle.FORM}
          onChange={onChangeInput}
          value={makePaymentInformation.amount}
          t={t}
          id="amount"
          placeholder={t(`${translPrefix}.amount`)}
          formError={formErrors}
          required
          max={rentalInfo?.amount}
        />

      </div>
      <div className={mobile ? classnames(styles.inputContainer, styles.storedCard) : ''}>

        <Input
          inputStyle={InputStyle.FORM}
          onChange={onChangeInput}
          value={makePaymentInformation.reference}
          t={t}
          id="reference"
          placeholder={t(`${translPrefix}.reference`)}
          formError={formErrors}
          required
        />
      </div>

      <BottomButtonsMakePayment
        onCancel={onCancel}
        disabledButtonRight={disabledNext}
        textButtonRight={t(`${translPrefix}.next`)}
        t={t}
        translPrefix={translPrefix}
        mobile={mobile}
      />

    </form>

  );
};

export { MakePaymentInformation };
