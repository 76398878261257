import { RouteName, goToPage } from 'routes';

import { useTranslation } from 'react-i18next';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { parseTaskStatus } from 'helpers/utils';
import { NewTask } from 'networking/types';
import styles from './new-task-detail.module.scss';
import { Footer, Header, TaskDetailTabs } from './components';

const breadcrumbs = (
  t: (text: string) => string,
  taskName: string,
  translPrefix: string,
): BreadcrumbsItem[] => [
  {
    name: t(`${translPrefix}.maintenance`),
    goToPageFn: () => goToPage(RouteName.Maintenance),
  },
  {
    name: taskName,
    goToPageFn: () => null,
  },
  {
    name: t(`${translPrefix}.taskAndRates`),
    goToPageFn: () => null,
  },
];

type NewTaskDetailProps = {
  newTask: NewTask,
  currentTab: TaskDetailTabs,
  setCurrentTab: (tab: string) => void,
  getTabContent: () => JSX.Element | null,
  showAlert?: (option: string) => boolean,
  disableRightButton?: boolean,
  withTooltip?: boolean,
  textButtonRight: string,
  textButtonLeft: string,
  translPrefix: string,
  toolTipText?: string,
  leftButtonFn: () => void,
  rightButtonFn?: () => void,
};

const NewTaskDetail = ({
  newTask, currentTab, setCurrentTab, getTabContent, showAlert = () => false,
  disableRightButton, withTooltip, textButtonRight, textButtonLeft, toolTipText = '',
  translPrefix, leftButtonFn, rightButtonFn,
}: NewTaskDetailProps) => {
  const { t } = useTranslation();

  const taskStatus = parseTaskStatus(newTask.status);

  return (
    <div className={styles.containerWrapper}>
      <Breadcrumbs
        values={breadcrumbs(t, newTask.name, `${translPrefix}.breadcrumbs`)}
      />
      <div className={styles.container}>
        <Header
          newTask={newTask}
          translPrefix={translPrefix}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          taskStatus={taskStatus}
          showAlert={showAlert}
        />
        {getTabContent()}
        <Footer
          translPrefix={translPrefix}
          taskStatus={taskStatus}
          disableRightButton={disableRightButton}
          withTooltip={withTooltip}
          textButtonRight={textButtonRight}
          textButtonLeft={textButtonLeft}
          toolTipText={toolTipText}
          leftButtonFn={leftButtonFn}
          rightButtonFn={rightButtonFn}
        />
      </div>
    </div>

  );
};

export { NewTaskDetail };
