import { classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { WhiteBoxTitled } from '@mapix/common/src/common/white-box-titled';
import { NewTask } from 'networking/types';
import { TypeOfCost } from '@mapix/common/src/types';
import styles from './status-tab.module.scss';

type UnassignedTaskDetailRowProps = {
  t: (key: string) => string,
  newTask: NewTask,
  translPrefix: string,
  typeOfCostObject?: TypeOfCost | null
};

const UnassignedTaskDetailRow = ({
  t, newTask, translPrefix, typeOfCostObject,
}: UnassignedTaskDetailRowProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const getTypeOfCostContent = () => {
    if (!typeOfCostObject) return '';

    if (typeOfCostObject.typeOfCost === 'budget') {
      return typeOfCostObject.budget.toString();
    }

    return typeOfCostObject.hourlyRate!.toString();
  };

  return (
    <div className={styles.unassignedTaskDetailContainer}>
      <div className={classnames(
        `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
        styles.lighterText,
      )}
      >
        {t(`${translPrefix}.details`)}
      </div>
      <div className="row">
        <WhiteBoxTitled
          title={t(`${translPrefix}.taskType`)}
          content={t(`taskType.${newTask.taskType}`)}
          mobile={mobile}
          containerClass={styles.whiteBoxContainer}
        />
        <WhiteBoxTitled
          title={t(`${translPrefix}.priority`)}
          content={t(`taskPriority.${newTask.priority}`)}
          mobile={mobile}
          containerClass={styles.whiteBoxContainer}
        />
        {typeOfCostObject && (
        <WhiteBoxTitled
          title={t(`${translPrefix}.${typeOfCostObject.typeOfCost}`)}
          content={getTypeOfCostContent()}
          mobile={mobile}
          containerClass={styles.whiteBoxContainer}
        />
        )}
      </div>
    </div>

  );
};

export { UnassignedTaskDetailRow };
