import React from 'react';
import { Breakpoints } from 'common/enums';
import { WhiteBoxTitled } from '../white-box-titled';
import { useMediaQuery } from '../../hooks/use-media-query';
import { classnames, resolveDateHour } from '../../helpers/utils';

import styles from './task-date-overview.module.scss';

export type TaskStatusDates = {
  negotiating?: string,
  inProgress?: string,
  solved?: string,
  rejected?: string,
  applied?: string,
  created?: string,
};

type TaskDatesOverviewProps = {
  dates: TaskStatusDates,
  t: (text: string) => string,
  estimatedStartDate?: string,
  language: string,
  translPrefix: string,
};

const TaskDatesOverview = ({
  dates, t, estimatedStartDate, language, translPrefix,
}: TaskDatesOverviewProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div>
      <div className={classnames(
        `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
        styles.lighterText,
      )}
      >
        {t(`${translPrefix}.dates`)}
      </div>
      <div className={styles.datesWrapper}>
        {Object.entries(dates).map(([taskState, date]) => (date && (
          <WhiteBoxTitled
            key={taskState}
            title={`${t(`taskStatus.${taskState}`)}:`}
            content={resolveDateHour(date, language, t, 'MMMM Do, YYYY - HH:mm A')}
            mobile={mobile}
          />
        )))}
        {estimatedStartDate && (
        <>
          <WhiteBoxTitled
            key="estimatedStartDate"
            title={t(`${translPrefix}.estimatedStartDate.title`)}
            content={resolveDateHour(estimatedStartDate, language, t, 'MMMM Do, YYYY - HH:mm A')}
            mobile={mobile}
          />
          <div className={classnames(styles.disclaimer, 'text__body__tiny__textNeutral30')}>
            {t(`${translPrefix}.estimatedStartDate.footnote`)}
          </div>
        </>
        )}
      </div>
    </div>
  );
};

export { TaskDatesOverview };
