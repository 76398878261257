import React,
{
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';
import { CreateFooter } from 'common/creation';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Task } from 'models/task';
import { ReactComponent as AlertFilled } from 'assets/icons/alertFilled.svg';
import { goToPage, RouteName } from 'routes';
import { Contractor } from 'models/contractor';
import { AssignContractorContainer } from 'common/assign-contractor-container';
import { useParams, useHistory } from 'react-router-dom';
import styles from './assign-contractor.module.scss';

type ParamType = {
  taskId: string,
};

const AssignContractor = () => {
  const { taskId } = useParams<ParamType>();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [task, setTask] = useState<Task>(new Task(null));
  const [idContractor, setIdContractor] = useState(-1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [optionNotifyNotSelected, setOptionNotifyNotSelected] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const translPrefix = 'createTask';

  const saveContractor = async () => {
    if (!task) {
      return;
    }
    const newTask: SerializedCreateTask = {
      category: task.category,
      propertyId: task.property.id,
      startDate: task.startDate,
      description: task.description,
      priority: task.priority,
      unitId: (task.unit && task.unit.id) ? task.unit.id : undefined,
      contractorId: idContractor || undefined,
      additionalInformation: task.additionalInformation || undefined,
      contactMethod: task.contactMethod || undefined,
      email: task.email,
      sms: task.sms,
      taskPhoto: task.photos,
    };
    if (idContractor !== -1) {
      if (newTask.email || newTask.sms) {
        try {
          setFetching(true);
          const taskDetail = await MaintenanceController.editContractor(Number(taskId), newTask);
          setTask(taskDetail);
          setFetching(false);
          setShowSuccessModal(true);
        } catch (err: any) {
          setFetching(false);
          logger.error(err);
          setError(true);
        }
      } else {
        setOptionNotifyNotSelected(true);
      }
    } else {
      setShowErrorModal(true);
    }
  };

  const setContractorData = (field: string, value: string) => {
    if (field === 'contractorId') {
      setIdContractor(Number(value));
    } else {
      if (!task) {
        return;
      }
      const serializedTaskView: SerializedTaskView = {
        property: task.property,
        category: task.category,
        id: task.id,
        startDate: task.startDate,
        priority: task.priority,
        contractor: task.contractor || new Contractor(null),
        status: task.status,
        unitNumber: task.unitNumber,
        description: task.description,
        photos: task.photos,
        cost: task.cost,
        endDate: task.endDate,
        additionalInfo: field === 'infoContractor' ? value : task.additionalInformation,
        rejectionDate: task.rejectionDate,
        rejectionReason: task.rejectionReason || '',
        contactMethod: field === 'contactMethod' ? value : task.contactMethod || '',
        unit: task.unit,
        taskEvents: task.taskEvents,
        sms: task.sms,
        email: task.email,
      };
      const newTask = new Task(serializedTaskView);
      setTask(newTask);
    }
  };

  const setContactMethod = (field: string, value: boolean) => {
    if (field === 'sms') {
      setTask((prevState) => ({ ...prevState, sms: value }));
    } else {
      setTask((prevState) => ({ ...prevState, email: value }));
    }
  };

  const getTaskDetail = async () => {
    try {
      setFetching(true);
      const taskDetail = await MaintenanceController.getTaskDetail(taskId);
      setTask(taskDetail);
      setFetching(false);
    } catch (err: any) {
      logger.error(err);
      setError(true);
    }
  };

  useEffect(() => {
    getTaskDetail();
  }, []);

  if (fetching || !task) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {error
      && (
      <ErrorMessage
        handleClose={() => setError(false)}
        message={t('error.errorMessage')}
      />
      )}
      { (showErrorModal && (
      <ModalResult
        title={t(`${translPrefix}.modalAssignError.title`)}
        subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
        Icon={Tool}
        iconStyle={styles.iconStyle}
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={() => setShowErrorModal(false)}
        handleButtonClose={() => setShowErrorModal(false)}
      />
      ))}
      { (showSuccessModal) && (
      <ModalResult
        title={t(`${translPrefix}.modalTitleEdit`)}
        subtitle={t(`${translPrefix}.modalContractorAssigned`)}
        Icon={Tool}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={() => history.goBack()}
        handleButtonClose={() => history.goBack()}
      />
      )}
      <div className="text__heading4__textNeutral50">{task.contractor?.id ? t(`${translPrefix}.assignAnotherContractor`) : t(`${translPrefix}.assignAContractor`)}</div>
      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {task.property.address.fullAddress}
      </div>
      <AssignContractorContainer
        category={task.category}
        propertyId={task.property.id}
        onChange={setContractorData}
        onChangeContactMethod={setContactMethod}
        infoContractor={task.additionalInformation}
        sms={task.sms}
        email={task.email}
      />
      {optionNotifyNotSelected && (
      <div className={classnames(styles.alertNotifyOptions, 'text__body__small__danger50')}>
        <AlertFilled className={styles.alertNotifyIcon} />
        {t(`${translPrefix}.alertSelectNotifyOptions`)}
      </div>
      )}
      <CreateFooter
        nextName={t('createTask.footer.assignTask')}
        Icon={null}
        secondButtonName={t('createTask.footer.cancel')}
        nextFn={saveContractor}
        secondButtonFn={() => goToPage(RouteName.TaskDetail, { id: taskId })}
      />

    </div>
  );
};

export { AssignContractor };
