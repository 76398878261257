import {
  OpenOrderResponse, PaymentMethod, RawUpdatePayment, UpdatePayment,
} from '@mapix/common/src/types';

class CreatePaymentSerializer {
  static serialize(data: OpenOrderData): RawOpenOrderData {
    return {
      rental_id: data.rentalId,
      amount: data.amount,
      currency: data.currency,
    };
  }

  static deserialize(data: RawOpenOrderResponse): OpenOrderResponse {
    return {
      id: data.id,
      userTokenId: data.user_token_id,
      sessionToken: data.session_token,
      merchantId: data.merchant_id,
      merchantSiteId: data.merchant_site_id,
    };
  }

  static serializeUpdatePayment(data: UpdatePayment): RawUpdatePayment {
    return {
      id: data.id,
      status: data.status,
      error_code: data.errorCode,
      last_four_numbers: data.lastFourNumbers,
      expiration_month: data.expirationMonth,
      expiration_year: data.expirationYear,
      user_payment_option_id: data.userPaymentOptionId,
      rental_id: data.rentalId,
      reason: data.reason,
      transaction_id: data.transactionId,
    };
  }

  static deserializePaymentMethod(data: RawPaymentMethod): PaymentMethod {
    return {
      id: data.id,
      lastFourNumbers: data.last_four_numbers,
      expirationMonth: data.expiration_month,
      expirationYear: data.expiration_year,
      userPaymentOptionId: data.user_payment_option_id,
    };
  }
}

export { CreatePaymentSerializer };
