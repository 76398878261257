import { getPaymentStatus } from 'helpers/utils';
import { PaymentStates } from 'common/enums';
import { PropertyOwner } from 'models/property-owner';

class RentalTenant {
  id: number;

  dueDate: string;

  paymentDate?: string;

  leaseExpirationDate: string;

  paymentStatus: PaymentStates;

  status: string;

  amount: number;

  managers: PropertyOwner[];

  currency: string;

  billedAmount: string;

  fullMonth: boolean;

  extensionBorderRental: boolean;

  constructor(params: SerializedTenantRental) {
    this.dueDate = params.dueDate;
    this.paymentDate = params.paymentDate;
    this.leaseExpirationDate = params.leaseExpirationDate;
    this.paymentStatus = getPaymentStatus(params.dueDate, params.paymentDate);
    this.amount = params.rentalAmount;
    this.id = params.id;
    this.managers = params.managers.map((manager) => new PropertyOwner(manager));
    this.currency = params.currency;
    this.billedAmount = `${this.amount} ${this.currency}`;
    this.status = params.status;
    this.fullMonth = params.fullMonth;
    this.extensionBorderRental = params.extensionBorderRental;
  }
}

export { RentalTenant };
