import { LeaseDetail } from 'models/lease-detail';
import { PropertyOwnerSerializer } from 'networking/serializers/property-owner-serializer';
import { ServicesSerializer } from 'networking/serializers/services-serializer';
import { PropertySerializer } from './property-serializer';
import { UnitSerializer } from './unit-serializer';

class DetailedLeaseSerializer {
  static deSerializeDetailedLease(data: RawDetailedLease): SerializedDetailedLease {
    return {
      id: data.id,
      status: data.status,
      property: PropertySerializer.deSerialize(data.property),
      unit: data.unit ? UnitSerializer.deSerialize(data.unit) : null,
      propertyId: data.property_id,
      currency: data.currency,
      dueDay: data.due_date,
      expirationDate: data.expiration_date,
      fees: data.fees,
      leaseCopy: data.lease_copy,
      monthlyRental: data.monthly_rental,
      services: data.services ? ServicesSerializer.deSerialize(data.services) : null,
      size: data.size,
      startDate: data.start_date,
      tenant: data.tenant ? PropertyOwnerSerializer.deSerialize(data.tenant) : null,
      tenantIdPhotos: data.tenant_id_photos,
      unitPhotos: data.unit_photos,
      endDate: data.end_date,
      propertyStatus: data.property.status,
      propertyCondition: data.property.condition,
    };
  }

  static serializeRenewLease(data: LeaseDetail): RawRenewLease {
    return {
      id: data.id,
      start_date: data.startDate,
      expiration_date: data.expirationDate,
      currency: data.currency,
      monthly_rental: data.monthlyRental,
      fees: data.condoFees,
      services: data.services ? ServicesSerializer.serialize(data.services) : null,
      lease_copy: data.leaseCopy,
      tenant_id_photos: data.tenantIdPhotos,
      unit_photos: data.unitPhotos,
      end_date: data.endDate ? data.endDate : null,
      size: data.size,
    };
  }
}

export { DetailedLeaseSerializer };
