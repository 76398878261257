class User {
  id: number;

  name: string;

  lastName: string;

  email: string;

  type: string;

  phoneNumber: string;

  countryCode: string;

  additionalPhoneNumber?: string;

  additionalCountryCode?: string;

  normalAuth: boolean;

  googleAuth: boolean;

  googleToken?: string;

  invite?: string;

  condition?: string;

  active: boolean;

  constructor(params: UserSerialized | null) {
    this.id = params?.id || -1;
    this.name = params?.name || '';
    this.lastName = params?.lastName || '';
    this.email = params?.email || '';
    this.type = params?.type || '';
    this.phoneNumber = params?.phoneNumber || '';
    this.countryCode = params?.countryCode || '';
    this.normalAuth = params?.normalAuth || false;
    this.googleAuth = params?.googleAuth || false;
    this.additionalPhoneNumber = params?.additionalPhoneNumber || '';
    this.additionalCountryCode = params?.additionalCountryCode || '';
    this.googleToken = params?.googleToken || '';
    this.invite = params?.invite || '';
    this.condition = params?.condition || '';
    this.active = params?.active || false;
  }
}

export { User };
