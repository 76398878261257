import { HTMLValidationError, resolveAddress } from '@mapix/common/src/helpers/utils';
import { Property } from 'models/property';
import { NewTask } from 'networking/types';

const translPrefix = 'createTask';

const createSteps = [`${translPrefix}.steps.property`, `${translPrefix}.steps.taskInfo`, `${translPrefix}.steps.contractor`];

type Action =
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'STEPS', steps: string [] }
  | { type: 'FINISH' }
  | { type: 'FINISH_MODAL_ASSIGN_LATER' }
  | { type: 'MODAL_ASSIGN_TASK', modalAssignTask: boolean }
  | { type: 'FINISH_MODAL_ASSIGN_TASK' }
  | { type: 'FINISH_ERROR' }
  | { type: 'CLOSE_ERROR' }
  | { type: 'PROPERTY_CHANGED', propertyAddress: string, buildingType: boolean, propertyId: number, location: string }
  | { type: 'CHANGE_FIELD', value: string | number | Specialization, field: string }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'ADD_CONTRACTOR_MODAL', addContractorModal: boolean }
  | { type: 'PROPERTIES_FETCHED', data: Property[] }
  | { type: 'TASK_PHOTOS', taskPhotos: FileType[] }
  | { type: 'GO_BACK' }
  | { type: 'FORM_ERROR', errors: HTMLValidationError }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string }
  | { type: 'GO_NEXT' }
  | { type: 'SET_NEW_TASK', taskData: NewTask };

const getArea = (buildingName: string, unitNumber: string) => {
  if (!buildingName) return '';
  return unitNumber ? 'specific' : 'common';
};

const initialState = (propertyId?: number): CreateNewTaskState => ({
  query: '',
  propertyAddress: '',
  show: false,
  data: [],
  step: 0,
  steps: createSteps,
  buildingType: true,
  issueDate: '',
  area: '',
  finish: false,
  specificUnit: null,
  unitNumber: '',
  contractors: [],
  infoContractor: '',
  notification: '',
  formErrors: {},
  addContractorModal: false,
  modalAssignLater: false,
  modalAssignTask: false,
  errorModal: false,
  description: '',
  priority: '',
  unitId: '',
  contractorId: '',
  name: '',
  otherCategory: '',
  propertyId: propertyId || -1,
  taskPhotos: [],
  taskType: '',
  category: {
    id: -1,
    name: '',
  },
  buildingName: '',
  location: '',
});

function CreateNewTaskReducer(state: CreateNewTaskState, action: Action): CreateNewTaskState {
  switch (action.type) {
    case 'PROPERTIES_FETCHED':
      return {
        ...state,
        data: action.data,
      };
    case 'SET_NEW_TASK':
      return {
        ...state,
        ...action,
        name: action.taskData.name,
        category: {
          // TODO change if backend sends the actual category
          id: 0,
          name: action.taskData.category,
        },
        priority: action.taskData.priority,
        description: action.taskData.description,
        propertyId: action.taskData.property.id!,
        taskPhotos: action.taskData.taskPhotos ?? [],
        taskType: action.taskData.taskType,
        propertyAddress: resolveAddress(action.taskData.address),
        unitNumber: action.taskData.address.unitNumber ?? '',
        specificUnit: Number(action.taskData.address.unitNumber) ?? null,
        buildingType: !!action.taskData.buildingName,
        buildingName: action.taskData.buildingName,
        area: getArea(action.taskData.buildingName, action.taskData.address.unitNumber ?? ''),
      };
    case 'PROPERTY_CHANGED':
      return {
        ...state,
        propertyAddress: action.propertyAddress,
        buildingType: action.buildingType,
        propertyId: action.propertyId,
        location: action.location,
      };
    case 'FORM_ERROR':
      return {
        ...state,
        formErrors: action.errors,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'STEPS':
      return {
        ...state,
        steps: action.steps,
      };
    case 'ADD_CONTRACTOR_MODAL':
      return {
        ...state,
        addContractorModal: action.addContractorModal,
      };
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GO_NEXT':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'CHANGE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'UNIT_SELECTED':
      return {
        ...state,
        specificUnit: action.specificUnit,
        unitNumber: action.unitNumber,
        formErrors: { ...state.formErrors, unitNumbersInput: '' },
      };
    case 'FINISH':
      return {
        ...state,
        finish: true,
      };
    case 'MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: action.modalAssignTask,
      };
    case 'FINISH_MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: true,
        finish: state.contractorId ? true : state.finish,
      };
    case 'FINISH_MODAL_ASSIGN_LATER':
      return {
        ...state,
        modalAssignLater: true,
        finish: true,
      };
    case 'FINISH_ERROR':
      return {
        ...state,
        errorModal: true,
      };
    case 'CLOSE_ERROR':
      return {
        ...state,
        errorModal: false,
      };
    case 'TASK_PHOTOS':
      return {
        ...state,
        taskPhotos: action.taskPhotos,
      };
    default:
      return {
        ...state,
      };
  }
}

export { CreateNewTaskReducer, initialState };
export type { Action };
