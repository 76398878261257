import { Paginator } from 'models/paginator';
import { addDateAndFiltersToURL, maintenanceTypeConverter, maintenanceTypeConverterV2 } from 'helpers/utils';
import { Contractor } from 'models/contractor';
import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { MaintenanceSerializer } from 'networking/serializers/maintenance-serializer';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { Task } from 'models/task';
import { TenantSimpleProperty } from 'models/tenant-simple-property';
import { Claim } from 'models/claim';
import { RawNewTask, RawPrivateRequest } from 'networking/types';
import { PrivateRequestSerializer } from 'networking/serializers/private-request-serializer';
import {
  ModifyRate, RawRejectPrivateRequest, RejectPrivateRequest,
} from '@mapix/common/src/types';
import { SpecializationSerializer } from 'networking/serializers/specialization-serializer';
import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { PaginatorSerializer } from '../serializers/paginator-serializer';

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

class MaintenanceController {
  static async getTasks(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS(page, pageSize, maintenanceTypeConverter(type), query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getTaskDetail(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(serializedTask);
  }

  static async editTask(task: SolveATask) {
    const serializedTask = MaintenanceSerializer.serializeSolveATask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async acceptTask(task: AcceptATask) {
    const serializedTask = MaintenanceSerializer.serializeAcceptTask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async cancelTask(id: number) {
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), {
      id,
      status: 'Rejected',
    });
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async rejectTask(data: RejectTask) {
    const serializedTask = MaintenanceSerializer.serializeRejectTask(data);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async cancelAssignement(id: number) {
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), {
      contractor_id: null,
    });
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getClaimsOfTenant(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    property: TenantSimpleProperty,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    let url;
    if (property.unitId) {
      url = `${API_ROUTES
        .GET_UNIT_CLAIMS_FROM_TENANT(page, pageSize, property.unitId, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    } else {
      url = `${API_ROUTES
        .GET_CONDO_CLAIMS_FROM_TENANT(page, pageSize, property.id, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    }
    const result = await ApiService.get<RawPaginatorContainer<ViewTaskBackend>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<Task>(
      paginator,
      results.map((item) => new Task(MaintenanceSerializer.deSerializeTask(item))),
    );
  }

  static async addContractor(id: number, contractor: Contractor) {
    const serializedContractor = ContractorSerializer.serialize(contractor);
    const result = await ApiService.post<RawContractor>(API_ROUTES
      .ADD_CONTRACTOR(id), serializedContractor);
    const deserializedContractor = ContractorSerializer.deSerialize(result.data);
    return new Contractor(deserializedContractor);
  }

  static async newTask(task: SerializedCreateTask) {
    const serializedTask = MaintenanceSerializer.serializeTask(task);
    return ApiService.post<RawContractor>(API_ROUTES.ADD_TASK, serializedTask);
  }

  static async getTasksOfProperty(
    idProperty: number,
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS_OF_PROPERTY(idProperty, page, pageSize, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getTasksOfUnit(
    idProperty: number,
    idUnit: number,
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS_OF_UNIT(idProperty, idUnit, page, pageSize, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async editContractor(id: number, task: SerializedCreateTask) {
    const serializedTask = MaintenanceSerializer.serializeTask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getContractorsV1(
    pageNumber: number,
    pageSize: number,
    id: number,
    category?: string,
  ) {
    const result = await ApiService.get<RawPaginatorContainer<RawContractor>>(API_ROUTES
      .PROPERTY_CONTRACTORS(pageNumber, pageSize, id, category));
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<Contractor>(
      paginator,
      results.map((item) => new Contractor(ContractorSerializer.deSerialize(item))),
    );
  }

  static async newClaim(claim: SerializedCreateClaim) {
    const serializedClaim = MaintenanceSerializer.serializeCreateClaim(claim);
    return ApiService.post<DeserializedCreateClaim>(API_ROUTES.ADD_CLAIM, serializedClaim);
  }

  static async editClaim(claimId: string, claim: SerializedEditClaim) {
    const serializedClaim = MaintenanceSerializer.serializeEditClaim(claim);
    return ApiService.put<DeserializedCreateClaim>(API_ROUTES.EDIT_CLAIM(claimId), serializedClaim);
  }

  static async getClaim(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_CLAIM_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(serializedTask);
  }

  static async getTaskDetailByContractorToken(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_TASK_BY_CONTRACTOR_TOKEN(id));
    const serializedTask = MaintenanceSerializer.deSerializeContractrTaskDetail(result.data);
    return serializedTask;
  }

  static async getDetailedClaim(id: string) {
    const result = await ApiService.get<RawClaim>(API_ROUTES
      .GET_CLAIM_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeClaim(result.data);
    return new Claim(serializedTask);
  }

  static async cancelClaim(id: number) {
    const result = await ApiService.post<RawClaim>(API_ROUTES
      .CANCEL_TASK(id));
    const deSerializedClaim = MaintenanceSerializer.deSerializeClaim(result.data);
    return new Claim(deSerializedClaim);
  }

  static async saveEditedTask(data: SerializedCreateTask, taskId: string) {
    const serializedTask = MaintenanceSerializer.serializeTask(data);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(taskId), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getSpecializations(): Promise<Specialization[]> {
    const result = await ApiService.get<RawSpecialization[]>(API_ROUTES.GET_SPECIALIZATIONS);
    return SpecializationSerializer.deSerializeAllSpecializations(result.data);
  }

  // New task

  static async createNewTask(task: CreateNewTaskData) {
    const serializedTask = PrivateRequestSerializer.serializeNewTask(task);
    const result = await ApiService.post<RawNewTask>(API_ROUTES.NEW_TASKS, serializedTask);

    return PrivateRequestSerializer.deSerializeNewTask(result.data);
  }

  static async updateNewTask(task: CreateNewTaskData, id: number) {
    const serializedTask = PrivateRequestSerializer.serializeNewTask(task);
    return ApiService.put<RawContractor>(API_ROUTES.NEW_TASK(id), serializedTask);
  }

  static async getPrivateRequest(id: string) {
    const result = await ApiService.get<RawPrivateRequest>(API_ROUTES
      .GET_PRIVATE_REQUEST(id));
    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  static async getNewTask(id: string) {
    const result = await ApiService.get<RawNewTask>(API_ROUTES
      .NEW_TASK(id));
    return PrivateRequestSerializer.deSerializeNewTask(result.data);
  }

  static async rejectPrivateRequest(id: number, data: RejectPrivateRequest) {
    const serializedData = PrivateRequestSerializer.serializeRejectPrivateRequestData(data);
    const result = await ApiService
      .post<RawRejectPrivateRequest>(API_ROUTES.REJECT_PRIVATE_REQUEST(id), serializedData);
    return result;
  }

  static async modifyRatePrivateRequest(id: number, data: ModifyRate) {
    const serializedData = PrivateRequestSerializer.serializeModifyRatePrivateRequest(data);
    const result = await ApiService
      .post<RawPrivateRequest>(API_ROUTES.MODIFY_RATE_PRIVATE_REQUEST(id), serializedData);
    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  // ----------------- V2 ----------------
  static async getNewTasks(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_NEW_TASKS(page, pageSize, maintenanceTypeConverterV2(type), query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;

    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getNewTaskDetail(id: string) {
    const result = await ApiService.get<RawNewTask>(API_ROUTES
      .NEW_TASK(id));

    return PrivateRequestSerializer.deSerializeNewTask(result.data);
  }

  static async acceptRatePrivateRequest(privateRequestId: number) {
    const result = await ApiService
      .post<RawPrivateRequest>(API_ROUTES.ACCEPT_RATE_PRIVATE_REQUEST(privateRequestId));

    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  static async moveTaskToInProgress(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.MOVE_TASK_TO_IN_PROGRESS(taskId));

    return PrivateRequestSerializer.deSerializeNewTask(result.data);
  }

  static async acceptTaskToInProgress(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.ACCEPT_IN_PROGRESS(taskId));

    return PrivateRequestSerializer.deSerializeNewTask(result.data);
  }
}
export { MaintenanceController };
