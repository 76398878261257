import { PropertyAddress } from 'models/property-address';
import { PropertyOwner } from 'models/property-owner';
import { addOrRemoveFromArrayObject, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Country } from 'models/country';
import { Unit } from 'models/unit';
import { Contractor } from 'models/contractor';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { Coordinates } from '@mapix/common/src/common/map-view';

const translPrefix = 'createBuilding';

const buildingSteps = [`${translPrefix}.steps.general`, `${translPrefix}.steps.units`, `${translPrefix}.steps.contractors`];
const unitTableProperties = ['unitNumber', 'status'];

type CreatePropertyBuildingState = {
  step: number,
  address: PropertyAddress,
  building: BuildingData,
  owner: PropertyOwner,
  otherOwner: PropertyOwner,
  formErrors: HTMLValidationError,
  steps: string[],
  unitTableProps: string[],
  countries: Country[],
  units: Unit[],
  contractors: Contractor[],
  finish: boolean,
  modal: boolean,
  errorModal: boolean,
  draftModal: boolean,
  finishDraft: boolean,
  id?: number,
  [key: string]: any
};

type BuildingData = {
  unitsCount: number,
  buildingName: string,
};

type Action =
  | { type: 'GO_BACK' }
  | { type: 'GO_NEXT' }
  | { type: 'FINISH' }
  | { type: 'CHANGE_INPUT', value: string | number, object: string, field: string }
  | { type: 'CHANGE_COORDINATES', value: Coordinates }
  | { type: 'COUNTRIES_FETCHED', fetchedCountries: Country[] }
  | { type: 'FORM_ERROR', errors: HTMLValidationError }
  | { type: 'ADD_REMOVE_UNIT', unit: Unit, previousUnit?: Unit }
  | { type: 'ADD_REMOVE_CONTRACTOR', contractor: Contractor }
  | { type: 'FINISH_MODAL' }
  | { type: 'FINISH_ERROR' }
  | { type: 'CLOSE_ERROR' }
  | { type: 'DRAFT_MODAL' }
  | { type: 'FINISH_DRAFT' };

const initBuilding = (property: DetailedDraftProperty | undefined) => {
  if (property) {
    return {
      unitsCount: property.unitsCount,
      buildingName: property.buildingName,
    };
  }
  return {
    unitsCount: 0,
    buildingName: '',
  };
};

const initProperty = (property: DetailedDraftProperty | undefined) => ({
  address: property ? property.address : new PropertyAddress(null),
  owner: property ? property.owner : new PropertyOwner(null),
  otherOwner: property && property.otherOwner ? property.otherOwner : new PropertyOwner(null),
  contractors: property ? property.contractors : [],
  status: property && property.status ? property.status : '',
  units: property && property.units ? property.units : [],
  id: property && property.id ? property.id : undefined,
});

const initialState = (property: DetailedDraftProperty | undefined):
CreatePropertyBuildingState => ({
  step: 0,
  ...initProperty(property),
  building: initBuilding(property),
  formErrors: {},
  steps: buildingSteps,
  unitTableProps: unitTableProperties,
  countries: [],
  finish: false,
  modal: false,
  errorModal: false,
  finishDraft: false,
  draftModal: false,
});

function CreatePropertyBuildingReducer(state: CreatePropertyBuildingState, action: Action) {
  let newUnits = [...state.units];
  switch (action.type) {
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GO_NEXT':
      return {
        ...state,
        step: state.step + 1,
        formErrors: {},
      };
    case 'CHANGE_INPUT':
      return {
        ...state,
        [action.object]: {
          ...state[action.object],
          [action.field]: action.value,
        },
      };
    case 'CHANGE_COORDINATES':
      return {
        ...state,
        address: { ...state.address, lat: action.value[0], long: action.value[1] },
      };
    case 'FORM_ERROR':
      return {
        ...state,
        formErrors: action.errors,
      };
    case 'COUNTRIES_FETCHED':
      return {
        ...state,
        countries: action.fetchedCountries,
      };
    case 'ADD_REMOVE_UNIT':
      if (action.previousUnit) {
        newUnits = addOrRemoveFromArrayObject(newUnits, action.previousUnit, 'unitNumber');
      }
      newUnits = addOrRemoveFromArrayObject(newUnits, action.unit, 'unitNumber');
      return {
        ...state,
        units: newUnits,
      };
    case 'ADD_REMOVE_CONTRACTOR':
      return {
        ...state,
        contractors: addOrRemoveFromArrayObject(state.contractors, action.contractor, 'email'),
      };
    case 'FINISH':
      return {
        ...state,
        finish: true,
      };
    case 'FINISH_MODAL':
      return {
        ...state,
        modal: true,
      };
    case 'FINISH_ERROR':
      return {
        ...state,
        errorModal: true,
      };
    case 'CLOSE_ERROR':
      return {
        ...state,
        errorModal: false,
      };
    case 'DRAFT_MODAL':
      return {
        ...state,
        draftModal: !state.draftModal,
      };
    case 'FINISH_DRAFT':
      return {
        ...state,
        finishDraft: true,
      };
    default:
      return state;
  }
}

export { CreatePropertyBuildingReducer, initialState };
export type { Action, BuildingData };
