import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { Breakpoints, Status } from 'common/enums';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as EditMobile } from 'assets/icons/edit-mobile.svg';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { logger } from 'helpers/logger';
import { getTaskEventText } from 'helpers/utils';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Claim } from 'models/claim';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppLink, goToPage, RouteName } from 'routes';
import { Menu } from 'common/menu';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { Button } from '@mapix/common/src/common/button';
import styles from './claim-detail.module.scss';

type ParamType = {
  id: string,
};

type List = {
  name: string,
  route: RouteName,
  pathParams?: {},
};

const breadcrumbs = (myClaims: string, claimDetail: string): BreadcrumbsItem[] => [
  {
    name: myClaims,
    goToPageFn: () => goToPage(RouteName.Claims),
  },
  {
    name: claimDetail,
    goToPageFn: () => null,
  },
];

const ClaimDetail = () => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [fetching, setFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalCancelOk, setShowModalCancelOk] = useState(false);
  const { t } = useTranslation();
  const [claim, setClaim] = useState<Claim | null>(null);
  const { id } = useParams<ParamType>();
  const [errorServer, setErrorServer] = useState(false);

  const listPath: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
    { name: t('claimDetail.claimDetail'), route: RouteName.ClaimDetail, pathParams: { id: claim?.id } },
  ];

  const getClaim = async () => {
    try {
      setFetching(true);
      const result = await MaintenanceController.getDetailedClaim(id);
      setClaim(result);
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setErrorServer(true);
      setFetching(false);
    }
  };

  const cancelClaim = async () => {
    try {
      setShowModalCancel(false);
      setFetching(true);
      const result = await MaintenanceController.cancelClaim(Number(id));
      setClaim(result);
      setFetching(false);
      setShowModalCancelOk(true);
    } catch (ex) {
      setFetching(false);
      setShowError(true);
    }
  };

  useEffect(() => {
    getClaim();
  }, []);

  const getClaimsEvents = () => claim?.claimEvents.map(
    (element) => (
      <div key={element.id} className={classnames(styles.marginEvent, 'text__body__medium__textNeutral40')}>
        {getTaskEventText(t, element)}
      </div>
    ),
  );

  if (fetching || claim === null) {
    return (
      <Spinner />
    );
  }

  const getIcon = () => {
    if (claim.status === Status.new) {
      return (
        <div className={styles.iconNew}>{t('claimDetail.pending')}</div>);
    }
    if (claim.status === Status.inProgress) {
      return (
        <div className={styles.iconInProgress}>{t('taskDetail.inProgress')}</div>);
    }
    if (claim.status === Status.solved) {
      return (
        <div className={styles.iconSolved}>{t('taskDetail.solved')}</div>);
    }
    return (
      <div className={styles.iconRejected}>{t('taskDetail.rejected')}</div>);
  };

  const addressToShow = `${claim?.unit?.unitNumber ? `App. ${claim.unit.unitNumber} - ` : ''} ${claim.property.fullAddress}`;

  return (
    <>
      <div>
        {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
        {showModalCancelOk && (
        <ModalResult
          title={t('claimDetail.modalCancelOk.title')}
          subtitle={t('claimDetail.modalCancelOk.subtitle')}
          Icon={Tool}
          buttonTextRight={t('claimDetail.modalCancelOk.ok')}
          handleButtonClose={() => setShowModalCancelOk(false)}
          handleButtonLeft={() => setShowModalCancelOk(false)}
          handleButtonRight={() => setShowModalCancelOk(false)}
          withCheckIcon
        />
        )}
        {showModalCancel && (
        <ModalResult
          title={t('claimDetail.modalCancel.title')}
          subtitle={t('claimDetail.modalCancel.subtitle')}
          Icon={Tool}
          buttonTextLeft={t('claimDetail.modalCancel.goBack')}
          buttonTextRight={t('claimDetail.cancelClaim')}
          handleButtonClose={() => setShowModalCancel(false)}
          handleButtonLeft={() => setShowModalCancel(false)}
          handleButtonRight={cancelClaim}
          iconStyle={styles.iconBlue}
        />
        )}
      </div>
      <div>
        {showError
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}

      </div>
      <div className={styles.column}>
        {!mobile
          && (
          <Breadcrumbs values={breadcrumbs(t('claimDetail.myClaims'), t('claimDetail.claimDetail'))} />
          )}
        <div className={styles.container}>
          { mobile
          && (
          <>
            <Menu list={listPath} className={styles.menu} />
            <div className={styles.iconRight}>
              {getIcon()}
            </div>

          </>
          )}
          <div className={mobile ? styles.title : styles.row}>
            <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral50'}>
              {t('claimDetail.claimId')}
              {' '}
              { claim.id}
            </div>
            <div>
              {
            claim.status === Status.new && mobile
              && (
              <AppLink routeName={RouteName.EditClaim} pathParams={{ claimId: claim.id }}>
                <EditMobile />
              </AppLink>
              )
            }
            </div>
            {!mobile && getIcon()}
          </div>
          {!mobile
          && (
          <div className={styles.row}>
            <div className={styles.leftText}>
              <div className="text__heading5__textNeutral50">
                {addressToShow}
              </div>
            </div>
            { claim.status === Status.new && !mobile
          && (
          <div className={styles.buttonsTop}>
            <AppLink routeName={RouteName.EditClaim} pathParams={{ claimId: claim.id }}>
              <button className={styles.buttonText} type="button">
                <div className="row text__body__large__primary40">
                  {t('claimDetail.editClaim')}
                  <Edit className={styles.iconEdit} />
                </div>
              </button>
            </AppLink>
            <button className={styles.buttonText} type="button" onClick={() => setShowModalCancel(true)}>
              <div className="row text__body__large__primary40">
                {t('claimDetail.cancelClaim')}
                <Close className={styles.iconPrimary} />
              </div>
            </button>
          </div>
          )}
          </div>
          )}
          {mobile
            ? (
              <div className={styles.section}>
                <div className={classnames(styles.bold, 'text__heading3__textNeutral50')}>
                  {t('claimDetail.generalInfo')}
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.category.title')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {t(`claimDetail.category.${claim.category}`)}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.address')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {addressToShow}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.description')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {claim.description}
                  </div>
                </div>
              </div>
            )
            : (
              <>
                <div className={styles.section}>
                  <div className="text__heading6__textNeutral50">
                    {`${t('claimDetail.category.title')}:`}
                  </div>
                  <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                    {t(`claimDetail.category.${claim.category}`)}
                  </div>
                </div>
                <div className={styles.section}>
                  <div className="text__heading6__textNeutral50">
                    {`${t('taskDetail.description')}:`}
                  </div>
                  <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                    { claim.description}
                  </div>
                </div>
              </>
            )}
          { claim.status === Status.rejected && (
            <div className={styles.section}>
              <div className="text__heading6__textNeutral50">
                {t('taskDetail.reasonForRejection')}
              </div>
              <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                {
                 claim.rejectionReason ? claim.rejectionReason : t('taskDetail.cancelledByManager')
                }
              </div>
            </div>
          )}
          <div className={styles.section}>
            {mobile
              ? (
                <div className={classnames(styles.bold, 'text__heading3__textNeutral50')}>
                  {`${t('claimDetail.images')}:`}
                </div>
              )
              : (
                <div className="text__heading6__textNeutral50">
                  {`${t('taskDetail.photos')}:`}
                </div>
              )}
            <div className={styles.photos}>
              {
              claim.photos.length > 0
                ? claim.photos?.map((elem) => (
                  <img src={elem.url} alt="" className={styles.photo} />
                ))
                : (
                  <div className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                    {t('taskDetail.emptyPhotos')}
                  </div>
                )
              }
            </div>
          </div>
          <div className={styles.section}>
            <div className={classnames(mobile ? styles.bold : '', mobile ? 'text__heading3__textNeutral50' : 'text__heading6__textNeutral50')}>
              {t('taskDetail.tracking')}
              :
              {' '}
            </div>
            <div className={styles.margin}>
              {getClaimsEvents()}
            </div>
          </div>
          { claim.status === Status.new && mobile
            && (
            <div className={styles.bottomButton}>
              <Button className={styles.button} onClick={() => setShowModalCancel(true)}>
                {t('claimDetail.cancelClaim')}
              </Button>
            </div>
            )}
        </div>
      </div>
    </>
  );
};

export { ClaimDetail };
