/* eslint-disable react/jsx-props-no-spreading */
import {
  HTMLProps, KeyboardEvent, ReactNode, useState,
} from 'react';
import { Breakpoints, InputStyle } from '../enums';
import { useMediaQuery } from '../../hooks/use-media-query';
import { classnames, helperTextFn, HTMLValidationError } from '../../helpers/utils';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../assets/icons/eye-off.svg';
import styles from './input.module.scss';

type InputProps = {
  t: (text: string) => string,
  inputStyle?: InputStyle,
  withSearchIcon?: boolean,
  placeholder?: string,
  searchClass?: string,
  id: string,
  helperText?: string,
  containerClass?: string,
  textStyle?: string,
  error?: boolean,
  disabled?: boolean,
  formError?: HTMLValidationError,
  isSelect?: boolean,
  activeSelect?: boolean,
  backgroundBlue?: boolean,
  withDateIcon?: boolean,
  autoComplete?: string,
  required?: boolean,
  plainHelperText?: boolean,
  children?: ReactNode,
  closeFn?: () => void,
  price?: boolean,
  RightIcon?: ReactNode,
  rightIconClass?: string,
};

function handleInputClass(
  styleType: InputStyle,
  withIcon: boolean,
  error?: boolean,
  backgroundBlue?: boolean,
  mobile?: boolean,
  priceSignMargin?: boolean,
  textStyle?: string,
) {
  const finalStyleType = [] as string[];
  switch (styleType) {
    case InputStyle.REGULAR:
      finalStyleType.push(withIcon ? classnames(styles.regular, styles.withIcon) : styles.regular);
      break;
    case InputStyle.FORM:
      finalStyleType.push(styles.form);
      if (error) {
        finalStyleType.push(styles.formError);
      }
      if (backgroundBlue) {
        finalStyleType.push(styles.formBlue);
      }
      break;
    default:
      finalStyleType.push(styles.regular);
  }

  if (priceSignMargin) {
    finalStyleType.push(styles.price);
  }

  if (backgroundBlue) {
    finalStyleType.push(styles.backgroundBlue);
  }

  finalStyleType.push(textStyle || `text__body__${
    mobile && !withIcon ? 'large' : 'medium'
  }__${backgroundBlue ? 'primary60' : 'textNeutral50'}`);

  return classnames(...finalStyleType);
}

const Input = ({
  t, id, label, withSearchIcon = false, children, inputStyle = InputStyle.REGULAR, helperText = '',
  containerClass = '', error, formError, closeFn, autoComplete = 'off', isSelect = false, activeSelect = false,
  backgroundBlue = false, searchClass, disabled, price, plainHelperText = false, placeholder,
  withDateIcon = false, required = false, type, RightIcon, rightIconClass,
  textStyle = '', ...props
}: InputProps & HTMLProps<HTMLInputElement>) => {
  /* TODO: Delete this once we unified the error handling */
  const hasFormError = !!(formError && formError[id]);

  const [typeState, setTypeState] = useState(type);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const togglePasswordVisibility = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setTypeState(typeState === 'password' ? 'text' : 'password');
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isSelect) {
      e.preventDefault();
    } else if (props.onKeyPress) {
      props.onKeyPress(e);
    }
  };

  const helperTextClassName = () => {
    if ((hasFormError) && (formError && formError[id].length > 50)) {
      return classnames(styles.helperLabel, 'text__body__tiny__danger50');
    } if ((error || hasFormError)) {
      return classnames(styles.helperLabel, 'text__body__tiny__danger50');
    }
    return classnames(styles.helperLabel, 'text__body__tiny__textNeutral40');
  };

  return (
    <>
      <div className={classnames(backgroundBlue
        ? styles.backgroundBlue : styles.inputContainer, containerClass)}
      >
        <div className="relative">
          <div className={classnames(styles.centerIcon, isSelect ? styles.select : '')}>

            {withDateIcon && (
            <CalendarIcon className={styles.dateIcon} />
            )}

            {type === 'password' && (
            <button type="button" onClick={togglePasswordVisibility} className={styles.eyeIconContainer}>
              {typeState === 'password' ? <Eye className={styles.eyeIcon} /> : <EyeOff className={styles.eyeIcon} />}
            </button>
            )}
            {price && (props.value || !placeholder) && (
            <span className={classnames(styles.priceSymbol)}>$</span>
            )}

            <input
              disabled={disabled}
              onKeyPress={onKeyPress}
              id={id}
              placeholder={placeholder}
              autoComplete={autoComplete}
              required={required}
              type={typeState}
              {...props}
              className={handleInputClass(
                inputStyle,
                withSearchIcon,
                error || hasFormError,
                backgroundBlue,
                mobile,
                price && (!!props.value || !placeholder),
                textStyle,
              )}
            />

            {(isSelect) && (
              <div className={styles.chevronBox}>
                {!activeSelect
                  ? (
                    <ChevronDown
                      className={backgroundBlue ? styles.chevronBlue : styles.chevron}
                    />
                  )
                  : (
                    <ChevronUp
                      className={backgroundBlue ? styles.chevronBlue : styles.chevron}
                    />
                  ) }
              </div>
            )}

            {/* TODO: Refactoring the search icon is kinda big for this ticket but,
             we should eventually */}
            {RightIcon && (
            <div className={styles.rightIcon}>
              {RightIcon}
            </div>
            )}

            {withSearchIcon && (
            <SearchIcon className={classnames(searchClass || '', styles.icon)} />
            )}

          </div>
          {children}
        </div>
        {(helperText || !required || hasFormError) && !backgroundBlue && (
          <div className="relative">
            <div
              id={`helperText-${id}`}
              className={helperTextClassName()}
            >
              {hasFormError
                ? formError[id]
                : helperTextFn(required, helperText, t, disabled, plainHelperText, inputStyle)}
            </div>
          </div>
        )}

        {inputStyle === InputStyle.FORM && label && !backgroundBlue && props.value ? (
          <label
            className={classnames(styles.formLabel, 'text__body__tiny__textNeutral40')}
            htmlFor={id}
          >
            {label}
          </label>
        ) : null}

      </div>

    </>
  );
};

export { InputStyle, Input };
export type { InputProps };
