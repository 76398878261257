import { SupportedLanguages } from 'i18n/utils';
import { ReactComponent as Logo } from 'assets/images/Main logo.svg';
import { ReactComponent as Alert } from 'assets/icons/alert-circle-filled.svg';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from 'context';
import { AppLink, goToPage, RouteName } from 'routes';
import { Users } from 'common/enums';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import styles from './header.module.scss';

const Header = () => {
  const { i18n } = useTranslation();
  const { state } = useContext(AppContext);
  const languageSelected = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const onClickLogo = () => {
    if (state.user.type === Users.Tenant) {
      goToPage(RouteName.HomeTenant);
    } else {
      goToPage(RouteName.Properties);
    }
  };

  const getAvatarText = () => {
    const stringArray = state.user.name?.split(/(\s+)/);
    const firstWordLetter = stringArray ? stringArray[0][0] : '';
    if (stringArray !== undefined && stringArray?.length === 1) {
      // if it only has one word i return the first letter of it.
      return firstWordLetter;
    }
    const lastWordLetter = stringArray ? stringArray[2][0] : '';
    return firstWordLetter + lastWordLetter;
  };
  return (
    <div className={styles.headerContainer}>
      <Logo onClick={onClickLogo} className={styles.logo} />

      <div className={styles.languageLinks}>
        {Object.values(SupportedLanguages).map((value) => (
          <Button
            key={value}
            buttonStyle={ButtonStyle.Link}
            className={i18n.language === value
              ? styles.selectedLanguage : styles.unselectedLanguage}
            onClick={() => languageSelected(value)}
          >
            <div className={i18n.language === value
              ? 'text__body__medium__primary60' : 'text__body__medium__textNeutral30'}
            >
              {value.toUpperCase()}
            </div>
          </Button>
        ))}
      </div>
      {state.logged && (
      <>
        <div className={styles.nameContainer}>
          <AppLink
            routeName={RouteName.MyProfile}
            className={styles.name}
          >
            <div className="text__body__small__textNeutral40">
              {`${state.user.name} ${state.user.lastName}`}
            </div>
          </AppLink>
        </div>

        {(state.user.name === null || state.user.phoneNumber === null || state.user.name === '' || state.user.phoneNumber === '')
          && (
          <div className={styles.icon}>
            <Alert />
          </div>
          )}
        <AppLink
          routeName={RouteName.MyProfile}
          className={styles.avatar}
        >
          <div className="text__body__large__textNeutral10">
            {getAvatarText()}
          </div>
        </AppLink>
      </>
      )}
    </div>
  );
};

export { Header };
