import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  addError,
  checkHTMLErrors, classnames, HTMLValidationError, isEmptyObject, removeError,
} from '@mapix/common/src/helpers/utils';
import {
  ChangeEvent, Dispatch, FormEvent, useEffect, useState,
} from 'react';
import { PropertyAddress } from 'models/property-address';
import { PropertyOwner } from 'models/property-owner';
import { CreateFooter } from 'common/creation';
import { Country } from 'models/country';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { logger } from 'helpers/logger';
import { ButtonType } from '@mapix/common/src/common/button';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useTranslation } from 'react-i18next';
import { AddressForm, OwnerForm } from 'common/forms';
import { City } from '@mapix/common/src/types';
import { Coordinates } from '@mapix/common/src/common/map-view';
import styles from './create-property-building.module.scss';
import { Action, BuildingData } from './create-property-building-reducer';

type CreatePropertyBuildingStep1Props = {
  dispatch: Dispatch<Action>,
  address: PropertyAddress,
  building: BuildingData,
  owner: PropertyOwner,
  otherOwner: PropertyOwner,
  formErrors: HTMLValidationError,
  countries: Country[],
  invalidNumOfUnits: boolean
};

const addressTranslPrefix = 'createBuilding.address';
const buildingTranslPrefix = 'createBuilding.building';
const ownerTranslPrefix = 'createBuilding.owner';
const otherOwnerTranslPrefix = 'createBuilding.otherOwner';
const footerTranslPrefix = 'createBuilding.footer';

const CreatePropertyBuildingStep1 = ({
  dispatch,
  address,
  building,
  owner,
  otherOwner,
  formErrors,
  countries,
  invalidNumOfUnits,
}: CreatePropertyBuildingStep1Props) => {
  const [fetching, setFetching] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});
  const { t } = useTranslation();

  const fetchCountries = async () => {
    try {
      setFetching(true);
      const newCountries = await ResourcesController.getCountries();
      dispatch({ type: 'COUNTRIES_FETCHED', fetchedCountries: newCountries });
      setFetching(false);
    } catch (err: any) {
      dispatch({ type: 'FINISH_ERROR' });
      setFetching(false);
      logger.error(err);
    }
  };

  const onClickSelect = (option: City) => {
    dispatch({
      type: 'CHANGE_INPUT', field: 'city', object: 'address', value: option.name,
    });
    dispatch({
      type: 'CHANGE_INPUT', field: 'cityId', object: 'address', value: option.id,
    });
  };

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries();
    }
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>, objectToChange: string) => {
    dispatch({
      type: 'CHANGE_INPUT', field: e.target.name, object: objectToChange, value: e.target.value,
    });
  };

  const onChangeCoordinates = (coords: Coordinates) => {
    dispatch({
      type: 'CHANGE_COORDINATES', value: coords,
    });
  };

  const onChangePhoneNumber = (k: string, countryCode: string, field: string) => {
    removeError(field, setPhoneErrors);
    const result = field.split('-');
    dispatch({
      type: 'CHANGE_INPUT', field: result[1], object: result[0], value: k,
    });

    dispatch({
      type: 'CHANGE_INPUT', field: 'countryCode', object: result[0], value: countryCode,
    });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !owner.phoneNumber) {
      dispatch({ type: 'FORM_ERROR', errors: checkHTMLErrors(target) });
      if (!owner.phoneNumber) {
        addError(t('error.emptyField'), 'owner-phoneNumber', setPhoneErrors);
      }

      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    dispatch({ type: 'GO_NEXT' });
  };

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className={classnames(styles.title, 'text__heading6__textNeutral50')}>
        {t(`${addressTranslPrefix}.buildingTitle`)}
      </div>

      <AddressForm
        formErrors={formErrors}
        onChange={onChange}
        address={address}
        onClickSelect={onClickSelect}
        getCities={ResourcesController.getCities}
        onChangeCoordinates={onChangeCoordinates}
      />

      <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${buildingTranslPrefix}.title`)}
      </div>

      <div className={styles.formContainer}>
        <div className={styles.row}>
          <Input
            required
            type="number"
            id="unitsCount"
            name="unitsCount"
            min={1}
            label={t(`${buildingTranslPrefix}.unitAmountLabel`)}
            placeholder={t(`${buildingTranslPrefix}.unitAmountLabel`)}
            helperText={invalidNumOfUnits ? t(`${buildingTranslPrefix}.unitAmountHelper`) : t(`${buildingTranslPrefix}.minNumOfUnits`)}
            formError={formErrors}
            value={building.unitsCount || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'building')}
            inputStyle={InputStyle.FORM}
            containerClass={styles.input}
            t={t}
          />

          <Input
            required
            type="text"
            id="buildingName"
            name="buildingName"
            label={t(`${buildingTranslPrefix}.buildingNameLabel`)}
            placeholder={t(`${buildingTranslPrefix}.buildingNameLabel`)}
            value={building.buildingName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'building')}
            inputStyle={InputStyle.FORM}
            containerClass={styles.input}
            formError={formErrors}
            t={t}
          />
        </div>

      </div>

      <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${ownerTranslPrefix}.title`)}
      </div>

      <OwnerForm
        isRequired
        formErrors={{ ...formErrors, ...phoneErrors }}
        onChange={onChange}
        onChangePhoneNumber={onChangePhoneNumber}
        owner={owner}
        translPrefix={ownerTranslPrefix}
        field="owner"
        idPrefix="owner"
        setPhoneErrors={setPhoneErrors}
      />

      <OwnerForm
        isRequired={false}
        formErrors={{ ...formErrors, ...phoneErrors }}
        onChange={onChange}
        onChangePhoneNumber={onChangePhoneNumber}
        owner={otherOwner}
        translPrefix={otherOwnerTranslPrefix}
        field="otherOwner"
        idPrefix="otherOwner"
        setPhoneErrors={setPhoneErrors}
      />

      <CreateFooter
        nextButtonType={ButtonType.Submit}
        nextName={t(`${footerTranslPrefix}.units`)}
      />

    </form>
  );
};

export { CreatePropertyBuildingStep1 };
