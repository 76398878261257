import { useTranslation } from 'react-i18next';
import { ChangeEvent, FormEvent, useState } from 'react';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Select } from '@mapix/common/src/common/select';
import { Currency } from 'common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { BillsController } from 'networking/controllers/bills-controller';
import { logger } from 'helpers/logger';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import styles from './bill-a-task-modal.module.scss';

type BillATaskModalProps = {
  taskId: number,
  propertyId: number,
  unitId?: number,
  closeFn: () => void,
};

type StateData = {
  billedAmount: number,
  dueDate: string,
  currency: string,
  success: boolean,
  error: boolean
};

const initState: StateData = {
  billedAmount: 0,
  dueDate: '',
  currency: '',
  success: false,
  error: false,
};

const translPrefix = 'taskDetail.billATask';
const BillATaskModal = ({
  taskId, unitId, closeFn, propertyId,
}: BillATaskModalProps) => {
  const [state, setState] = useState<StateData>(initState);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});

  const onChange = (field: string, value: string) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  const { t } = useTranslation();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      try {
        const billedTask: SerializedBillWithTask = {
          billedAmount: state.billedAmount,
          currency: state.currency,
          dueDate: state.dueDate,
          taskId,
          unitId,
        };
        await BillsController.addBilWithTask(billedTask, propertyId);
        setState((prevState) => ({ ...prevState, success: true }));
      } catch (err: any) {
        logger.error(err);
      }
    }
  };

  return (
    <>
      {state.success ? (
        <ModalResult
          title={t(`${translPrefix}.success`)}
          Icon={CheckCircle}
          buttonTextRight={t(`${translPrefix}.okButton`)}
          handleButtonRight={() => closeFn()}
          handleButtonClose={() => closeFn()}
          withCheckIcon
        />
      )
        : (
          <Modal>
            <form onSubmit={onSubmit} noValidate className={styles.modalContainer}>
              <div className={styles.crossContainer}>
                <button type="button" onClick={() => closeFn()}>
                  <Cross className={styles.crossIcon} />
                </button>
              </div>

              <div className={classnames(styles.title, 'text__heading6__textNeutral50')}>
                {t(`${translPrefix}.billInfo`)}
              </div>

              <div className="row">
                <Select
                  options={Object.values(Currency)}
                  onClickOption={(option: string) => onChange('currency', option)}
                  optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
                  required
                  id="currency"
                  label={t(`${translPrefix}.currency`)}
                  placeholder={t(`${translPrefix}.currency`)}
                  value={state.currency}
                  inputStyle={InputStyle.FORM}
                  containerClass={styles.currency}
                  formError={formErrors}
                  t={t}
                />

                <Input
                  containerClass={styles.input}
                  required
                  id="billedAmount"
                  label="$"
                  inputStyle={InputStyle.FORM}
                  value={state.billedAmount}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('billedAmount', e.target.value)}
                  formError={formErrors}
                  helperText={t(`${translPrefix}.billedCost`)}
                  type="number"
                  min={1}
                  t={t}
                />

                <InputDate
                  containerClass={styles.input}
                  required
                  id="dueDate"
                  name="dueDate"
                  label={t(`${translPrefix}.dueDate`)}
                  placeholder={t(`${translPrefix}.dueDate`)}
                  value={state.dueDate !== '' ? dayjs(state.dueDate).toDate() : undefined}
                  helperText="MM/DD/YYYY"
                  formErrors={formErrors}
                  onChangeFn={(date: Date) => onChange('dueDate', dayjs(date).format('YYYY-MM-DD'))}
                  t={t}
                />
              </div>

              <div className={styles.buttons}>
                <Button
                  buttonStyle={ButtonStyle.Secondary}
                  className={styles.button}
                  onClick={() => closeFn()}
                >
                  {t(`${translPrefix}.cancel`)}
                </Button>
                <Button
                  className={styles.button}
                  buttonType={ButtonType.Submit}
                >
                  {t(`${translPrefix}.confirm`)}
                </Button>
              </div>

              {state.error && (
                <p className={classnames(styles.error, 'text__body__medium__danger50')}>
                  {t(`${translPrefix}.error`)}
                </p>
              )}

            </form>
          </Modal>
        )}
    </>
  );
};

export { BillATaskModal };
