import { ButtonType } from '@mapix/common/src/common/button';
import { CreateFooter } from 'common/creation';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { Spinner } from '@mapix/common/src/common/spinner';
import { checkHTMLErrors, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import {
  FormEvent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { useParams } from 'react-router-dom';
import { Menu } from 'common/menu';
import styles from './create-edit-claim.module.scss';
import { CommonForm } from './commonForm';

const initialStateClaim: SerializedCreateClaim = {
  category: '',
  propertyId: undefined,
  startDate: '',
  description: '',
  unitId: undefined,
  photos: [],
};

type List = {
  name: string,
  route: RouteName,
  pathParams?: {},
};

const CreateClaim = () => {
  const [claim, setClaim] = useState<SerializedCreateClaim>(initialStateClaim);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buildingCommonArea, setBuildingCommonArea] = useState(false);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const { t } = useTranslation();
  const { propertyId, unitId } = useParams<{ propertyId: string, unitId: string }>();

  const listPathProperty: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
    { name: t('createClaim.newClaim'), route: RouteName.CreateClaim, pathParams: { propertyId } },
  ];

  const listPathUnit: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
    { name: t('createClaim.newClaim'), route: RouteName.CreateClaimUnit, pathParams: { propertyId, unitId } },
  ];

  useEffect(() => {
    const newClaim = {
      ...claim,
      propertyId: Number(propertyId),
      unitId: Number(unitId),
    };
    setClaim(newClaim);
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      setFormErrors({});
      try {
        setFetching(true);
        if (buildingCommonArea) {
          delete claim.unitId;
        }
        await MaintenanceController.newClaim(claim);
        setFetching(false);
        setShowModal(true);
      } catch (ex) {
        setFetching(false);
        setErrorServer(true);
      }
    }
  };

  const handleClickModal = () => {
    setShowModal(false);
    goToPage(RouteName.Claims);
  };

  if (fetching) {
    return (
      <Spinner />
    );
  }
  return (
    <>
      {showModal && (
      <ModalResult
        handleButtonRight={handleClickModal}
        title={t('createClaim.modal.title')}
        subtitle={t('createClaim.modal.subtitle')}
        buttonTextRight={t('editProfile.modal.ok')}
        Icon={Tool}
        withCheckIcon
        handleButtonClose={handleClickModal}
      />
      )}
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <form onSubmit={onSubmit} noValidate>
        <div className={styles.container}>
          { mobile && (
          <Menu
            list={unitId ? listPathUnit : listPathProperty}
            className={styles.menu}
          />
          ) }
          <CommonForm
            claim={claim}
            setClaim={setClaim}
            formErrors={formErrors}
            setBuildingCommonArea={setBuildingCommonArea}
          />
        </div>
        <div className={styles.footer}>
          {mobile
            ? (
              <CreateFooter
                nextButtonType={ButtonType.Submit}
                nextName={t('createClaim.button')}
                backFn={() => goToPage(RouteName.Claims)}
                showBack
                withBackIcon={false}
                backName={t('createClaim.cancel')}
                Icon={null}
              />
            )
            : (
              <CreateFooter
                nextButtonType={ButtonType.Submit}
                nextName={t('createClaim.button')}
              />
            )}
        </div>
      </form>
    </>
  );
};

export { CreateClaim };
