import { Paginator } from 'models/paginator';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { MaintenanceTabs } from 'common/enums';
import { ModalFilterItem, ModalFilterObject } from 'common/filter-modal/filter-modal';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { MaintenanceFiltersV2 } from './maintenance-filtersV2';

/* Here we define the order and which properties of the object are we going to use for each table
* these should match or model */
/* For the headers, we add an extra empty element to handle the action column */
// TODO:  pending createdOn and tenantName columns, and check all the rest columns.
const maintenanceNew = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'createdOn', 'tenantName'];

// TODO: pending applications, priority and expires columns, and check all the rest columns.
const maintenanceUnassigned = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'taskType', 'applications', 'priority', 'expires'];

// TODO: pending applications and rate columns
const maintenanceNegotiating = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'taskType', 'applications', 'assigned', 'rate'];

// TODO: pending rate column
const maintenanceInProgress = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'startDate', 'assigned', 'rate'];

// TODO: pending endDate and rate column, and check all the rest columns.
const maintenanceSolved = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'endDate', 'assigned', 'rate'];

// TODO: pending rejectedBy and rejectedDate columns, and check all the rest columns.
const maintenanceRejected = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'rejectionDate', 'assigned', 'rejectedBy'];

// TODO: pending archivedDate column, and check all the rest columns.
const maintenanceArchived = ['propertyName', 'address', 'affectedArea', 'taskCategory', 'taskName', 'taskId', 'creationDate', 'archivedDate'];

type TaskChange = {
  placeholderText: string,
  dataProperties: string[],
  filters: ModalFilterObject[],
};

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: 'all',
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  dateFilters: DateFilter,
  tab: string | undefined,
};

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

type TaskState = {
  currentTab: string,
  data: TaskChange,
  paginator: Paginator<MaintenanceTableItem>,
  appliedFilters: ModalFilterItem[],
  code: string,
  showFiltersModal: boolean,
  query: string,
  input: string,
  filterDate: SelectedValueFilter,
};

type Action =
  | { type: 'TAB_CHANGED', newTab: string }
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', showFiltersModal: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'TASKS_FETCHED', paginator: Paginator<MaintenanceTableItem> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string }
  | { type: 'APPLY_FILTER_DATE', filter: SelectedValueFilter };

function switchTab(tab: string): TaskChange {
  switch (tab) {
    case MaintenanceTabs.New:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceNew,
        filters: MaintenanceFiltersV2.allTabs,
      };
    case MaintenanceTabs.Unassigned:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceUnassigned,
        filters: MaintenanceFiltersV2.allTabs,
      };
    case MaintenanceTabs.Negotiating:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceNegotiating,
        filters: MaintenanceFiltersV2.allTabs,
      };
    case MaintenanceTabs.InProgress:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceInProgress,
        filters: MaintenanceFiltersV2.inProgress,
      };
    case MaintenanceTabs.Solved:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceSolved,
        filters: MaintenanceFiltersV2.allTabs,
      };
    case MaintenanceTabs.Rejected:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceRejected,
        filters: MaintenanceFiltersV2.allTabs,
      };
    case MaintenanceTabs.Archived:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceArchived,
        filters: MaintenanceFiltersV2.allTabs,
      };
    default:
      throw new Error('Invalid tab');
  }
}

const changePage = (
  currentPage: number,
  paginator: Paginator<MaintenanceTableItem>,
): Paginator<MaintenanceTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = (urlFilters?: URLFilters) => ({
  currentTab: urlFilters?.tab || MaintenanceTabs.New,
  data: switchTab(urlFilters?.tab || MaintenanceTabs.New),
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: urlFilters?.appliedFilters || [],
  showFiltersModal: false,
  query: '',
  input: '',
  filterDate: urlFilters?.dateFilters || initDateFilter,
});

function MaintenanceDashboardReducerV2(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case 'TAB_CHANGED':
      return {
        ...state,
        data: switchTab(action.newTab),
        currentTab: action.newTab,
        paginator: changePage(1, state.paginator),
        appliedFilters: [],
        filterDate: initDateFilter,
        code: 'FETCHING',
      };
    case 'TASKS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        showFiltersModal: action.showFiltersModal,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        showFiltersModal: false,
        appliedFilters: action.filters,
        paginator: changePage(1, state.paginator),
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        paginator: changePage(1, state.paginator),
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'APPLY_FILTER_DATE':
      return {
        ...state,
        filterDate: action.filter,
        paginator: changePage(1, state.paginator),
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  MaintenanceDashboardReducerV2, initialState, initDateFilter,
};
