import { ReactComponent as Carpentry } from '../../assets/icons/contractor-categories/carpentry.svg';
import { ReactComponent as ConcreteSpecialist } from '../../assets/icons/contractor-categories/concrete-specialist.svg';
import { ReactComponent as Demolition } from '../../assets/icons/contractor-categories/demolition.svg';
import { ReactComponent as Drywaller } from '../../assets/icons/contractor-categories/drywaller.svg';
import { ReactComponent as Electricity } from '../../assets/icons/contractor-categories/electricity.svg';
import { ReactComponent as ElevatorAndOtherLiftingDevices } from '../../assets/icons/contractor-categories/elevator-and-other-lifting-devices.svg';
import { ReactComponent as Excavator } from '../../assets/icons/contractor-categories/excavator.svg';
import { ReactComponent as FloorLaying } from '../../assets/icons/contractor-categories/floor-laying.svg';
import { ReactComponent as GardeningAndLandscape } from '../../assets/icons/contractor-categories/gardening-and-landscape.svg';
import { ReactComponent as GeneralContractor } from '../../assets/icons/contractor-categories/general-contractor.svg';
import { ReactComponent as GlassAndGlazing } from '../../assets/icons/contractor-categories/glass-and-glazing.svg';
import { ReactComponent as Handyman } from '../../assets/icons/contractor-categories/handyman.svg';
import { ReactComponent as HeatingAndAirConditioning } from '../../assets/icons/contractor-categories/heating-and-air-conditioning.svg';
import { ReactComponent as Masonry } from '../../assets/icons/contractor-categories/masonry.svg';
import { ReactComponent as Painting } from '../../assets/icons/contractor-categories/painting.svg';
import { ReactComponent as Plaster } from '../../assets/icons/contractor-categories/plaster.svg';
import { ReactComponent as Plumbing } from '../../assets/icons/contractor-categories/plumbing.svg';
import { ReactComponent as Roofing } from '../../assets/icons/contractor-categories/roofing.svg';
import { ReactComponent as TileSetting } from '../../assets/icons/contractor-categories/tile-setting.svg';
import { ReactComponent as WallpaperInstaller } from '../../assets/icons/contractor-categories/wallpaper-installer.svg';

import { ReactComponent as CarpentryAlert } from '../../assets/icons/contractor-categories-alert/carpentry.svg';
import { ReactComponent as ConcreteSpecialistAlert } from '../../assets/icons/contractor-categories-alert/concrete-specialist.svg';
import { ReactComponent as DemolitionAlert } from '../../assets/icons/contractor-categories-alert/demolition.svg';
import { ReactComponent as DrywallerAlert } from '../../assets/icons/contractor-categories-alert/drywaller.svg';
import { ReactComponent as ElectricityAlert } from '../../assets/icons/contractor-categories-alert/electricity.svg';
import { ReactComponent as ElevatorAndOtherLiftingDevicesAlert } from '../../assets/icons/contractor-categories-alert/elevator-and-other-lifting-devices.svg';
import { ReactComponent as ExcavatorAlert } from '../../assets/icons/contractor-categories-alert/excavator.svg';
import { ReactComponent as FloorLayingAlert } from '../../assets/icons/contractor-categories-alert/floor-laying.svg';
import { ReactComponent as GardeningAndLandscapeAlert } from '../../assets/icons/contractor-categories-alert/gardening-and-landscape.svg';
import { ReactComponent as GeneralContractorAlert } from '../../assets/icons/contractor-categories-alert/general-contractor.svg';
import { ReactComponent as GlassAndGlazingAlert } from '../../assets/icons/contractor-categories-alert/glass-and-glazing.svg';
import { ReactComponent as HandymanAlert } from '../../assets/icons/contractor-categories-alert/handyman.svg';
import { ReactComponent as HeatingAndAirConditioningAlert } from '../../assets/icons/contractor-categories-alert/heating-and-air-conditioning.svg';
import { ReactComponent as MasonryAlert } from '../../assets/icons/contractor-categories-alert/masonry.svg';
import { ReactComponent as PaintingAlert } from '../../assets/icons/contractor-categories-alert/painting.svg';
import { ReactComponent as PlasterAlert } from '../../assets/icons/contractor-categories-alert/plaster.svg';
import { ReactComponent as PlumbingAlert } from '../../assets/icons/contractor-categories-alert/plumbing.svg';
import { ReactComponent as RoofingAlert } from '../../assets/icons/contractor-categories-alert/roofing.svg';
import { ReactComponent as TileSettingAlert } from '../../assets/icons/contractor-categories-alert/tile-setting.svg';
import { ReactComponent as WallpaperInstallerAlert } from '../../assets/icons/contractor-categories-alert/wallpaper-installer.svg';

import { Specializations } from '../enums';

type ContractorCategoryIconProps = {
  category: string;
  warning?: boolean;
  className?: string;
};

const ContractorCategoryIcon = (
  { category, warning = false, className }: ContractorCategoryIconProps,
) => {
  let Icon;
  switch (category) {
    case Specializations.Carpentry:
      Icon = warning ? CarpentryAlert : Carpentry;
      break;
    case Specializations.ConcreteSpecialist:
      Icon = warning ? ConcreteSpecialistAlert : ConcreteSpecialist;
      break;
    case Specializations.Demolition:
      Icon = warning ? DemolitionAlert : Demolition;
      break;
    case Specializations.Drywaller:
      Icon = warning ? DrywallerAlert : Drywaller;
      break;
    case Specializations.Electricity:
      Icon = warning ? ElectricityAlert : Electricity;
      break;
    case Specializations.ElevatorsAndOtherLiftingDevices:
      Icon = warning ? ElevatorAndOtherLiftingDevicesAlert : ElevatorAndOtherLiftingDevices;
      break;
    case Specializations.Excavator:
      Icon = warning ? ExcavatorAlert : Excavator;
      break;
    case Specializations.FloorLaying:
      Icon = warning ? FloorLayingAlert : FloorLaying;
      break;
    case Specializations.GardeningAndLandscape:
      Icon = warning ? GardeningAndLandscapeAlert : GardeningAndLandscape;
      break;
    case Specializations.GeneralContractor:
      Icon = warning ? GeneralContractorAlert : GeneralContractor;
      break;
    case Specializations.GlassAndGlazing:
      Icon = warning ? GlassAndGlazingAlert : GlassAndGlazing;
      break;
    case Specializations.Handyman:
      Icon = warning ? HandymanAlert : Handyman;
      break;
    case Specializations.HeatingAndAirConditioning:
      Icon = warning ? HeatingAndAirConditioningAlert : HeatingAndAirConditioning;
      break;
    case Specializations.Masonry:
      Icon = warning ? MasonryAlert : Masonry;
      break;
    case Specializations.Painting:
      Icon = warning ? PaintingAlert : Painting;
      break;
    case Specializations.Plaster:
      Icon = warning ? PlasterAlert : Plaster;
      break;
    case Specializations.Plumbing:
      Icon = warning ? PlumbingAlert : Plumbing;
      break;
    case Specializations.Roofing:
      Icon = warning ? RoofingAlert : Roofing;
      break;
    case Specializations.TileSetting:
      Icon = warning ? TileSettingAlert : TileSetting;
      break;
    case Specializations.WallpaperInstaller:
      Icon = warning ? WallpaperInstallerAlert : WallpaperInstaller;
      break;
    default:
      Icon = warning ? WallpaperInstallerAlert : WallpaperInstaller;
      break;
  }
  return <Icon className={className} />;
};

export { ContractorCategoryIcon };
