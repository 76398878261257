import { useContext, useState } from 'react';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ReactComponent as CreditCard } from 'assets/icons/creditCard.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { AppLink, RouteName } from 'routes';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'context';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './drawer.module.scss';

const Drawer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [selected] = useState(location.pathname);
  const { state } = useContext(AppContext);
  if (state.user.type === 'Tenant') {
    // TODO change when differents pages are ready.
    return (
      <div className={styles.container}>
        <div className={styles.elementsTenant}>
          <div className={styles.tooltip}>
            <AppLink
              routeName={RouteName.PaymentsTenant}
              className={selected === `/${RouteName.PaymentsTenant}`
                ? styles.selected : styles.icon}
            >
              <Dollar className={selected === `/${RouteName.Home}` ? styles.iconColor : styles.dollar} />
            </AppLink>
            <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.tenant.payments')}</span>
          </div>
          <div className={styles.tooltip}>
            <AppLink
              routeName={RouteName.Claims}
              className={(selected === `/${RouteName.Claims}` || selected.match(`/${RouteName.Claims}/`)
              || selected.match(`/${RouteName.ClaimDetail}/`))
                ? styles.selected : styles.icon}
            >
              <Tool className={(selected === `/${RouteName.Claims}`
              || selected.match(`/${RouteName.ClaimDetail}/`)) ? styles.iconColor : styles.iconGrey}
              />
            </AppLink>
            <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.tenant.claims')}</span>
          </div>
          <div className={styles.tooltip}>
            <AppLink
              routeName={RouteName.MyPropertyTenant}
              className={(selected === (`/${RouteName.MyPropertyTenant}`)) ? styles.selected : styles.icon}
            >
              <Home className={(selected === (`/${RouteName.MyPropertyTenant}`)) ? styles.iconColor : styles.iconGrey} />
            </AppLink>
            <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.tenant.property')}</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.elements}>
        <div className={styles.tooltip}>
          <AppLink
            routeName={RouteName.Properties}
            className={(selected === (`/${RouteName.Properties}`) || selected === '/'
            || selected.match(`/${RouteName.PropertyDetail}/`)) ? styles.selected : styles.icon}
          >
            <Home className={(selected === (`/${RouteName.Properties}`) || selected === '/'
            || selected.match(`/${RouteName.PropertyDetail}/`)) ? styles.iconColor : styles.iconGrey}
            />
          </AppLink>
          <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.properties')}</span>
        </div>
        <div className={styles.tooltip}>
          <AppLink
            routeName={RouteName.Maintenance}
            className={(selected === `/${RouteName.Maintenance}` || selected.match(`/${RouteName.TaskDetail}/`))
              ? styles.selected : styles.icon}
          >
            <Tool className={selected === `/${RouteName.Maintenance}` ? styles.iconColor : styles.iconGrey} />
          </AppLink>
          <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.maintenance')}</span>
        </div>
        <div className={styles.tooltip}>
          <AppLink
            routeName={RouteName.RentalDashboard}
            className={selected.match(`/${RouteName.RentalDashboard}`)
              ? styles.selected : styles.icon}
          >
            <CreditCard className={
              (selected.match(RouteName.RentalDashboard)) ? styles.iconColor : ''
              }
            />
          </AppLink>
          <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.rentals')}</span>
        </div>
        <div className={styles.tooltip}>
          <AppLink
            routeName={RouteName.BillsTable}
            className={selected === `/${RouteName.BillsTable}`
              ? styles.selected : styles.icon}
          >
            <Dollar className={selected === `/${RouteName.Home}` ? styles.iconColor : styles.dollar} />
          </AppLink>
          <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.bills')}</span>
        </div>
        <div className={styles.tooltip}>
          <AppLink
            routeName={RouteName.Leases}
            className={selected === (`/${RouteName.Leases}`)
              ? styles.selected : styles.icon}
          >
            <File className={selected === `/${RouteName.Leases}` ? styles.iconColor : ''} />
          </AppLink>
          <span className={classnames(styles.tooltiptext, 'text__body__small__surface10')}>{t('drawer.leases')}</span>
        </div>
      </div>
    </div>
  );
};

export { Drawer };
