import React from 'react';

import { Sender } from '../../types';
import { Breakpoints } from '../enums';
import { Message, MessageType } from '../message';
import { classnames } from '../../helpers/utils';
import { Input } from '../input';
import styles from './task-updates-overview.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

type TaskUpdatesOverviewProps = {
  messages: MessageType[],
  t: (text: string) => string,
  language: string,
  translPrefix: string,
  userRole: Sender,
};

const TaskUpdatesOverview = ({
  messages, t, language, translPrefix, userRole,
}: TaskUpdatesOverviewProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={styles.container}>
      <div className={styles.titleAndSearchWrapper}>
        <div>
          <div className={classnames(
            `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
            styles.updates,
          )}
          >
            {t(`${translPrefix}.updates`)}
          </div>
          <div className={`text__body__${mobile ? 'small' : 'medium'}__textBody`}>
            {t(`${translPrefix}.updatesSubtitle`)}
          </div>

        </div>
        <Input
          id="search"
          t={t}
          withSearchIcon
          placeholder={t(`${translPrefix}.searchInput.placeholder`)}
          onChange={() => {}}
          containerClass={styles.searchInput}
          textStyle={`text__body__${mobile ? 'small' : 'xsmall'}__textNeutral30`}
        />
      </div>
      {messages.map((message) => (
        <Message
          key={message.id}
          message={message}
          t={t}
          containerClass={styles.message}
          language={language}
          userRole={userRole}
        />
      ))}
      <button
        type="button"
        className={styles.button}
      >
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.addUpdate`)}
        </div>
        <div className={styles.plusIconContainer}>
          <PlusIcon className={styles.plusIcon} />
        </div>

      </button>
    </div>
  );
};

export { TaskUpdatesOverview };
