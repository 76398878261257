import { classnames } from '@mapix/common/src/helpers/utils';
import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './table.module.scss';
import { TableHeader } from './table-header';

type TableProps = {
  headerNames: string[],
  data: any[],
  dataProperties: string[],
  iconCell?: (item: any | null) => JSX.Element[] | null,
  dashboardName: string,
  uniqueId: string,
  emptyState?: ReactNode,
  setId?: (id: any) => void,
  textClassFn?: (item: any) => string,
  rowClick?: (item: any) => void,
  iconCellLeft?: (item: any | null) => JSX.Element[] | null,
  iconCellLeftClass?: string,
  clickable?: boolean,
  cellClassCondition?: string,
  cellClass?: string,
};

const Table = ({
  headerNames, data, dataProperties, iconCell, dashboardName,
  uniqueId, emptyState, setId, rowClick, iconCellLeft, iconCellLeftClass,
  textClassFn = () => 'text__body__small__textNeutral40', clickable = true,
  cellClassCondition, cellClass,
}: TableProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    if (setId !== undefined) {
      setId(id);
    }
  };

  const { t } = useTranslation();

  const getIcons = (item: any) => (iconCell ? iconCell(item) : []);
  const getIconsLeft = (item: any) => (iconCellLeft ? iconCellLeft(item) : []);

  const getCellStyle = (elem: string | undefined): string => {
    if (cellClassCondition && elem === cellClassCondition) {
      return cellClass || '';
    }
    return '';
  };

  const showCellValue = (item: any, key: any) => {
    if (['type', 'propertyName'].includes(key)) {
      return t(`propertyName.${item[key]}`);
    }

    if (['taskType'].includes(key)) {
      return t(`taskTypeName.${item[key]}`);
    }

    return item[key] || '-';
  };

  return (
    <table className={styles.table} cellSpacing={0}>
      <TableHeader hasIcons={!!iconCell} names={headerNames} dashboardName={dashboardName} />
      <tbody>
        {data.length === 0 && <tr><td colSpan={headerNames.length}>{emptyState}</td></tr> }
        {data.map((item) => (
          <tr
          /* @ts-ignore */
            key={item[uniqueId]}
            className={classnames(styles.bodyRow, textClassFn(item), clickable ? styles.clickable : '')}
            onClick={() => rowClick && rowClick(item)}
          >
            {iconCellLeft && (
            <td className={classnames(
              styles.cell,
              iconCellLeftClass || '',
            )}
            >
              <button onClick={(e) => handleClick(e, item[uniqueId])} type="button">
                {getIconsLeft(item)}
              </button>
            </td>
            )}
            {dataProperties.map((key: string) => (
              <td
                key={key}
                className={classnames(
                  styles.cell,
                  styles.cellSize,
                  key !== 'email' ? styles.capitalizeText : '',
                  getCellStyle(item[key]),
                )}
              >
                {showCellValue(item, key)}
              </td>
            ))}
            {iconCell && (
              <td className={classnames(styles.cell, styles.cellSize, styles.iconCell)}>
                <button
                  className={classnames(styles.toRight, 'row align-justify-center')}
                  onClick={(e) => handleClick(e, item[uniqueId])}
                  type="button"
                >
                  {getIcons(item)}
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export { Table };
