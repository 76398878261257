import {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { AppLink, goToPage, RouteName } from 'routes';
import { User as ModelUser } from 'models/user';
import { SessionController } from 'networking/controllers/session-controller';
import { appActions, AppContext } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { GoogleLogin } from '@mapix/common/src/common/google-login';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Users } from 'common/enums';
import { constants } from 'config/constants';
import styles from './login.module.scss';

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { state, dispatch } = useContext(AppContext);
  const [showLogin, setShowLogin] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [showError, setShowError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);

  const errorCode = urlParams.get('error_code');

  useEffect(() => {
    if (errorCode === '4007') {
      setShowError('needsToDoSignUp');
    }
  }, []);

  const redirectPage = (user: ModelUser) => {
    if (user.type === Users.Tenant) {
      if (user.name === '' || user.phoneNumber === '') {
        goToPage(RouteName.HomeTenant, {}, { showModal: true });
      } else {
        goToPage(RouteName.HomeTenant);
      }
    } else if (user.name === '' || user.phoneNumber === '') {
      goToPage(RouteName.Properties, {}, { showModal: true });
    } else {
      goToPage(RouteName.Properties);
    }
  };

  const auth = async () => {
    try {
      setFetching(true);
      const user = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, user });
      setFetching(false);
      redirectPage(user);
    } catch (e) {
      dispatch({ type: appActions.SESSION_EXPIRED });
      setFetching(false);
      setShowLogin(true);
    }
  };
  useEffect(() => {
    auth();
  }, []);

  const handleLogin = async (event: any) => {
    setErrorServer(false);
    setErrorMessage('');
    setShowError('');
    event.preventDefault();
    if (email === '' || password === '') {
      setErrorMessage(t('login.emptyField'));
      setError(true);
      return;
    }
    const userSend: User = {
      email,
      password,
    };
    try {
      setFetching(true);
      const userResult = await SessionController.login(userSend);
      dispatch({ type: appActions.USER_LOGGED, user: userResult });
      setFetching(false);
      if (state.redirectWhenLogged !== '') {
        const route = Object.entries(RouteName)
          .find(([, val]) => val === state.redirectWhenLogged)?.[1];
        dispatch({ type: appActions.REMOVE_POST_AUTH_REDIRECT });
        if (route) {
          goToPage(route);
        }
      } else {
        redirectPage(userResult);
      }
    } catch (ex: any) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    if (id === 'email') {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }

  return (
    showLogin ? (
      <div className={globalStyles.initialContainer}>
        {showError && (
          <ErrorMessage
            message={t(`login.${showError}`)}
            handleClose={() => { setShowError(''); }}
            errorStyle={styles.errorMessage}
          />
        )}
        {errorServer && (
        <ErrorMessage
          message={t('login.error')}
          handleClose={() => { setErrorServer(false); }}
          errorStyle={styles.errorMessage}
        />
        )}

        <div className={styles.content}>

          <div className={styles.titleBox}>
            <p className={classnames(styles.title, 'text__heading3__textNeutral40')}>
              {t('initialpage.title')}
              {' '}
              <span className={styles.blue}>
                Mapix
              </span>
              .
            </p>
            <p className={classnames(styles.subtitle, 'text__heading4__textNeutral40')}>
              {t('initialpage.subtitle')}
              .
            </p>
          </div>
          <div className={styles.loginForm}>
            <form onSubmit={handleLogin}>
              <p className={classnames(styles.loginTitle, 'text__heading5__textNeutral50')}>
                {t('login.login')}
              </p>
              <div className={styles.googleContainer}>
                <GoogleLogin
                  domain={constants.googleLoginRedirect!}
                  googleClientId={constants.googleClientId!}
                />
              </div>
              <p className="text__body__medium__textNeutral50">
                {t('login.emailOption')}
              </p>
              <Input
                id="email"
                containerClass={styles.input}
                label={t('login.email')}
                placeholder={t('login.email')}
                inputStyle={InputStyle.FORM}
                value={email}
                onChange={handleChange}
                error={error && email === ''}
                helperText={error && email === '' ? errorMessage : ''}
                type="email"
                t={t}
                required
              />
              <Input
                id="password"
                containerClass={styles.input}
                label={t('password.password')}
                placeholder={t('password.password')}
                inputStyle={InputStyle.FORM}
                type="password"
                value={password}
                onChange={handleChange}
                error={error && password === ''}
                helperText={error && password === '' ? errorMessage : ''}
                t={t}
                required
              />
              <div className={styles.marginTop}>
                <AppLink
                  routeName={RouteName.PasswordRecovery}
                  className="text__body__small__textNeutral50"
                >
                  {t('login.forgotPassword')}
                </AppLink>
              </div>
              <Button
                className={classnames(styles.button, 'text__body__small__textNeutral50')}
                buttonStyle={ButtonStyle.PrimaryGradient}
                buttonType={ButtonType.Submit}
              >
                {t('login.loginButton')}

              </Button>
              <div className={styles.row}>
                <p className={classnames(styles.textNeedAccount, 'text__body__medium__textNeutral50')}>
                  {t('login.needAccount')}
                </p>
                <AppLink
                  className={classnames(styles.buttonLink, 'text__body__medium__primary')}
                  routeName={RouteName.InitialRegister}
                >
                  {t('login.register')}
                </AppLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null
  );
};

export { Login };
