import axios from 'axios';
import { logger } from 'helpers/logger';
import { Coordinates } from '../../common/map-view';

interface SearchParams {
  q?: string;
  amenity?: string;
  street?: string;
  city?: string;
  county?: string;
  state?: string;
  country?: string;
  postalcode?: string;
}

async function getCoordinates(searchParams: SearchParams): Promise<Coordinates | null> {
  try {
    const url = 'https://nominatim.openstreetmap.org/search';
    const params = {
      format: 'json',
      limit: 1,
      ...searchParams,
    };

    const response = await axios.get(url, { params });
    const { data } = response;

    if (data && data.length > 0) {
      const { lat, lon } = data[0];
      return [parseFloat(lat), parseFloat(lon)];
    }
    return null;
  } catch (error) {
    logger.log(error);
    return null;
  }
}

export { getCoordinates };
export type { Coordinates, SearchParams };
