import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  addError,
  checkHTMLErrors, classnames, HTMLValidationError, isEmptyObject,
} from '@mapix/common/src/helpers/utils';
import {
  ChangeEvent, FormEvent, useState,
} from 'react';
import { CreateFooter } from 'common/creation';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useTranslation } from 'react-i18next';
import { AddressForm, OwnerForm } from 'common/forms';
import { Property } from 'models/property';
import { PropertyController } from 'networking/controllers/property-controller';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { PropertyOwner } from 'models/property-owner';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as CircleClose } from 'assets/icons/circleClose.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { goToPage, RouteName } from 'routes';
import { City } from '@mapix/common/src/types';
import { Coordinates } from '@mapix/common/src/common/map-view';
import styles from './edit-building.module.scss';

const addressTranslPrefix = 'createBuilding.address';
const buildingTranslPrefix = 'createBuilding.building';
const ownerTranslPrefix = 'createBuilding.owner';
const otherOwnerTranslPrefix = 'createBuilding.otherOwner';
const footerTranslPrefix = 'editBuilding';

type ModalState = {
  showClose: boolean,
  showOk: boolean
};

type EditBuildingType = {
  comingProperty: Property,
  getProperty: () => void,
  modalState: ModalState,
  setModalState: (prev: any) => void,
};

const EditBuilding = ({
  comingProperty, getProperty,
  modalState, setModalState,
}: EditBuildingType) => {
  const [fetching, setFetching] = useState(false);
  const [property, setProperty] = useState(comingProperty);
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [errorServer, setErrorServer] = useState(false);

  const { t } = useTranslation();

  const validNumOfUnits = +property.unitsCount >= comingProperty.unitsCount;

  const handleChangeCity = (option: City) => {
    setProperty((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        city: option.name,
        cityId: option.id,
      },
    }));
  };

  const onChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const newData = {
      ...property,
    };
    newData.address = {
      ...property.address,
      [name]: value,
    };
    setProperty(newData);
  };

  const onChangeCoordinates = (coords: Coordinates) => setProperty((prevState) => ({
    ...prevState,
    address: {
      ...prevState.address,
      lat: coords[0],
      long: coords[1],
    },
  }));

  const onChangeBuilding = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const newData = {
      ...property,
      [name]: value,
    };
    setProperty(newData);
  };

  const onChangeOwner = (event: ChangeEvent<HTMLInputElement>, objectToChange: string) => {
    const { value, name } = event.target;
    if (property) {
      if (objectToChange === 'owner') {
        const newData = {
          ...property,
        };
        newData.owner = {
          ...property.owner,
          [name]: value,
        };
        setProperty(newData);
      } else if (objectToChange === 'otherOwner') {
        const newData = {
          ...property,
        };
        if (newData.otherOwner === undefined) {
          newData.otherOwner = new PropertyOwner(null);
        }
        newData.otherOwner[name] = value;
        setProperty(newData);
      }
    }
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string, ownerId: string) => {
    setProperty((prevState) => (
      {
        ...prevState,
        [ownerId]: { ...prevState.owner, phoneNumber, countryCode },
      }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !property.owner.phoneNumber) {
      setFormErrors(checkHTMLErrors(target));
      if (!property.owner.phoneNumber) {
        addError(t('error.emptyField'), 'owner-phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    try {
      await PropertyController.editProperty(property);
      await getProperty();
      setModalState((prevState: any) => ({ ...prevState, showOk: true }));
    } catch (err: any) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  if (fetching || !property) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {modalState.showClose
        && (
        <ModalResult
          Icon={CircleClose}
          title={t('editBuilding.modalClose.title')}
          subtitle={t('editBuilding.modalClose.subtitle')}
          handleButtonRight={
           () => goToPage(RouteName.PropertyDetail, { id: property.id })
          }
          buttonTextRight={t('editBuilding.modalClose.yes')}
          handleButtonClose={() => setModalState((prevState: any) => ({
            ...prevState, showClose: false,
          }))}
          buttonTextLeft={t('editBuilding.modalClose.cancel')}
          handleButtonLeft={() => setModalState((prevState: any) => ({
            ...prevState,
            showClose: false,
          }))}
          iconStyle={styles.circleCloseIcon}
        />
        )}
      {modalState.showOk && (
        <ModalResult
          Icon={Edit}
          title={t('editBuilding.modalOk.title')}
          subtitle={t('editBuilding.modalOk.subtitle')}
          handleButtonRight={
            () => goToPage(RouteName.PropertyDetail, { id: property.id })
          }
          buttonTextRight={t('editBuilding.modalOk.ok')}
          handleButtonClose={
            () => setModalState((prevState: any) => ({ ...prevState, showOk: false }))
          }
          withCheckIcon
        />
      )}
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <div className={styles.topContainer}>
        <div className={styles.topText}>
          <h4 className="text__heading4__textNeutral50">
            {t('editBuilding.title')}
          </h4>
          <h5 className="text__heading5__textNeutral50">
            {comingProperty.fullAddress}
          </h5>
        </div>
        <div className={styles.closeButton}>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={() => setModalState((prevState: any) => ({
              ...prevState,
              showClose: true,
            }))}
          >
            <div className="row justify-between">
              {t('myProfile.close')}
              <Close className={styles.closeIcon} />
            </div>
          </Button>
        </div>
      </div>
      <form className={styles.form} onSubmit={onSubmit} noValidate>
        <div className={classnames(styles.title, 'text__heading6__textNeutral50')}>
          {t(`${addressTranslPrefix}.condoTitle`)}
        </div>

        <AddressForm
          formErrors={formErrors}
          onChange={onChangeAddress}
          address={property.address}
          onClickSelect={handleChangeCity}
          getCities={ResourcesController.getCities}
          onChangeCoordinates={onChangeCoordinates}
        />

        <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
          {t(`${buildingTranslPrefix}.title`)}
        </div>

        <div className={styles.formContainer}>
          <div className={styles.row}>
            <Input
              required
              type="number"
              id="unitsCount"
              name="unitsCount"
              min={1}
              label={t(`${buildingTranslPrefix}.unitAmountLabel`)}
              placeholder={t(`${buildingTranslPrefix}.unitAmountLabel`)}
              helperText={!validNumOfUnits ? `${t(`${buildingTranslPrefix}.minNumOfUnits`)}: ${comingProperty.unitsCount}` : t(`${buildingTranslPrefix}.unitAmountHelper`)}
              formError={formErrors}
              value={property.unitsCount || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBuilding(e)}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              t={t}
            />

            <Input
              required
              type="text"
              id="buildingName"
              name="buildingName"
              label={t(`${buildingTranslPrefix}.buildingNameLabel`)}
              placeholder={t(`${buildingTranslPrefix}.buildingNameLabel`)}
              value={property.buildingName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBuilding(e)}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              t={t}
            />
          </div>

        </div>

        <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
          {t(`${ownerTranslPrefix}.title`)}
        </div>

        <OwnerForm
          isRequired
          formErrors={{ ...formErrors, ...phoneErrors }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOwner(e, 'owner')}
          owner={property.owner}
          onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(phoneNumber, countryCode, 'owner')}
          translPrefix={ownerTranslPrefix}
          field="owner"
          idPrefix="owner"
          setPhoneErrors={setPhoneErrors}
        />
        { property.otherOwner && (
          <OwnerForm
            isRequired={false}
            formErrors={{ ...formErrors, ...phoneErrors }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOwner(e, 'otherOwner')}
            owner={property.otherOwner}
            onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(phoneNumber, countryCode, 'otherOwner')}
            translPrefix={otherOwnerTranslPrefix}
            field="otherOwner"
            idPrefix="otherOwner"
            setPhoneErrors={setPhoneErrors}
          />
        )}
        <CreateFooter
          disableNextCondition={!validNumOfUnits}
          nextButtonType={ButtonType.Submit}
          nextName={t(`${footerTranslPrefix}.save`)}
          Icon={Check}
          iconStyle={styles.checkIcon}
        />

      </form>
    </div>
  );
};

export { EditBuilding };
