import { FormEvent, useEffect, useState } from 'react';
import { logger } from 'helpers/logger';
import { constants } from 'config/constants';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { useMediaQuery } from '../../hooks/use-media-query';
import { SelectPaymentMethod } from './select-payment';
import { Breakpoints, MakePaymentModalState } from '../enums';
import { Spinner } from '../spinner';
import { Modal } from '../modal';
import { checkHTMLErrors, HTMLValidationError } from '../../helpers/utils';
import styles from './make-payment.module.scss';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import {
  RentalTenant, OpenOrderResponse, OpenOrderData,
  PaymentMethod as PaymentMethodType, CardNonSensitiveData, UpdatePayment,
} from '../../types';
import { AddCard, NuveiFields } from './add-card/add-card';
import { MakePaymentResult, NuveiResponse, createPayment } from './create-payment';
import { ReactComponent as DollarSign } from '../../assets/icons/dollar.svg';
import { getSafeCharge } from './safe-charge';
import { MakePaymentInformation, MakePaymentInformationValues } from './make-payment-information';

type PaymentMethodProps = {
  onClose: () => void,
  t: (text: string) => string,
  email: string,
  rental: RentalTenant | null,
  storedCards: PaymentMethodType[],
  openOrderFn: (openOrderData: OpenOrderData,
    setCreatePaymentResult: (
      createPaymentResult: MakePaymentModalState) => void) => Promise<OpenOrderResponse | null>,
  updatePaymentFn: (updatePayment: UpdatePayment) => void,
  refreshData: () => void,
  deletePaymentMethod: (id: number) => Promise<void>,
  toggleShowInfoModal: () => void,
};

const initMakePaymentInformation = (rental: RentalTenant | null) => ({
  amount: '',
  reference: '',
  currency: rental?.currency || '',
});

const initCardNonSensitiveData: CardNonSensitiveData = {
  countryAlpha2Code: '',
  holderName: '',
};

const translPrefix = 'paymentMethod';
const MakePayment = ({
  onClose, t, email, rental, openOrderFn, storedCards, updatePaymentFn, refreshData,
  deletePaymentMethod, toggleShowInfoModal,
}: PaymentMethodProps) => {
  const [contentToShow, setContentToShow] = useState<MakePaymentModalState>(
    MakePaymentModalState.ShowMakePaymentInformationForm,
  );
  const [makePaymentInformation, setMakePaymentInformation] = useState<
  MakePaymentInformationValues>(
    initMakePaymentInformation(rental),
  );
  const [selectedCard, setSelectedCard] = useState<PaymentMethodType | undefined >(undefined);
  const [openOrderData, setOpenOrderData] = useState<OpenOrderResponse>({} as OpenOrderResponse);
  const [safeCharge, setSafeCharge] = useState<any>(null);
  const [nuveiFields, setNuveiFields] = useState<NuveiFields>({} as NuveiFields);
  const [cardNonSensitiveData, setCardNonSensitiveData] = useState<
  CardNonSensitiveData>(initCardNonSensitiveData);

  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleClose = () => {
    if (nuveiFields.cardCvc) nuveiFields.cardCvc.destroy();
    if (nuveiFields.scard) nuveiFields.scard.destroy();
    if (nuveiFields.cardExpiry) nuveiFields.cardExpiry.destroy();
    if (contentToShow === MakePaymentModalState.ShowSuccess) refreshData();
    onClose();
  };

  useEffect(() => {
    if (rental?.currency) {
      // Currency wasn't loaded in time when component is show as page (not modal)
      setMakePaymentInformation((prevState) => ({
        ...prevState,
        currency: rental.currency,
      }));
    }
  }, [rental?.currency]);

  const storeCreatePayment = async (response: NuveiResponse) => {
    const updatePaymentData: UpdatePayment = {
      status: response.result,
      errorCode: response.errCode,
      expirationYear: response.ccExpYear,
      lastFourNumbers: response.last4Digits,
      reason: response.errorDescription,
      id: openOrderData?.id!,
      userPaymentOptionId: response.userPaymentOptionId,
      rentalId: rental?.id!,
      expirationMonth: response.ccExpMonth,
      transactionId: response.transactionId,
    };

    updatePaymentFn(updatePaymentData);

    if (response.status === 'ERROR' || response.result !== MakePaymentResult.APPROVED) {
      setContentToShow(MakePaymentModalState.ShowNuveiError);
      logger.log(response);
      return;
    }

    setContentToShow(MakePaymentModalState.ShowSuccess);
  };

  const createPaymentCall = () => createPayment({
    safeCharge,
    openOrderData,
    email,
    setShowModal: setContentToShow,
    storeCreatePayment,
    cardNonSensitiveData,
    scard: nuveiFields?.scard || undefined,
    selectedCard,
  });

  const onSubmitPayment = () => {
    setContentToShow(MakePaymentModalState.ShowSpinnerSafecharge);
    createPaymentCall();
  };

  const onPayWithStoredCard = () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    createPaymentCall();
  };

  const onSubmitCurrencyAmount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }
    setContentToShow(MakePaymentModalState.ShowSpinner);
    const responseOpenOrder = await openOrderFn({
      rentalId: rental?.id!,
      currency: makePaymentInformation.currency,
      amount: makePaymentInformation.amount,
    }, setContentToShow);

    if (responseOpenOrder === null) {
      setContentToShow(MakePaymentModalState.ShowNuveiError);
      return;
    }

    setOpenOrderData(responseOpenOrder);

    const actualSafeCharge = await getSafeCharge(
      responseOpenOrder?.merchantId,
      responseOpenOrder?.merchantSiteId,
      constants.environment.isProduction,
    );
    setSafeCharge(actualSafeCharge);

    setContentToShow(MakePaymentModalState.ShowPaymentMethods);
  };

  const deletePaymentMethodFn = async (id: number) => {
    try {
      setContentToShow(MakePaymentModalState.ShowSpinner);
      await deletePaymentMethod(id);
      setContentToShow(MakePaymentModalState.ShowPaymentMethods);
    } catch (error) {
      logger.log(error);
      setContentToShow(MakePaymentModalState.ShowGenericError);
    }
  };

  const handleAddCard = () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    setSelectedCard(undefined);

    // Instantiate Nuvei Fields
    const ScFields = safeCharge.fields({
      fonts: [{ cssUrl: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap' }],
    });

    const style = {
      base: {
        fontSize: '16px',
        color: '#3f3f3f',
        '::placeholder': {
          color: '#626D71',
        },
      },
      valid: {
        color: '#3f3f3f',
        ':focus': {
          color: '#303238',
        },
      },
      invalid: {
        color: '#3f3f3f',
      },
    };

    setNuveiFields({
      scard: ScFields.create('ccNumber', { style }),
      cardCvc: ScFields.create('ccCvc', { style }),
      cardExpiry: ScFields.create('ccExpiration', { style }),
    });
    setContentToShow(MakePaymentModalState.ShowAddCard);
  };

  const pageTitle = (
    <div className="text__heading5__textNeutral50">
      {contentToShow === MakePaymentModalState.ShowAddCard
        ? t(`${translPrefix}.payWithNewCard`) : t(`${translPrefix}.payTitle`)}
    </div>
  );

  if (contentToShow === MakePaymentModalState.ShowSpinner) {
    return (<Spinner />);
  }

  if (contentToShow === MakePaymentModalState.ShowSuccess) {
    return (
      <ModalResult
        buttonTextRight={t(`${translPrefix}.createPaymentResult.success.ok`)}
        Icon={DollarSign}
        handleButtonClose={handleClose}
        handleButtonRight={handleClose}
        title={t(`${translPrefix}.createPaymentResult.success.title`)}
        subtitle={t(`${translPrefix}.createPaymentResult.success.subtitle`)}
        withBackground
        modalAccentColor={ModalAccentColor.GREEN}
      />
    );
  }

  if (contentToShow === MakePaymentModalState.ShowNuveiError
    || contentToShow === MakePaymentModalState.ShowGenericError) {
    return (
      <ModalResult
        buttonTextRight={t(`${translPrefix}.createPaymentResult.error.tryAgain`)}
        handleButtonRight={() => setContentToShow(
          MakePaymentModalState.ShowMakePaymentInformationForm,
        )}
        buttonTextLeft={t(`${translPrefix}.createPaymentResult.error.cancel`)}
        handleButtonLeft={() => onClose()}
        handleButtonClose={() => onClose()}
        Icon={DollarSign}
        title={t(`${translPrefix}.createPaymentResult.error.title`)}
        subtitle={t(`${translPrefix}.createPaymentResult.error.${
          MakePaymentModalState.ShowNuveiError ? 'nuveiErrorSubtitle' : 'genericErrorSubtitle'}`)}
        withBackground
        modalAccentColor={ModalAccentColor.RED}
      />
    );
  }

  const getContent = () => {
    if (contentToShow === MakePaymentModalState.ShowAddCard
      || contentToShow === MakePaymentModalState.ShowSpinnerSafecharge) {
      return (
        <AddCard
          t={t}
          cardNonSensitiveData={cardNonSensitiveData}
          setCardNonSensitiveData={setCardNonSensitiveData}
          cancel={handleClose}
          nuveiFields={nuveiFields}
          createPayment={onSubmitPayment}
          contentToShow={contentToShow}
        />
      );
    }

    if (contentToShow === MakePaymentModalState.ShowMakePaymentInformationForm) {
      return (
        <MakePaymentInformation
          t={t}
          formErrors={formErrors}
          makePaymentInformation={makePaymentInformation}
          onCancel={handleClose}
          rentalInfo={rental}
          onSubmit={onSubmitCurrencyAmount}
          setMakePaymentInformation={setMakePaymentInformation}
          toggleShowInfoModal={toggleShowInfoModal}
        />
      );
    }

    return (
      <>
        <SelectPaymentMethod
          selectedCard={selectedCard}
          onCardSelect={(card) => setSelectedCard(card)}
          makePaymentInformation={makePaymentInformation}
          onCancel={handleClose}
          onPay={onPayWithStoredCard}
          t={t}
          handleAddCard={handleAddCard}
          cards={storedCards ?? []}
          deletePaymentMethod={deletePaymentMethodFn}
        />
      </>
    );
  };

  const getContentWrapper = () => (
    <div className={contentToShow === MakePaymentModalState.ShowSpinnerSafecharge
      ? styles.containerSpinnerSafecharge : styles.container}
    >
      { !mobile && (
        <div className={styles.titleContainer}>
          {pageTitle}
          <Close className={styles.close} onClick={handleClose} />
        </div>
      )}

      {getContent()}
    </div>
  );

  return (
    <>
      {mobile ? (getContentWrapper())
        : (
          <Modal withBackground={contentToShow !== MakePaymentModalState.ShowSpinnerSafecharge}>
            {getContentWrapper()}
          </Modal>
        )}
      {contentToShow === MakePaymentModalState.ShowSpinnerSafecharge && (<Spinner />)}
    </>
  );
};

export { MakePayment };
