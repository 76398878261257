const fetchVariable = (varName: string, defaultValue?: string) => {
  if (!process.env[varName] && typeof defaultValue === 'undefined') {
    throw new Error(`Mandatory environment variable ${varName} is not set.`);
  }
  return process.env[varName] || defaultValue;
};

const nodeEnv = fetchVariable('NODE_ENV');
const environment = {
  isDevelopment: nodeEnv === 'development',
  isProduction: nodeEnv === 'production',
};

const ONE_WEEK_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
const MAX_PHOTO_AMOUNT = 6;

export const constants = {
  apiBaseURL: fetchVariable('REACT_APP_API_BASE_URL', ''),
  i18nextDebugMode: fetchVariable('REACT_APP_I18N_DEBUG_MODE', 'false') === 'true',
  publicUrl: fetchVariable('REACT_APP_PUBLIC_URL', ''),
  pdfMaxSize: Number(fetchVariable('REACT_APP_PDF_MB_SIZE', '15')) * 1000000,
  imageMaxSize: Number(fetchVariable('REACT_APP_IMAGE_MB_SIZE', '15')) * 1000000,
  nuveiPdfMaxSize: Number(fetchVariable('REACT_APP_NUVEI_PDF_MB_SIZE', '4')) * 1000000,
  nuveiImageMaxSize: Number(fetchVariable('REACT_APP_NUVEI_IMAGE_MB_SIZE', '4')) * 1000000,
  MAX_PHOTO_AMOUNT,
  i18nextLocalstorageExpirationTime: parseInt(fetchVariable('REACT_APP_I18N_STORAGE_CACHE_EXPIRATION', String(ONE_WEEK_MILLISECONDS)) as string, 10),
  googleTenantRedirect: fetchVariable('REACT_APP_GOOGLE_TENANT_REGISTER_REDIRECT_URL', ''),
  googleManagerRedirect: fetchVariable('REACT_APP_GOOGLE_MANAGER_REGISTER_REDIRECT_URL', ''),
  googleLoginRedirect: fetchVariable('REACT_APP_GOOGLE_ALL_SIGNIN_REDIRECT_URL', ''),
  googleClientId: fetchVariable('REACT_APP_GOOGLE_CLIENT_ID', '***'),
  appName: fetchVariable('REACT_APP_APP_NAME'),

  environment,
};
