import { Paginator } from 'models/paginator';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { ModalFilterItem, ModalFilterObject } from 'common/filter-modal/filter-modal';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { MaintenanceFilters } from './maintenance-filters';

enum MaintenanceTabs {
  New = 'New',
  InProgress = 'InProgress',
  Solved = 'Solved',
  Rejected = 'Rejected',
}

/* Here we define the order and which properties of the object are we going to use for each table
* these should match or model */
/* For the headers, we add an extra empty element to handle the action column */
const maintenanceNew = ['propertyType', 'address', 'buildingName', 'affectedArea', 'taskCategory', 'taskId', 'startDate'];
const maintenanceInProgress = ['propertyType', 'address', 'buildingName', 'affectedArea', 'taskCategory', 'taskId', 'startDate', 'priority', 'assigned'];
const maintenanceSolved = ['propertyType', 'address', 'buildingName', 'affectedArea', 'taskCategory', 'taskId', 'startDate', 'endDate', 'billedAmount', 'assigned'];
const maintenanceRejected = ['propertyType', 'address', 'buildingName', 'affectedArea', 'taskCategory', 'taskId', 'startDate', 'rejectionDate'];

type TaskChange = {
  placeholderText: string,
  dataProperties: string[],
  filters: ModalFilterObject[],
};

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: 'all',
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  dateFilters: DateFilter,
  tab: string | undefined,
};

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

type TaskState = {
  currentTab: string,
  data: TaskChange,
  paginator: Paginator<MaintenanceTableItem>,
  appliedFilters: ModalFilterItem[],
  code: string,
  show: boolean,
  query: string,
  input: string,
  filterDate: SelectedValueFilter,
};

type Action =
  | { type: 'TAB_CHANGED', newTab: string }
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'TASKS_FETCHED', paginator: Paginator<MaintenanceTableItem> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string }
  | { type: 'APPLY_FILTER_DATE', filter: SelectedValueFilter };

function switchTab(tab: string): TaskChange {
  switch (tab) {
    case MaintenanceTabs.New:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceNew,
        filters: MaintenanceFilters.allTabs,
      };
    case MaintenanceTabs.InProgress:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceInProgress,
        filters: MaintenanceFilters.inProgress,
      };
    case MaintenanceTabs.Solved:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceSolved,
        filters: MaintenanceFilters.allTabs,
      };
    case MaintenanceTabs.Rejected:
      return {
        placeholderText: 'maintenanceDashboard.placeholders.all',
        dataProperties: maintenanceRejected,
        filters: MaintenanceFilters.allTabs,
      };
    default:
      throw new Error('Invalid tab');
  }
}

const changePage = (
  currentPage: number,
  paginator: Paginator<MaintenanceTableItem>,
): Paginator<MaintenanceTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = (urlFilters?: URLFilters) => ({
  currentTab: urlFilters?.tab || MaintenanceTabs.New,
  data: switchTab(urlFilters?.tab || MaintenanceTabs.New),
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: urlFilters?.appliedFilters || [],
  show: false,
  query: '',
  input: '',
  filterDate: urlFilters?.dateFilters || initDateFilter,
});

function MaintenanceDashboardReducer(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case 'TAB_CHANGED':
      return {
        ...state,
        data: switchTab(action.newTab),
        currentTab: action.newTab,
        paginator: changePage(1, state.paginator),
        appliedFilters: [],
        filterDate: initDateFilter,
        code: 'FETCHING',
      };
    case 'TASKS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
        paginator: changePage(1, state.paginator),
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        paginator: changePage(1, state.paginator),
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'APPLY_FILTER_DATE':
      return {
        ...state,
        filterDate: action.filter,
        paginator: changePage(1, state.paginator),
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  MaintenanceDashboardReducer, MaintenanceTabs, initialState, initDateFilter,
};
