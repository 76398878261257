import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import styles from './initial-register.module.scss';

const InitialRegister = () => {
  const { t } = useTranslation();
  const handleClickTenant = () => {
    goToPage(RouteName.RegisterTenant);
  };
  const handleClickManager = () => {
    goToPage(RouteName.RegisterManager);
  };

  return (
    <div className={globalStyles.initialContainer}>
      <div className={styles.content}>
        <div className={styles.titleBox}>
          <p className={classnames(styles.title, 'text__heading3__textNeutral40')}>
            {t('initialpage.title')}
            {' '}
            <span className={styles.blue}>
              Mapix
            </span>
            .
          </p>
          <p className={classnames(styles.subtitle, 'text__heading4__textNeutral40')}>
            {t('initialpage.subtitle')}
            .
          </p>
        </div>
        <div className={styles.cardContent}>
          <button className={styles.card} type="button" onClick={handleClickManager}>
            <p className={classnames(styles.cardTitle, 'text__heading5__textNeutral40')}>
              {t('initialpage.iAmRealEstate')}
              <span className={styles.blue}>
                {t('initialpage.manager')}
              </span>
              {t('initialpage.or')}
              <span className={styles.blue}>
                {t('initialpage.landlord')}
              </span>
            </p>
            <p className={classnames(styles.cardSubtitle, 'text__body__medium__textBody')}>
              {t('initialpage.cardLeftSubtitle')}
              .
            </p>
          </button>
          <button className={styles.card} onClick={handleClickTenant} type="button">
            <p className={classnames(styles.cardTitle, 'text__heading5__textNeutral40')}>
              {t('initialpage.iAmA')}
              <span className={styles.green}>
                {t('initialpage.tenant')}
              </span>
            </p>
            <p className={classnames(styles.cardSubtitle, 'text__body__medium__textBody')}>
              {t('initialpage.cardRightSubtitle')}
              .
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export { InitialRegister };
