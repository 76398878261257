import { PropertyOwnerSerializer } from 'networking/serializers/property-owner-serializer';
import { ServicesSerializer } from 'networking/serializers/services-serializer';
import { FileSerializer } from './file-serializer';

class LeaseSerializer {
  static serialize(data: SerializedLease): RawLease {
    return {
      id: data.id,
      currency: data.currency,
      expiration_date: data.expirationDate,
      fees: data.fees || 0,
      lease_copy: data.leaseCopy ? FileSerializer.serialize(data.leaseCopy) : null,
      monthly_rental: data.monthlyRental,
      services: data.services ? ServicesSerializer.serialize(data.services) : null,
      size: data.size,
      start_date: data.startDate,
      tenant: data.tenant ? PropertyOwnerSerializer.serialize(data.tenant) : null,
      tenant_id_photos: data.tenantIdPhotos.map((item) => FileSerializer.serialize(item)),
      unit_photos: data.unitPhotos.map((item) => FileSerializer.serialize(item)),
      status: data.status ? data.status : undefined,
      extension_date: null,
    };
  }

  static deSerialize(data: RawLease): SerializedLease {
    return {
      id: data.id,
      currency: data.currency,
      expirationDate: data.expiration_date,
      fees: data.fees,
      leaseCopy: data.lease_copy,
      monthlyRental: data.monthly_rental,
      services: data.services ? ServicesSerializer.deSerialize(data.services) : null,
      size: data.size,
      startDate: data.start_date,
      tenant: data.tenant ? PropertyOwnerSerializer.deSerialize(data.tenant) : null,
      tenantIdPhotos: data.tenant_id_photos,
      unitPhotos: data.unit_photos,
      status: data.status ? data.status : undefined,
      extensionDate: data.extension_date,
    };
  }

  static serializeEditCondo(data: SerializedLeaseEditCondo): RawLeaseEditCondo {
    /*
        TODO add tenant id photo, unit photo, and lease copy
    */
    return {
      currency: data.currency,
      fees: data.fees,
      monthly_rental: data.monthlyRental,
      services: data.services ? ServicesSerializer.serialize(data.services) : null,
      size: data.size,
    };
  }

  static serializeEditUnit(data: SerializedLeaseEditUnit): RawLeaseEditUnit {
    // TODO add photos when backend fix the problem
    return {
      currency: data.currency,
      monthly_rental: data.monthlyRental,
      services: data.services ? ServicesSerializer.serialize(data.services) : null,
      size: data.size,
    };
  }
}

export { LeaseSerializer };
