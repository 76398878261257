import { classnames, getDisplayFileSize, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  BuildingArea, TaskType,
} from 'common/enums';
import { Priority } from '@mapix/common/src/common/enums';
import { Select } from '@mapix/common/src/common/select';
import { FileUpload } from 'common/image-upload';
import { TextArea } from '@mapix/common/src/common/textarea';
import { UnitNumberInput } from 'common/unit-number-input';
import { SimpleUnit } from 'models/simple-unit';
import { constants } from 'config/constants';
import { RadioButtonMobile } from '@mapix/common/src/common/radio-button-mobile';
import { ReactComponent as Info } from '@mapix/common/src/assets/icons/info24.svg';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { SelectCategory } from 'common/select-category';
import styles from './create-edit-new-task-container-step-2.module.scss';

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

type Action =
  | { type: 'CHANGE_FIELD', value: string | number | Specialization, field: string }
  | { type: 'TASK_PHOTOS', taskPhotos: FileType[] }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string };

type CreateEditNewTaskContainerStep2Props = {
  fn: (action: Action) => void,
  createTaskInfo: CreateNewTaskState,
  formErrors: HTMLValidationError,
};

const translPrefix = 'createTask';

const createTaskArea = `${translPrefix}.concernedArea`;

const CreateEditNewTaskContainerStep2 = ({
  fn, createTaskInfo, formErrors,
}: CreateEditNewTaskContainerStep2Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    option: string,
  ) => {
    fn({
      type: 'CHANGE_FIELD', field: option, value: event.target.value,
    });
  };

  const onClickSelect = (option: string | Specialization, objField: string) => {
    fn({
      type: 'CHANGE_FIELD', field: objField, value: option,
    });
  };

  const onChangeRadioButton = (item: string) => {
    onClickSelect(item, 'area');
    if (item === 'common') {
      fn({
        type: 'UNIT_SELECTED', unitNumber: '', specificUnit: null,
      });
    }
  };

  const getUnitSelected = (unit: SimpleUnit) => {
    fn({
      type: 'UNIT_SELECTED', unitNumber: unit.unitNumber, specificUnit: unit.id,
    });
  };

  return (
    <>

      {createTaskInfo.buildingType && (
        <>
          <div className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.area`)}</div>
          <div className={styles.checkboxContainer}>
            <form className={styles.form}>
              <div className={styles.area}>
                {Object.keys(BuildingArea).map((item) => (
                  <RadioButtonMobile
                    key={item}
                    max={1}
                    onChangeFn={() => onChangeRadioButton(item)}
                    maxLength={1}
                    containerClass={styles.checkbox}
                    labelClass={classnames('text__body__medium__textNeutral40', styles.label)}
                    labelContent={t(`${createTaskArea}.${(item)}`)}
                    name="area"
                    checked={item === createTaskInfo.area}
                  />
                ))}
              </div>
            </form>
            <UnitNumberInput
              propertyId={createTaskInfo.propertyId as number}
              disabled={createTaskInfo.area !== 'specific'}
              prefix="createTask.unit"
              onSelectUnitFn={getUnitSelected}
              query={createTaskInfo.unitNumber}
              required={createTaskInfo.buildingType && createTaskInfo.area === 'specific'}
              formError={formErrors}
            />
          </div>
        </>
      )}
      <div className={classnames('text__heading6__textNeutral50', styles.firstMargin)}>{t(`${translPrefix}.breakdown.title`)}</div>
      <div className="row">
        <SelectCategory
          categoryContainerClass={styles.inputLarge}
          otherCategoryContainerClass={styles.inputLarge}
          selectCategory={(category: Specialization) => onClickSelect(category, 'category')}
          setOtherCategory={(category: string) => onClickSelect(category, 'otherCategory')}
          category={createTaskInfo.category}
          formErrors={formErrors}
          otherCategory={createTaskInfo.otherCategory}

        />

        <Input
          id="name"
          name="name"
          label={t(`${translPrefix}.breakdown.placeholders.taskName`)}
          placeholder={t(`${translPrefix}.breakdown.placeholders.taskName`)}
          helperText={t(`${translPrefix}.breakdown.helperTexts.taskName`)}
          value={createTaskInfo.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'name')}
          required
          t={t}
          containerClass={styles.inputLarge}
          inputStyle={InputStyle.FORM}
        />
      </div>

      <TextArea
        required
        id="description"
        placeholder={t(`${translPrefix}.breakdown.placeholders.description`)}
        value={createTaskInfo.description}
        maxLength={500}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 'description')}
        containerClass={styles.inputDescription}
        formError={formErrors}
        t={t}
      />

      <div className={classnames('text__heading6__textNeutral50', styles.sectionTitle)}>
        {t(`${translPrefix}.priority.title`)}
      </div>
      <Select
        options={Object.values(Priority)}
        onClickOption={(option) => onClickSelect(option, 'priority')}
        optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
        required
        id="priority"
        value={createTaskInfo.priority ? `${t(`taskPriority.${createTaskInfo.priority}`)}` : ''}
        label={t(`${translPrefix}.priority.select`)}
        placeholder={t(`${translPrefix}.priority.select`)}
        inputStyle={InputStyle.FORM}
        containerClass={styles.inputLarge}
        formError={formErrors}
        translationPrefix="taskPriority"
        t={t}
      />

      <div className={styles.sectionTitleWrapper}>
        <span className="text__heading6__textNeutral50">{t(`${translPrefix}.taskType.title`)}</span>
        <button onClick={() => setShowModal(true)} type="button">
          <Info className={styles.info} />
        </button>
      </div>

      <div className="row">
        {Object.values(TaskType).map((item) => (
          <RadioButtonMobile
            key={item}
            max={1}
            onChangeFn={() => onClickSelect(item, 'taskType')}
            maxLength={1}
            containerClass={styles.checkbox}
            labelClass={classnames(
              `text__body__medium__textNeutral${item === TaskType.Public ? '30' : '40'}`,
              styles.label,
            )}
            labelContent={t(`${translPrefix}.taskType.${item}`)}
            name="taskType"
            checked={createTaskInfo.taskType === item}
            disabled={item === TaskType.Public}
          />
        ))}
      </div>

      <div className={classnames('text__body__small__textNeutral40', styles.disclaimer)}>
        {t(`${translPrefix}.taskType.versionDisclaimer`)}
      </div>

      <div className={classnames(styles.sectionTitle, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.photos.title`)}</div>
      <FileUpload
        fetchedFiles={createTaskInfo.taskPhotos}
        fileAddedFn={(files: FileType[]) => {
          fn({
            type: 'TASK_PHOTOS', taskPhotos: files,
          });
        }}
        title={t('createTask.photos.description')}
        restrictionText={t('createTask.photos.size', { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}
      />
      {showModal && (
      <ModalInformation
        handleButtonClose={() => setShowModal(false)}
        handleButtonRight={() => setShowModal(false)}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
        containerClass={styles.modalContainer}
      >
        <>
          <div className={classnames('text__heading5__textNeutral40', styles.modalTitle)}>
            {t(`${translPrefix}.modal.title`)}

          </div>
          {['public', 'private', 'unpublished'].map((item) => (
            <p className="text__body__medium__textNeutral50">
              <span className={styles.semibold}>{`${t(`${translPrefix}.taskType.${item}_task`)}: `}</span>
              <span>{t(`${translPrefix}.modal.${item}Body`)}</span>
            </p>
          ))}
        </>
      </ModalInformation>
      )}
    </>

  );
};

export { CreateEditNewTaskContainerStep2 };
