import { User as ModelUser } from 'models/user';
import { API_ROUTES } from 'networking/api-routes';
import { UserSerializer } from 'networking/serializers/user-serializer';
import { ApiService } from '../api-service';

class SessionController {
  static async login(user: User) {
    const serializedUser = UserSerializer.serialize(user);
    const result = await ApiService.post<RawLogin>(API_ROUTES.LOGIN, serializedUser);
    return new ModelUser(UserSerializer.deSerialize(result.data.user));
  }

  static async logout() {
    return ApiService.delete(API_ROUTES.LOGIN);
  }
}
export { SessionController };
