import { useTranslation } from 'react-i18next';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { FormEvent, useState } from 'react';
import { BillArea } from 'common/enums';
import { BillCreate } from 'models/bill-create';
import { BillForm } from 'common/bill-form';
import { SimpleUnit } from 'models/simple-unit';
import { CreateFooter, CreateHeader } from 'common/creation';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ButtonType } from '@mapix/common/src/common/button';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { BillsController } from 'networking/controllers/bills-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import styles from './edit-bill.module.scss';

type EditBillProps = {
  propertyId: number,
  billId: number,
  fullAddress: string,
  propertyType: string,
  prevBill: BillCreate,
  finish: () => void,
  close: () => void,
};

const initState = (bill: BillCreate | null) => (bill || new BillCreate());

const translPrefix = 'addBill';
const EditBill = ({
  propertyId, prevBill, finish, close, fullAddress, propertyType, billId,
}: EditBillProps) => {
  const [bill, setBill] = useState<BillCreate>(initState(prevBill));
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});

  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      if (bill.affectedArea === BillArea.CommonArea) {
        delete bill.unitId;
      }
      if (bill.billPaid === 'no') {
        delete bill.paymentDate;
      }
      try {
        await BillsController.editBills(bill, billId);
        setShowModal(true);
      } catch (err: any) {
        logger.error(err);
        setShowError(true);
      }
    }
  };

  const onUnitSelected = (unit: SimpleUnit) => {
    setBill((prevState) => ({ ...prevState, unitId: unit.id }));
  };

  const changeBill = (option: string, field: string) => setBill(
    (prevState) => ({ ...prevState, [field]: option }),
  );

  return (
    <form onSubmit={onSubmit} noValidate className={styles.container}>

      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}

      {showModal && (
        <ModalResult
          title={t(`${translPrefix}.modal.editTitle`)}
          subtitle={t(`${translPrefix}.modal.editSubTitle`)}
          Icon={CheckCircle}
          buttonTextRight={t(`${translPrefix}.modal.okButton`)}
          handleButtonRight={() => finish()}
          handleButtonClose={() => finish()}
          withCheckIcon
        />
      )}

      <CreateHeader
        closeFn={close}
        title={t(`${translPrefix}.editBillHeader`)}
        closeText={t(`${translPrefix}.closeHeader`)}
      />

      <div className={classnames(styles.addressTitle, 'text__heading5__textNeutral50')}>
        {fullAddress}
      </div>

      <BillForm
        propertyType={propertyType}
        propertyId={propertyId}
        formErrors={formErrors}
        bill={bill}
        changeBillFn={changeBill}
        onUnitSelectedFn={onUnitSelected}
      />

      <CreateFooter
        nextName={t(`${translPrefix}.form.save`)}
        Icon={Check}
        iconStyle={styles.check}
        nextButtonType={ButtonType.Submit}
      />

    </form>
  );
};

export { EditBill };
