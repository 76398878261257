enum Currency {
  USD = 'USD',
  CAD = 'CAD',
}

enum UnitSizes {
  OneHalf = '1 1/2',
  TwoHalf = '2 1/2',
  ThreeHalf = '3 1/2',
  FourHalf = '4 1/2',
  FiveHalf = '5 1/2',
  SixHalf = '6 1/2',
  SevenHalf = '7 1/2',
  EightHalf = '8 1/2',
  NineHalf = '9 1/2',
  TenHalf = '10 1/2',
}

enum Services {
  Heating = 'Heating',
  Electricity = 'Electricity',
  AcceptsPets = 'Accepts pets',
  Water = 'Water',
  Refrigerator = 'Refrigerator',
  Furnished = 'Furnished',
  Stove = 'Stove',
  ParkingLot = 'Parking Lot',
  Other = 'Other',
}

enum ContractorCategories {
  Plumbing = 'Plumbing',
  Electricity = 'Electricity',
  Extermination = 'Extermination',
  HeatingRepair = 'Heating Repair',
  MinorRepair = 'Minor Repair',
  Gardening = 'Gardening',
  SnowRemoving = 'Snow Removing',
  Superintendent = 'Superintendent',
  Other = 'Other',
}

enum PropertyType {
  Building = 'Building',
  CondoHouse = 'CondoHouse',
}

enum Condition {
  Active = 'active',
  Draft = 'draft',
  Archived = 'archived',
}

enum PropertyStatus {
  Available = 'available',
  Rented = 'rented',
}

enum Breakpoints {
  xl = 1400,
  lg = 1200,
  md = 992,
  sm = 768,
  xs = 375,
}

enum Priority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

enum BuildingArea {
  common = 'Building Common Area',
  specific = 'Specific Unit',
}

enum DateCodes {
  All = 'all',
  Last7Days = 'last7Days',
  LastMonth = 'lastMonth',
  Last3Months = 'last3months',
  Last6Months = 'last6months',
  CustomDate = 'customDate',
}

enum BillStatus {
  Paid = 'Paid',
  Overdue = 'Overdue',
  Pending = 'Pending',
}

enum Users {
  Manager = 'Manager',
  Tenant = 'Tenant',
}

enum TaskEvents {
  created = 'Task created',
  started = 'Task started',
  newToRejected = 'New to Rejected',
  newToInProgress = 'New to In Progress',
  inProgressToRejected = 'In Progress to Rejected',
  inProgressToSolved = 'In Progress to Solved',
  assigned = 'Contractor assigned',
  reassigned = 'Contractor reassigned',
  assignementCancelled = 'Assignment cancelled',
  billed = 'Task billed',
  payment = 'Payment registered',
  billDeleted = 'Bill deleted',
}

enum TaskType {
  Public = 'public_task',
  Private = 'private_task',
  Unpublished = 'unpublished_task',
}

enum Status {
  inProgress = 'In Progress',
  new = 'New',
  solved = 'Solved',
  rejected = 'Rejected',
}

enum RentalsStatus {
  Pending = 'pending',
  Paid = 'paid',
  Overdue = 'overdue',
}

enum BillArea {
  CommonArea = 'commonArea',
  Unit = 'unit',
}

enum PaymentStates {
  OnTime = 'onTime',
  Late = 'late',
  PaidOnTime = 'paidOnTime',
  PaidLate = 'paidLate',
}

enum RentalActionCodes {
  SelectAction = 'selectAction',
  RegisterPayment = 'registerPayment',
  KeepStatus = 'keepStatus',
  DeleteRental = 'deleteRental',
}

enum MaintenanceTabs {
  New = 'New',
  Unassigned = 'Unassigned',
  Negotiating = 'Negotiating',
  InProgress = 'InProgress',
  Solved = 'Solved',
  Rejected = 'Rejected',
  Archived = 'Archived',
}

export {
  Currency, UnitSizes, Services, ContractorCategories, PropertyType, Condition, PropertyStatus,
  Breakpoints, BuildingArea, Priority, DateCodes, Users, TaskEvents, BillStatus, Status,
  RentalsStatus, BillArea, PaymentStates, RentalActionCodes, TaskType, MaintenanceTabs,
};
