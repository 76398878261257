import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { goToPage, RouteName } from 'routes';
import { appActions, AppContext } from 'context';
import { ModalResult } from 'common/modal-result';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ResendConfirmEmailButton } from 'common/resend-confirm-email-button';
import { Storage, StorageKeys } from 'helpers/storage';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { SessionController } from 'networking/controllers/session-controller';

import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './verify-user.module.scss';

const VerifyUser = () => {
  const { dispatch } = useContext(AppContext);
  const [showError, setShowError] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const { t } = useTranslation();

  const translPrefix = 'verifyUser';

  const handleLogout = async () => {
    try {
      await SessionController.logout();
      dispatch({ type: appActions.SESSION_EXPIRED });
      Storage.remove(StorageKeys.PropertyKey);
      goToPage(RouteName.Login);
    } catch (exception) {
      setShowError(true);
    }
  };

  return (
    <div className={styles.container}>
      {showError && (
      <ErrorMessage
        message={t('error.errorMessage')}
        handleClose={() => setShowError(false)}
      />
      )}
      {showModalLogout && (
        <ModalResult
          Icon={Logout}
          title={t('myProfile.confirmLogout')}
          handleButtonRight={() => handleLogout()}
          handleButtonLeft={() => setShowModalLogout(false)}
          buttonTextLeft={t('myProfile.cancel')}
          buttonTextRight={t('myProfile.logout')}
          handleButtonClose={() => setShowModalLogout(false)}
          iconStyle={styles.logoutIcon}
        />
      )}
      <div className={styles.content}>
        <p className={classnames(styles.title, 'text__heading5__textNeutral50')}>
          {t(`${translPrefix}.title`)}
        </p>
        <p className="text__body__medium__textNeutral50">
          {t(`${translPrefix}.description`)}
        </p>
        <Button
          buttonStyle={ButtonStyle.Primary}
          className={classnames(styles.buttonLogOut, 'text__body__small__primary')}
          onClick={() => setShowModalLogout(true)}
        >
          {t('myProfile.logout')}
        </Button>
        <ResendConfirmEmailButton />
      </div>
    </div>
  );
};

export { VerifyUser };
