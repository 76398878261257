class SpecializationSerializer {
  static deSerializeSpecialization(data: RawSpecialization): Specialization {
    return {
      name: data.name,
      id: data.id,
    };
  }

  static deSerializeAllSpecializations(data: RawSpecialization[]): Specialization[] {
    return data.map(
      (specialization: RawSpecialization) => this.deSerializeSpecialization(specialization),
    );
  }
}

export { SpecializationSerializer };
