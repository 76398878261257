import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { getFiltersByUrl, groupFilters } from 'helpers/utils';
import { Tab } from '@mapix/common/src/common/tab';
import { FilterByDate } from 'common/filter-by-date';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Modal } from '@mapix/common/src/common/modal';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { FilterModal } from 'common/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { MaintenanceTabs } from 'common/enums';
import { EmptyState } from 'common/empty-state';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import {
  initDateFilter,
  initialState,
  MaintenanceDashboardReducerV2,
} from './maintenance-dashboard-reducerV2';
import styles from './maintenance-dashboardV2.module.scss';

const MaintenanceDashboardV2 = () => {
  const params = new URLSearchParams(window.location.search);
  const [state, dispatch] = useReducer(
    MaintenanceDashboardReducerV2,
    initialState(getFiltersByUrl(params.toString())),
  );
  const [idRequest, setIdRequest] = useState('');
  const { t } = useTranslation();

  const iconCells = () => [
    <ChevronRight
      className={styles.icons}
      key="chevron"
    />];

  // Every time that idTask changes means that I clicked on
  // the table, so I wanna go to the detail of the selected task.
  useEffect(() => {
    if (idRequest !== '') {
      goToPage(RouteName.PrivateRequestDetail, { id: idRequest });
    }
  }, [idRequest]);

  const getTasks = async () => {
    try {
      const response = await MaintenanceController
        .getNewTasks(
          state.paginator?.currentPage || 1,
          10,
          state.filterDate,
          state.currentTab,
          state.query,
          state.appliedFilters,
        );
      dispatch({ type: 'TASKS_FETCHED', paginator: response });
    } catch (err) {
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    getTasks();
  }, [state.currentTab, state.paginator?.currentPage, state.appliedFilters,
    state.query, state.filterDate]);

  const clickTab = (option: string) => {
    groupFilters(option, initDateFilter, []);
    dispatch({ type: 'TAB_CHANGED', newTab: option });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', showFiltersModal: !state.showFiltersModal });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    groupFilters(state.currentTab, state.filterDate, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    groupFilters(state.currentTab, state.filterDate, []);
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    groupFilters(state.currentTab, state.filterDate, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const applyFilterDate = (startDate: string, endDate: string, code: string) => {
    const dateFilter = { startDate, endDate, code };
    groupFilters(state.currentTab, dateFilter, state.appliedFilters);
    dispatch({ type: 'APPLY_FILTER_DATE', filter: dateFilter });
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const handleRedirect = (item: MaintenanceTableItem) => {
    if (['New', 'Unassigned', 'Negotiating'].includes(state.currentTab)) {
      goToPage(RouteName.PrivateRequestDetail, { id: item.activeRequestAssignedId });
    } else {
      goToPage(RouteName.TaskDetailV2, { id: item.taskId });
    }
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING') {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }
    if ((state.query !== '' || state.filterDate.startDate !== '') && state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search')}
          />
        </div>
      );
    }

    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Tool}
            title={t('emptyState.maintenance.title')}
            subtitle={t('emptyState.maintenance.subtitle')}
            handleOnClick={() => goToPage(RouteName.CreateNewTask)}
            iconStyle={styles.toolIcon}
          />
        </div>
      );
    }

    return (
      <>
        <Table
          headerNames={state.data.dataProperties}
          data={state.paginator.results}
          dataProperties={state.data.dataProperties}
          dashboardName="maintenanceDashboard"
          iconCell={iconCells}
          uniqueId="taskId"
          setId={setIdRequest}
          rowClick={(item: MaintenanceTableItem) => handleRedirect(item)}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div className={styles.dashboardContainer}>

      {state.showFiltersModal && (
      <Modal>
        <FilterModal
          appliedFilters={state.appliedFilters}
          filters={state.data.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix="maintenanceDashboard"
        />
      </Modal>
      )}

      <div className="text__heading4__textNeutral40">
        {t('maintenanceDashboard.title')}
      </div>
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t('maintenanceDashboard.subTitle')}
      </p>

      <div className="row">
        {Object.entries(MaintenanceTabs).map(([k, v]) => (
          <div key={k} className={styles.tabContainer}>
            <Tab
              onClickFn={() => clickTab(k)}
              name={t(`maintenanceDashboard.tabs.${v.toLowerCase()}`)}
              isActive={state.currentTab === k}
            />
          </div>
        ))}
      </div>

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t(state.data.placeholderText)}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />

        <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
          <div className="row justify-between">
            <div className="text__button__medium__primary60">
              {t('maintenanceDashboard.buttons.filter')}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button>

        {state.appliedFilters.length > 0
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix="maintenanceDashboard"
        />
        )}

        <div className={styles.rightButtons}>
          <div className={styles.dateFilter}>
            <FilterByDate
              onClickOption={applyFilterDate}
              key={state.currentTab}
              dateFilter={(params.toString()) ? state.filterDate : initDateFilter}
            />
          </div>
          <Button
            className={styles.propertyButton}
            buttonStyle={ButtonStyle.Primary}
            onClick={() => goToPage(RouteName.CreateNewTask)}
          >
            <div className="row align-justify-center">
              <PlusCircle className={styles.plusIcon} />
              {t('maintenanceDashboard.buttons.createTask')}
            </div>
          </Button>
        </div>
      </div>

      {getTable()}
    </div>
  );
};

export { MaintenanceDashboardV2 };
