import { MakePayment } from '@mapix/common/src/common/make-payment';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { useContext, useEffect, useState } from 'react';
import { logger } from 'helpers/logger';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context';
import { List, Menu } from 'common/menu/menu';
import { RentalTenant, PaymentMethod as PaymentMethodType, UpdatePayment } from '@mapix/common/src/types';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints, MakePaymentModalState } from '@mapix/common/src/common/enums';
import { goToPage, RouteName } from 'routes';
import { CreatePaymentController } from 'networking/controllers/create-payment-controller';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './make-payment-page.module.scss';

const translPrefix = 'tenantCreatePayment';

const MakePaymentPage = () => {
  const { rentalId } = useParams<{ rentalId: string }>();
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const [rental, setRental] = useState<RentalTenant | null>(null);
  const [storedCards, setStoredCards] = useState<PaymentMethodType[]>([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [fetching, setFetching] = useState(true);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const breadCrumbs: List[] = [
    { name: t(`${translPrefix}.breadcrumbHome`), route: RouteName.HomeTenant },
    { name: t(`${translPrefix}.breadcrumbPayment`), route: RouteName.PaymentsTenant },
    { name: t(`${translPrefix}.breadcrumbMakePayment`), route: RouteName.MakePaymentPage },
  ];

  const openOrderFn = async (openOrderData: OpenOrderData, setCreatePaymentResult: (
    createPaymentResult: MakePaymentModalState) => void) => {
    try {
      return await CreatePaymentController.openOrder(openOrderData);
    } catch (err) {
      logger.error(err as Error);
      setCreatePaymentResult(MakePaymentModalState.ShowNuveiError);
      return null;
    }
  };

  const storedCardsFn = async () => {
    try {
      setFetching(true);
      const response = await CreatePaymentController.getPaymentMethod();
      setStoredCards(response);
      setFetching(false);
      return response;
    } catch (err) {
      setFetching(false);
      logger.error(err as Error);
      return [];
    }
  };

  const updatePaymentFn = async (updatePayment: UpdatePayment) => {
    try {
      await CreatePaymentController.updatePayment(updatePayment);
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const getRental = async () => {
    try {
      const response = await RentalsController.getTenantRental(Number(rentalId));
      const rentalData: RentalTenant = {
        id: response.id,
        dueDate: response.dueDate,
        paymentDate: response.paymentDate,
        paymentStatus: response.status,
        amount: response.rentalAmount,
        currency: response.currency,
        fullMonth: response.fullMonth,
        leaseExpirationDate: response.leaseExpirationDate,
        extensionBorderRental: response.extensionBorderRental,
      };
      setRental(rentalData);
      setFetching(false);
    } catch (err: any) {
      logger.error(err);
    }
  };

  const deletePaymentMethodFn = async (id: number) => {
    await CreatePaymentController.deletePaymentMethod(id);
    await storedCardsFn();
  };

  const refreshData = async () => {
    getRental();
    storedCardsFn();
  };

  useEffect(() => {
    getRental();
    storedCardsFn();
  }, []);

  if (fetching) {
    return (
      <Spinner />
    );
  }

  return (
    <div className={styles.container}>
      {mobile && <Menu className={styles.menu} list={breadCrumbs} />}
      <MakePayment
        onClose={() => goToPage(RouteName.PaymentsTenant)}
        t={t}
        email={state.user.email}
        rental={rental}
        openOrderFn={openOrderFn}
        storedCards={storedCards}
        updatePaymentFn={updatePaymentFn}
        refreshData={refreshData}
        deletePaymentMethod={deletePaymentMethodFn}
        toggleShowInfoModal={() => setShowInfoModal(true)}
      />
      {showInfoModal && (
      <ModalInformation
        handleButtonClose={() => setShowInfoModal(false)}
        handleButtonRight={() => setShowInfoModal(false)}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
      >
        <div className={classnames('text__body__medium__textNeutral40', styles.modalText)}>
          {t('tenantPayments.card.amountTooltip')}
        </div>
      </ModalInformation>
      )}
    </div>
  );
};

export { MakePaymentPage };
