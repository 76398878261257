import { FormEvent } from 'react';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { classnames } from '../../../helpers/utils';
import { PaymentMethodsList } from '../payment-methods-list';
import styles from './select-payment.module.scss';
import { PaymentMethod } from '../../../types';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { MakePaymentInformationValues } from '../make-payment-information';
import { BottomButtonsMakePayment } from '../bottom-buttons-make-payment/bottom-buttons-make-payment';

type SelectPaymentMethodProps = {
  cards: PaymentMethod[],
  t: (text: string) => string,
  onCancel: () => void,
  onCardSelect: (card: PaymentMethod) => void,
  selectedCard?: PaymentMethod,
  onPay: (e: FormEvent<HTMLFormElement>) => void,
  handleAddCard: () => void,
  makePaymentInformation: MakePaymentInformationValues
  deletePaymentMethod: (id: number) => void,
};

const isChecked = (
  card: PaymentMethod,
  selected?: PaymentMethod,
) => card.userPaymentOptionId === selected?.userPaymentOptionId;

const translPrefix = 'paymentMethod';
const SelectPaymentMethod = ({
  cards, t, onCancel, onCardSelect, selectedCard, onPay, handleAddCard,
  makePaymentInformation, deletePaymentMethod,
}: SelectPaymentMethodProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const disablePayWithNewCard = cards.length >= 3;

  return (

    <div className={styles.container}>

      <div className={classnames(styles.paymentAndReferenceWrapper, `text__body__${mobile ? 'medium' : 'small'}__textNeutral40`)}>
        <div>
          <span className="bold">{`${t(`${translPrefix}.payment`)}:  `}</span>
          <span>{`${makePaymentInformation.currency}$${makePaymentInformation.amount}`}</span>
        </div>
        <div className={styles.pipe} />
        <div>
          <span className="bold">{`${t(`${translPrefix}.reference`)}:  `}</span>
          <span>{makePaymentInformation.reference}</span>
        </div>
      </div>

      {mobile && (
      <div className={classnames('text__heading2__textNeutral40')}>
        {t(`${translPrefix}.payTitle`)}
      </div>
      )}

      {cards.length ? (cards.map((card) => (
        <div className={styles.cardContainer}>
          <PaymentMethodsList
            checked={isChecked(card, selectedCard)}
            t={t}
            onSelect={onCardSelect}
            onDelete={() => deletePaymentMethod(card.id)}
            card={card}
          />
        </div>
      ))) : (
        <div className={`text__body__${mobile ? 'medium' : 'small'}__textNeutral30`}>
          {t(`${translPrefix}.noCards`)}
        </div>
      )}

      <button
        type="button"
        className={styles.addNewCardContainer}
        onClick={handleAddCard}
        disabled={disablePayWithNewCard}
      >
        <div
          className={`text__button__small__${disablePayWithNewCard ? 'textNeutral20' : 'textNeutral40'}`}
        >
          {t(`${translPrefix}.payWithNewCard`)}
        </div>

        <div className={disablePayWithNewCard
          ? styles.plusContainerDisabled : styles.plusContainer}
        >
          <Plus className={disablePayWithNewCard ? styles.plusDisabled : styles.plus} />
        </div>
      </button>

      <form noValidate onSubmit={onPay}>
        <div className={classnames(styles.inputContainer, styles.storedCard)} />

        <BottomButtonsMakePayment
          onCancel={onCancel}
          t={t}
          disabledButtonRight={!selectedCard}
          textButtonRight={t(`${translPrefix}.pay`)}
          mobile={mobile}
          translPrefix={translPrefix}
        />

      </form>

    </div>
  );
};

export { SelectPaymentMethod };
