import { TaskStatus } from '@mapix/common/src/common/enums';
import { Pill, PillColor } from '@mapix/common/src/common/pill';
import { Tab } from '@mapix/common/src/common/tab';
import { classnames, resolveAddress } from '@mapix/common/src/helpers/utils';
import { NewTask } from 'networking/types';
import { useTranslation } from 'react-i18next';
import styles from '../new-task-detail.module.scss';

type HeaderProps = {
  setCurrentTab: (option: string) => void,
  currentTab: string,
  newTask: NewTask,
  translPrefix: string,
  taskStatus: TaskStatus | '',
  showAlert: (option: string) => boolean,
};

const getPillColor = (taskStatus: TaskStatus | ''): PillColor => {
  switch (taskStatus) {
    case TaskStatus.NEGOTIATING:
      return PillColor.Purple;
    case TaskStatus.UNASSIGNED:
    case TaskStatus.UNPUBLISHED:
      return PillColor.Gray;
    case TaskStatus.IN_PROGRESS:
      return PillColor.Blue;
    default:
      return PillColor.White;
  }
};

export enum TaskDetailTabs {
  Status = 'status',
  Rate = 'rate',
  TaskView = 'taskView',
  Applications = 'applications',
}

const getTabs = (taskStatus: TaskStatus | ''): string[] => {
  switch (taskStatus) {
    case TaskStatus.NEGOTIATING:
      return [TaskDetailTabs.Status, TaskDetailTabs.Rate,
        TaskDetailTabs.TaskView, TaskDetailTabs.Applications];
    case TaskStatus.UNASSIGNED:
    case TaskStatus.UNPUBLISHED:
      return [TaskDetailTabs.Status, TaskDetailTabs.TaskView];
    default:
      return [TaskDetailTabs.Status];
  }
};

const Header = ({
  currentTab, newTask, setCurrentTab, translPrefix, taskStatus, showAlert,
}: HeaderProps) => {
  const { t } = useTranslation();

  const clickTab = (option: TaskDetailTabs) => {
    setCurrentTab(option);
  };

  return (
    <>
      <div className={styles.titlePillWrapper}>
        <span className="text__heading4__textNeutral50">
          {`${newTask.category} - ${newTask.name}`}
        </span>
        <Pill
          content={t(`taskStatus.${taskStatus}`)}
          color={getPillColor(taskStatus)}
          textStyle={classnames(styles.pill, 'text__body__small__textNeutral10')}
        />

      </div>
      <span className="text__heading5__textNeutral50">
        {`${newTask.buildingName || t('condoHouse')} - ${
          resolveAddress(newTask.address)}${newTask.address.unitNumber
          ? ` - Unit ${newTask.address.unitNumber}` : ''}`}
      </span>
      <div className={styles.tabsWrapper}>
        {getTabs(taskStatus).map((option) => (
          <div key={option}>
            <Tab
              onClickFn={() => clickTab(option as TaskDetailTabs)}
              name={t(`${translPrefix}.tabs.${option}.tabName`)}
              isActive={currentTab === option}
              alert={showAlert(option)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export { Header };
