import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ModalRejection } from '@mapix/common/src/common/modal-rejection';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PrivateRequest } from 'networking/types/private-request';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { logger } from 'helpers/logger';
import { RouteName, goToPage } from 'routes';
import { StatusTab } from 'common/new-task-detail/tabs/status';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { RateTab } from '@mapix/common/src/common/new-task-detail-tabs/rate-tab';
import { parseTaskStatus } from 'helpers/utils';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { RawNewTaskStatus, RawPrivateRequestStatus, TaskStatus } from '@mapix/common/src/common/enums';
import { useTranslation } from 'react-i18next';
import styles from './private-request-detail.module.scss';

type ParamType = {
  id: string,
};

const translPrefix = 'newTaskDetail';

const initPrivateRequest: PrivateRequest = {
  id: 0,
  status: RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL,
  newTask: {
    id: 0,
    status: RawNewTaskStatus.IN_PROGRESS,
    address: {
      city: '',
      country: '',
      civicNumber: '',
      streetName: '',
      province: '',
      zipCode: '',
      unitNumber: '',
    },
    description: '',
    buildingName: '',
    category: '',
    createdAt: '',
    name: '',
    newTaskEvents: [],
    privateRequests: [],
    inProgressManagerValidation: false,
    inProgressContractorValidation: false,
    inProgressRejectionExpiration: '',
    priority: '',
    property: {
      id: 0,
      type: '',
      condition: '',
      address: {
        streetName: '',
        country: '',
        province: '',
        city: '',
        zipCode: '',
        cityId: 0,
        lat: 0,
        long: 0,
      },
      owner: {
        name: '',
        phoneNumber: '',
        email: '',
        countryCode: '',
      },
    },
    unit: {
      id: 0,
      unitNumber: '',
      status: '',
      lease: null,
      address: null,
    },
    taskType: 'private_task',
  },
  createdAt: '',
  contractor: {
    id: 0,
    name: '',
    lastName: '',
  },
  manager: {
    id: 0,
    name: '',
    lastName: '',
  },
  contractorMessage: '',
  managerMessage: '',
  estimatedEndDate: '',
  privateRequestEvents: [],
  startDate: '',
  taskMessages: [],
  typeOfCost: 'budget',
  budget: 0,
  amountOfHours: 0,
  reasonForRejection: '',
};

const PrivateRequestDetail = () => {
  const [fetching, setFetching] = useState(true);
  const [privateRequest, setPrivateRequest] = useState<PrivateRequest>(initPrivateRequest);
  const [currentTab, setCurrentTab] = useState<TaskDetailTabs>(TaskDetailTabs.Status);
  const [showModal, setShowModal] = useState(false);
  const [moveToInProgressSuccessfullModal, setMoveToInProgressSuccessfullModal] = useState(false);

  const { id } = useParams<ParamType>();

  const { t } = useTranslation();

  const getPrivateRequest = async () => {
    try {
      const actualTask = await MaintenanceController.getPrivateRequest(id);
      setPrivateRequest(actualTask);
      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const handleAcceptRateByManager = async () => {
    setFetching(true);
    try {
      const taskUpdated = await MaintenanceController.acceptRatePrivateRequest(Number(id));
      setPrivateRequest(taskUpdated);
      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const taskStatus = parseTaskStatus(privateRequest.newTask.status);

  const getTabContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <StatusTab
            className={styles.tabsContainer}
            privateRequest={privateRequest}
            taskStatus={taskStatus}
            changeContractorFn={() => setShowModal(true)}
          />
        );
      case TaskDetailTabs.Rate:
        return (
          <RateTab
            contractor={privateRequest.contractor}
            manager={privateRequest.manager}
            privateRequestEvents={privateRequest.privateRequestEvents}
            privateRequestId={privateRequest.id}
            rawStatus={privateRequest.status}
            typeOfCost={{ ...privateRequest }}
            contractorMessage={privateRequest.contractorMessage}
            managerMessage={privateRequest.managerMessage}
            modifyRate={MaintenanceController.modifyRatePrivateRequest}
            notifyAcceptRate={handleAcceptRateByManager}
            refreshData={getPrivateRequest}
            t={t}
          />
        );
      case TaskDetailTabs.TaskView:
        return (
          <TaskViewTab
            className={styles.tabsContainer}
            task={privateRequest.newTask}
            translPrefix={`${translPrefix}.tabs.taskView`}
            t={t}
          />
        );
      case TaskDetailTabs.Applications:
        return null;
      default:
        return null;
    }
  };

  const showAlert = (option: string) => option === TaskDetailTabs.Rate
  && (privateRequest.status === RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL
    || privateRequest.status === RawPrivateRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL);

  useEffect(() => {
    getPrivateRequest();
  }, []);

  const disableRightButton = [RawPrivateRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL,
    RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL].includes(privateRequest.status);
  const withTooltip = taskStatus === TaskStatus.NEGOTIATING;

  if (fetching) {
    return <Spinner />;
  }

  const getTextButtonLeft = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'cancelTask';
        break;
      case TaskStatus.UNASSIGNED:
        translEntry = 'changeAssignedContractor';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getTextButtonRight = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'markAsInProgress';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const toolTipText = () => {
    let translEntry = '';
    switch (privateRequest.status) {
      case RawPrivateRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
        translEntry = 'waitingForContractorApproval';
        break;
      case RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL:
        translEntry = 'waitingForManagerApproval';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getLeftButtonFn = () => {
    switch (taskStatus) {
      case TaskStatus.UNASSIGNED:
        return () => setShowModal(true);
      default:
        return () => {};
    }
  };

  const modalRejectionFn = async (reason: string) => {
    try {
      await MaintenanceController.rejectPrivateRequest(
        Number(id),
        { reasonForRejection: reason },
      );
      goToPage(RouteName.AssignContractorV2, { taskId: Number(id) });
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  if (showModal) {
    return (
      <ModalRejection
        close={() => setShowModal(false)}
        handleButtonLeft={() => setShowModal(false)}
        onSubmit={modalRejectionFn}
        translPrefix={`${translPrefix}.changeContractorModal`}
      />
    );
  }

  const handleMarAskInProgress = async () => {
    const { id: taskId } = privateRequest.newTask;

    try {
      await MaintenanceController.moveTaskToInProgress(+taskId);
      setMoveToInProgressSuccessfullModal(true);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  const redirectToTaskDetail = () => {
    const { id: taskId } = privateRequest.newTask;
    setMoveToInProgressSuccessfullModal(false);

    goToPage(RouteName.TaskDetailV2, { id: taskId });
  };

  const moveToInProgressSuccessfullModalComponent = () => (
    <ModalResult
      title={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.title`)}
      subtitle={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.subtitle`)}
      Icon={Tool}
      withCheckIcon
      buttonTextRight={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.modalButton`)}
      handleButtonRight={redirectToTaskDetail}
      handleButtonClose={redirectToTaskDetail}
    />
  );

  return (
    <>
      {moveToInProgressSuccessfullModal && moveToInProgressSuccessfullModalComponent()}

      <NewTaskDetail
        currentTab={currentTab}
        setCurrentTab={setCurrentTab as (tab: string) => void}
        newTask={privateRequest.newTask}
        getTabContent={getTabContent}
        showAlert={showAlert}
        disableRightButton={disableRightButton}
        withTooltip={withTooltip}
        textButtonLeft={getTextButtonLeft()}
        textButtonRight={getTextButtonRight()}
        translPrefix={translPrefix}
        toolTipText={toolTipText()}
        leftButtonFn={getLeftButtonFn()}
        rightButtonFn={handleMarAskInProgress}
      />
    </>
  );
};

export { PrivateRequestDetail };
