const loadScript = (src: string) => new Promise<void>((resolve, reject) => {
  const scriptElem = Object.assign(document.createElement('script'), {
    type: 'text/javascript',
    defer: true,
    src,
    onerror: (e: Error) => {
      reject(e);
    },
  });
  scriptElem.onload = () => {
    resolve();
  };
  scriptElem.id = 'google-login';
  document.body.appendChild(scriptElem);
});

type GoogleLoginProps = {
  googleClientId: string,
  domain: string,
  signUp?: boolean
};

const GoogleLogin = ({ domain, googleClientId, signUp = false }: GoogleLoginProps) => {
  loadScript('https://accounts.google.com/gsi/client');

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={googleClientId}
        data-login_uri={domain}
        data-auto_prompt="false"
      />
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text={signUp ? 'signup_with' : 'sign_in_with'}
        data-shape="rectangular"
        data-logo_alignment="left"
      />
    </>

  );
};

export { GoogleLogin };
