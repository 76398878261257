import React from 'react';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { InputDate } from '@mapix/common/src/common/input-date';

import { Tooltip } from '@mapix/common/src/common/tooltip';
import { ReactComponent as Info } from 'assets/icons/info.svg';

import styles from './renew-lease.module.scss';

const leaseTranslPrefix = 'units.lease';

type DateFormProps = {
  formErrors: HTMLValidationError,
  onChange: (date: string, type: string) => void,
  startDate: string,
  expirationDate: string,
  endDate: string,
  isCondo: boolean,
};

const DateForm = ({
  formErrors, onChange, startDate, expirationDate, endDate, isCondo,
}: DateFormProps) => {
  const { t } = useTranslation();

  const translPrefix = 'renewLease';

  return (
    <div className={styles.datesWrapper}>
      <InputDate
        containerClass={styles.input}
        required
        id="startDate"
        name="startDate"
        label={t(`${translPrefix}.startDate`)}
        placeholder={t(`${translPrefix}.startDate`)}
        value={startDate !== '' ? dayjs(startDate).toDate() : undefined}
        formErrors={formErrors}
        onChangeFn={(date: Date) => onChange(dayjs(date).format('YYYY-MM-DD'), 'startDate')}
        helperText="DD/MM/YYYY"
        min={endDate ? dayjs(endDate).toDate() : undefined}
        t={t}
      />

      <InputDate
        containerClass={styles.input}
        required
        id="expirationDate"
        name="expirationDate"
        label={t(`${translPrefix}.expirationDate`)}
        placeholder={t(`${translPrefix}.expirationDate`)}
        value={expirationDate !== '' ? dayjs(expirationDate).toDate() : undefined}
        formErrors={formErrors}
        onChangeFn={(date: Date) => onChange(dayjs(date).format('YYYY-MM-DD'), 'expirationDate')}
        helperText="DD/MM/YYYY"
        min={startDate ? dayjs(startDate).toDate() : undefined}
        disabled={startDate === ''}
        t={t}
      />

      <Tooltip
        textComponent={<Info className={styles.infoIcon} />}
        hoverComponent={(
          <div className="text__body__small__surface10">
            {t(`${leaseTranslPrefix}.tooltipDueDate${isCondo ? 'Condo' : ''}`)}
          </div>
                )}
        containerWidth="483px"
      />

    </div>
  );
};

export { DateForm };
