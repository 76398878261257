import { classnames, getDisplayFileSize, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputStyle } from '@mapix/common/src/common/input';
import { RadioButton } from 'common/radio-button';
import { BuildingArea, ContractorCategories, Priority } from 'common/enums';
import { Select } from '@mapix/common/src/common/select';
import { FileUpload } from 'common/image-upload';
import { TextArea } from '@mapix/common/src/common/textarea';
import dayjs from 'dayjs';
import { InputDate } from '@mapix/common/src/common/input-date';
import { UnitNumberInput } from 'common/unit-number-input';
import { SimpleUnit } from 'models/simple-unit';
import { constants } from 'config/constants';

import styles from './create-edit-task-container-step-2.module.scss';

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

type Action =
  | { type: 'CHANGE_FIELD', value: string | number, field: string }
  | { type: 'TASK_PHOTO', taskPhoto: FileType[] }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string };

type CreateEditTaskContainerStep2Props = {
  fn: (action: Action) => void,
  propertyAddress: string,
  buildingType: boolean | null,
  taskCategory: string,
  priority: string,
  issueDate: string,
  description: string,
  area: string,
  formErrors: HTMLValidationError,
  taskPhoto: FileType[],
  propertyId: number,
  unitNumber: string,
};

const createTaskArea = 'createTask.concernedArea';

const CreateEditTaskContainerStep2 = ({
  fn, propertyAddress, buildingType, taskCategory, unitNumber,
  priority, issueDate, area, description, formErrors, taskPhoto,
  propertyId,
}: CreateEditTaskContainerStep2Props) => {
  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    option: string,
  ) => {
    fn({
      type: 'CHANGE_FIELD', field: option, value: event.target.value,
    });
  };

  const onClickSelect = (option: string, objField: string) => {
    fn({
      type: 'CHANGE_FIELD', field: objField, value: option,
    });
  };

  const onChangeDateFn = (date: string, option: string) => {
    fn({
      type: 'CHANGE_FIELD', field: option, value: date,
    });
  };

  const onChangeRadioButton = (item: string) => {
    onClickSelect(item, 'area');
    if (item === 'common') {
      fn({
        type: 'UNIT_SELECTED', unitNumber: '', specificUnit: null,
      });
    }
  };

  const getUnitSelected = (unit: SimpleUnit) => {
    fn({
      type: 'UNIT_SELECTED', unitNumber: unit.unitNumber, specificUnit: unit.id,
    });
  };

  return (
    <div>
      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {`${buildingType ? t('createTask.type.building') : t('createTask.type.condoHouse')} ${propertyAddress}`}
      </div>
      {buildingType && (
        <>
          <div className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>{t('createTask.area')}</div>
          <div className={styles.checkboxContainer}>
            <form className={styles.form}>
              <div className={styles.area}>
                {Object.keys(BuildingArea).map((item) => (
                  <RadioButton
                    key={item}
                    max={1}
                    onChangeFn={() => onChangeRadioButton(item)}
                    maxLength={1}
                    containerClass={styles.checkbox}
                    labelClass="text__body__medium__textNeutral40"
                    labelContent={t(`${createTaskArea}.${(item)}`)}
                    name="area"
                    checked={item === area}
                  />
                ))}
              </div>
            </form>
            <UnitNumberInput
              propertyId={propertyId}
              disabled={area !== 'specific'}
              prefix="createTask.unit"
              onSelectUnitFn={getUnitSelected}
              query={unitNumber}
              required={buildingType && area === 'specific'}
              formError={formErrors}

            />
          </div>
        </>
      )}
      <div className={classnames('text__heading6__textNeutral50', styles.firstMargin)}>{t('createTask.category.title')}</div>
      <Select
        options={Object.values(ContractorCategories)}
        onClickOption={(option) => onClickSelect(option, 'taskCategory')}
        optionTextClass={classnames(styles.options, 'text__body__tiny__textNeutral50')}
        required
        id="category"
        label={t('createTask.category.type')}
        value={taskCategory ? `${t(`maintenanceDashboard.filters.category.${taskCategory}`)}` : ''}
        placeholder={t('createTask.category.type')}
        inputStyle={InputStyle.FORM}
        containerClass={classnames(styles.input, styles.large)}
        formError={formErrors}
        translationPrefix="maintenanceDashboard.filters.category"
        t={t}
      />
      <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.issue.title')}</div>
      <InputDate
        containerClass={classnames(styles.input, styles.large)}
        required
        id="issueDate"
        name="issueDate"
        label={t('createTask.issue.placeholder')}
        placeholder={t('createTask.issue.placeholder')}
        value={issueDate !== '' ? dayjs(issueDate).toDate() : undefined}
        formErrors={formErrors}
        onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'issueDate')}
        helperText="DD/MM/YYYY"
        t={t}
      />
      <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.description.title')}</div>
      <TextArea
        required
        id="description"
        placeholder={description === '' ? t('createTask.description.text') : undefined}
        value={description}
        maxLength={500}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 'description')}
        containerClass={classnames(styles.input, styles.description)}
        formError={formErrors}
        t={t}
      />
      <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.priority.title')}</div>
      <Select
        options={Object.values(Priority)}
        onClickOption={(option) => onClickSelect(option, 'priority')}
        optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
        required
        id="priority"
        value={priority ? `${t(`createTask.priority.options.${priority}`)}` : ''}
        label={t('createTask.priority.select')}
        placeholder={t('createTask.priority.select')}
        inputStyle={InputStyle.FORM}
        containerClass={classnames(styles.input, styles.large)}
        formError={formErrors}
        translationPrefix="maintenanceDashboard.filters.priority"
        t={t}
      />
      <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.photos.title')}</div>
      <FileUpload
        fetchedFiles={taskPhoto}
        fileAddedFn={(files: FileType[]) => {
          fn({
            type: 'TASK_PHOTO', taskPhoto: files,
          });
        }}
        title={t('createTask.photos.description')}
        restrictionText={t('createTask.photos.size', { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}
      />
    </div>
  );
};

export { CreateEditTaskContainerStep2 };
