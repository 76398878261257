/* A simplified version of the Property model that's easier to access */
class
MaintenanceTableItem {
  propertyType: string;

  propertyName: string;

  address: string;

  buildingName: string;

  affectedArea: string;

  taskCategory: string;

  taskName: string;

  taskType: string;

  taskId: number;

  activeRequestAssignedId: number;

  creationDate: string;

  startDate: string;

  priority: string;

  assigned: string;

  endDate: string;

  status: string;

  billedAmount: number | null;

  constructor(params: SerializedTask) {
    this.propertyType = params.property.type;
    this.propertyName = params.property.type;
    this.address = `${params.property.address.civicNumber} ${params.property.address.streetName} \
    ${params.property.address.unitNumber ? params.property.address.unitNumber : ''}, \
    ${params.property.address.city}, ${params.property.address.country} `;
    this.buildingName = params.property.buildingName;
    this.affectedArea = params.unit ? `Unit ${params.unit.unitNumber}` : '-';
    this.taskCategory = params.category;
    this.taskName = params.name;
    this.taskType = params.taskType ? params.taskType : '-';
    this.taskId = params.id;
    this.activeRequestAssignedId = params.activeRequestAssignedId;
    this.startDate = params.startDate;
    this.creationDate = params.createdAt;
    this.priority = params.priority;
    this.assigned = params.contractor ? `${params.contractor.name} ${params.contractor.lastName}` : '-';
    this.endDate = params.endDate;
    this.status = params.status;
    this.billedAmount = params.bill ? params.bill.billedAmount : null;
  }
}

export { MaintenanceTableItem };
