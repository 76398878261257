import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  classnames, HTMLValidationError, checkHTMLErrors,
} from '@mapix/common/src/helpers/utils';
import { camelCaseToStringWithSpaces } from 'helpers/utils';
import { useHistory, useParams } from 'react-router-dom';
import { logger } from 'helpers/logger';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { goToPage, RouteName } from 'routes';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { LeaseController } from 'networking/controllers/lease-controller';
import { ModalResult } from 'common/modal-result';
import { Spinner } from '@mapix/common/src/common/spinner';
import { LeaseDetail } from 'models/lease-detail';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import { Services } from 'models/services';
import { InputDate } from '@mapix/common/src/common/input-date';
import { PropertyType, UnitSizes } from 'common/enums';
import { Select } from '@mapix/common/src/common/select';
import { RentalForm } from './rental-form';
import { FilesUpload } from './files-upload';
import styles from './renew-lease.module.scss';
import { DateForm } from './date-form';

const RenewLease = () => {
  const [lease, setLease] = useState<LeaseDetail>(new LeaseDetail(null));
  const [successModal, setSuccessModal] = useState(false);
  const [currentLeaseStartDate, setCurrentLeaseStartDate] = useState('');
  const [currentLeaseEndDate, setCurrentLeaseEndDate] = useState('');
  const [code, setCode] = useState('FETCHING');
  const [errorServer, setErrorServer] = useState(false);
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const translPrefix = 'renewLease';
  const serviceTranslPrefix = 'services';

  const serviceSelected = (key: keyof Services) => {
    const modifiedService = { ...lease.services };
    (modifiedService as any)[key] = !modifiedService[key];

    setLease({ ...lease, services: modifiedService });
  };

  const onChangeOtherServices = (text: string) => {
    const modifiedService = { ...lease.services };
    modifiedService.other = text;
    setLease({ ...lease, services: modifiedService });
  };

  const onChangeRentalForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name !== 'yearlyRental') {
      setLease((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onChangeDateForm = (date: string, type: string) => {
    setLease((prevState) => ({ ...prevState, [type]: date }));
  };

  const onClickSelect = (option: string, objField: string) => {
    setLease((prevState) => ({ ...prevState, [objField]: option }));
  };

  const updateFiles = (field: string, files: FileType[]) => {
    if (field === 'pdf') {
      setLease((prevState) => ({ ...prevState, leaseCopy: files[0] }));
    } else {
      setLease((prevState) => ({ ...prevState, [field]: files }));
    }
  };

  const renewLease = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      try {
        setCode('FETCHING');
        await LeaseController.renewLease(lease);
        setCode('READY');
        setSuccessModal(true);
      } catch (err: any) {
        logger.error(err as Error);
        setErrorServer(true);
        setCode('ERROR');
      }
    }
  };

  const getDetailedLease = async () => {
    setCode('FETCHING');

    try {
      let fetchedLease = await LeaseController.getDetailedLease(Number(id));
      if (fetchedLease !== undefined) {
        setCurrentLeaseStartDate(fetchedLease.startDate);
        if (fetchedLease.status === 'ended' && fetchedLease.endDate) {
          setCurrentLeaseEndDate(fetchedLease.endDate);
        }
        fetchedLease = {
          ...fetchedLease, startDate: '', expirationDate: '', endDate: '',
        };
        setLease(fetchedLease);
      }
      setCode('READY');
    } catch (err: any) {
      logger.error(err as Error);
      setErrorServer(true);
      setCode('ERROR');
    }
  };

  const nextDate = (day: string) => {
    const newDate = dayjs(day).toDate();
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  };

  useEffect(() => {
    getDetailedLease();
  }, []);

  if (successModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.successModal.title`)}
        subtitle={t(`${translPrefix}.successModal.subtitle`)}
        Icon={File}
        buttonTextRight={t(`${translPrefix}.successModal.ok`)}
        handleButtonClose={() => { goToPage(RouteName.Leases); }}
        handleButtonRight={() => { goToPage(RouteName.Leases); }}
        withCheckIcon
      />
    );
  }

  if (code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}

      <form onSubmit={renewLease} noValidate>

        <div className={styles.header}>
          <div className={classnames(styles.alertIcon, 'text__heading4__textNeutral50')}>{t(`${translPrefix}.title`)}</div>
          <ButtonClose closeFn={() => { history.goBack(); }} closeText={t(`${translPrefix}.buttons.close`)} />
        </div>

        <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
          {`${lease.type === 'Building' ? t('createTask.type.building') : t('createTask.type.condoHouse')} ${lease.address}`}
        </div>
        <div className="text__body__medium__textNeutral40">{t(`${translPrefix}.advice`)}</div>
        <div className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>
          {t(`${translPrefix}.subtitle`)}
        </div>

        { (lease.status !== 'ended') && (
        <div className={styles.endDate}>
          <InputDate
            containerClass={classnames(styles.input)}
            required
            id="endDate"
            name="endDate"
            label={t(`${translPrefix}.endDate`)}
            placeholder={t(`${translPrefix}.endDate`)}
            value={lease.endDate ? dayjs(lease.endDate).toDate() : undefined}
            formErrors={formErrors}
            onChangeFn={(date: Date) => onChangeDateForm(dayjs(date).format('YYYY-MM-DD'), 'endDate')}
            helperText="DD/MM/YYYY"
            min={nextDate(currentLeaseStartDate)}
            t={t}
          />
        </div>
        )}

        <RentalForm
          formErrors={formErrors}
          onChange={onChangeRentalForm}
          monthlyRental={lease.monthlyRental}
          yearlyRental={lease.monthlyRental * 12}
          currency={lease.currency}
          onClickSelect={onClickSelect}
          isCondo={lease.type === PropertyType.CondoHouse}
          condoFees={lease.condoFees}
        />

        <div className={classnames(styles.services, 'text__body__medium__textNeutral40')}>{t(`${translPrefix}.servicesTitle`)}</div>
        <div className={styles.checkboxContainer}>
          {Object.keys(lease.services).filter((item) => item !== 'other').map((service) => (
            <Checkbox
              key={service}
              onChangeFn={() => serviceSelected(service as keyof Services)}
              checked={!!lease.services[service as keyof Services]}
              containerClass={styles.checkbox}
              labelClass="text__body__medium__textNeutral40"
              labelContent={t(`${serviceTranslPrefix}.${camelCaseToStringWithSpaces(service)}`)}
            />
          ))}
          <div className={styles.row}>
            <Checkbox
              onChangeFn={() => serviceSelected('other' as keyof Services)}
              checked={!!lease.services.other}
              containerClass={styles.otherOption}
              labelClass="text__body__medium__textNeutral40"
              labelContent={t(`${serviceTranslPrefix}.other`)}
            />
            {lease.services.other && (
            <Input
              required
              id="other"
              helperText={t(`${serviceTranslPrefix}.otherText`)}
              value={lease.services.other}
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOtherServices(e.target.value)}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              t={t}
            />
            )}
          </div>
        </div>

        <DateForm
          formErrors={formErrors}
          onChange={onChangeDateForm}
          expirationDate={lease.expirationDate}
          startDate={lease.startDate}
          endDate={currentLeaseEndDate || ''}
          isCondo={lease.type === PropertyType.CondoHouse}
        />

        <Select
          options={Object.values(UnitSizes)}
          onClickOption={(option) => setLease({ ...lease, size: option })}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="size"
          label={t(`${translPrefix}.unitSize`)}
          placeholder={t(`${translPrefix}.unitSize`)}
          value={lease.size}
          containerClass={classnames(styles.input, styles.select)}
          formError={formErrors}
          disabled={lease.status === 'ongoing'}
          t={t}
        />

        <FilesUpload
          unitPhotos={lease.unitPhotos}
          tenantIdPhotos={lease.tenantIdPhotos}
          leaseCopy={lease.leaseCopy}
          fileAddedFn={updateFiles}
        />

        <div className={styles.footer}>
          <Button
            buttonType={ButtonType.Submit}
            buttonStyle={ButtonStyle.Primary}
            className={classnames(styles.renewButton, 'text__button__medium__textNeutral50')}
          >
            {t(`${translPrefix}.buttons.renew`)}
          </Button>
        </div>
      </form>

    </div>
  );
};

export { RenewLease };
