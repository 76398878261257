import { City, PaginatorContainer, RawCity } from '@mapix/common/src/types';
import { PaginatorSerializer } from './paginator-serializer';

class ResourcesSerializer {
  static deSerializeFileType(data: RawFile): FileType {
    return {
      filename: data.filename,
      url: data.url,
    };
  }

  static serializeFileType(data: FileType): RawFile {
    return {
      filename: data.filename,
      url: data.url,
    };
  }

  static deSerializeCity: (data: RawCity) => City = (data) => ({
    id: data.id,
    name: data.name,
    province: data.province,
    provinceId: data.province_id,
    country: data.country,
  });

  static deSerializeCities = (data: RawCity[]): City[] => data
    .map(ResourcesSerializer.deSerializeCity);

  static deSerializePaginatedCities = (
    data: RawPaginatorContainer<RawCity>,
  ): PaginatorContainer<City> => ({
    navigation: PaginatorSerializer.deSerialize(data.navigation),
    results: ResourcesSerializer.deSerializeCities(data.results),
  });
}

export { ResourcesSerializer };
