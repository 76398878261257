// extracted from https://blog.webdevsimplified.com/2020-10/react-debounce/
import { useEffect, useCallback } from 'react';

function useDebounceEffect(effect: () => void, deps: Array<string | number>, delay = 500) {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

export { useDebounceEffect };
