// TODO:VIEW IN PROGRESS
/* eslint-disable @typescript-eslint/no-unused-vars */ // TODO: remove later
import { Task } from 'models/task';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTask } from 'networking/types';
import { useParams } from 'react-router-dom';
import { Spinner } from '@mapix/common/src/common/spinner';
import { logger } from 'helpers/logger';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import dayjs from 'dayjs';
import { ModalAccentColor, ModalResult } from '@mapix/common/src/common/modal-result';
import styles from './task-detailV2.module.scss';

type ParamType = {
  id: string,
};

const TaskDetailV2 = () => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [task, setTask] = useState<NewTask | null>(null);
  const [currentTab, setCurrentTab] = useState<string>(TaskDetailTabs.Status);
  const [showContractorMovedTaskModal, setShowContractorMovedTaskModal] = useState(false);
  const [showKeepAsNegotiatingModal, setShowKeepAsNegotiatingModal] = useState(false);

  const translPrefix = 'newTaskDetail';

  const { id } = useParams<ParamType>();

  const wasTaskMovedByContractor = (taskDetail: NewTask): boolean => {
    const {
      inProgressContractorValidation,
      inProgressManagerValidation,
    } = taskDetail;

    return (
      inProgressContractorValidation && !inProgressManagerValidation
    );
  };

  const isExpirationDateAfterCurrentDate = (taskDetail: NewTask): boolean => {
    const {
      inProgressRejectionExpiration,
    } = taskDetail;

    // TODO replace new Date() by server Date
    return dayjs(
      dayjs(inProgressRejectionExpiration).toDate(),
    ).isAfter(new Date());
  };

  const checkTaskStatus = (taskDetail: NewTask): boolean => (
    wasTaskMovedByContractor(taskDetail) && isExpirationDateAfterCurrentDate(taskDetail)
  );

  const getTask = async () => {
    try {
      setFetching(true);
      const taskResponse = await MaintenanceController.getNewTaskDetail(id);
      setTask(taskResponse);

      const hasContractorMovedTask = checkTaskStatus(taskResponse);

      if (hasContractorMovedTask) {
        setShowContractorMovedTaskModal(true);
      }
    } catch (err: any) {
      logger.error(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  const handleKeepTaskAsNegotiating = () => {
    setShowContractorMovedTaskModal(false);
    setShowKeepAsNegotiatingModal(true);
  };

  const handleConfirmInProgress = async () => {
    try {
      const taskResponse = await MaintenanceController.acceptTaskToInProgress(Number(id));
      setTask(taskResponse);
    } catch (err: any) {
      logger.error(err);
    } finally {
      setShowContractorMovedTaskModal(false);
    }
  };

  const getBodyContent = () => (
    <div> TODO </div>
  );

  const showViewContent = () => (
    task
      ? (
        <NewTaskDetail
          currentTab={currentTab as TaskDetailTabs}
          newTask={task}
          getTabContent={getBodyContent}
          setCurrentTab={setCurrentTab}
          translPrefix={translPrefix}
          textButtonRight={t(`${translPrefix}.footer.markAsSolved`)}
          textButtonLeft={t(`${translPrefix}.footer.cancelTask`)}
          leftButtonFn={() => { /* TODO */ }}
          rightButtonFn={() => { /* TODO */ }}
        />
      ) : null
  );

  const showContractorMovedTaskModalComponent = () => (
    <ModalResult
      title={t(`${translPrefix}.moveToInProgressByContractorSuccessfullModal.title`)}
      subtitle={t(`${translPrefix}.moveToInProgressByContractorSuccessfullModal.subtitle`)}
      Icon={Tool}
      cross={false}
      modalAccentColor={ModalAccentColor.GREEN}
      buttonLeftStyle={styles.movedtoInProgressModalButton}
      buttonRightStyle={styles.movedtoInProgressModalButton}
      buttonTextLeft={t(`${translPrefix}.moveToInProgressByContractorSuccessfullModal.buttonLeft`)}
      buttonTextRight={t(`${translPrefix}.moveToInProgressByContractorSuccessfullModal.buttonRight`)}
      handleButtonLeft={handleKeepTaskAsNegotiating}
      handleButtonRight={handleConfirmInProgress}
    />
  );

  // TODO
  const showKeepAsNegotiatingModalComponent = () => null;

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showContractorMovedTaskModal && showContractorMovedTaskModalComponent()}
      {showKeepAsNegotiatingModal && showKeepAsNegotiatingModalComponent()}

      {showViewContent()}
    </>
  );
};

export { TaskDetailV2 };
