import { ButtonType } from '@mapix/common/src/common/button';
import { Table } from 'common/table';
import {
  checkHTMLErrors, classnames, HTMLValidationError,
} from '@mapix/common/src/helpers/utils';
import { getTaskEventText } from 'helpers/utils';
import { Task } from 'models/task';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as UserX } from 'assets/icons/userX.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { CreateFooter } from 'common/creation';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ModalResult } from 'common/modal-result';
import { goToPage, RouteName } from 'routes';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Select } from '@mapix/common/src/common/select';
import { InputStyle } from '@mapix/common/src/common/input';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { BillsController } from 'networking/controllers/bills-controller';
import { BillATaskModal } from 'pages/task-detail/bill-a-task-modal';
import { Condition, PropertyType } from 'common/enums';
import { ImageOverlay } from '@mapix/common/src/common/image-overlay';
import { ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import styles from './task-detail.module.scss';
import { ModalRejectTask } from './modal-reject-task';
import { ModalSolvedTask } from './modal-solved-task';
import { SolvedTaskModalType } from './modal-solved-task/modal-solved-task';

type ParamType = {
  id: string,
};

const headerNewTask = ['affectedArea', 'category', 'startDate'];
const headerInProgressTask = ['affectedArea', 'category', 'startDate', 'priority', 'assignment'];
const headerSolvedTask = ['affectedArea', 'category', 'startDate', 'endDate', 'billedAmount'];
const headerRejectedTask = ['affectedArea', 'category', 'startDate', 'rejectionDate'];
const STATUS = {
  inProgress: 'In Progress',
  new: 'New',
  solved: 'Solved',
  rejected: 'Rejected',
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const TaskDetail = () => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const [showModalAcceptedTask, setShowModalAcceptedTask] = useState(false);
  const [showModalResolved, setShowModalResolved] = useState(false);
  const [showModalResolvedOk, setShowModalResolvedOk] = useState(false);
  const [showModalCancelTask, setShowModalCancelTask] = useState(false);
  const [showModalRejectedOk, setShowModalRejectedOk] = useState(false);
  const [showBillATask, setShowBillATask] = useState(false);
  const [billCreationFailed, setBillCreationFailed] = useState(false);
  const [showModalCancelAssignment, setShowModalCancelAssignment] = useState(false);
  const [showModalCancelAssignmentOk, setShowModalCancelAssignmentOk] = useState(false);
  const [showError, setShowError] = useState(false);
  const [priority, setPriority] = useState('');
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [data, setData] = useState<TaskView[]>([]);
  const [task, setTask] = useState<Task | null>(null);
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const { id } = useParams<ParamType>();

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const getTask = async () => {
    try {
      setFetching(true);
      const result = await MaintenanceController.getTaskDetail(id);
      setFetching(false);
      setTask(result);
      const dataAux: any[] = [
        {
          affectedArea: result.unit.unitNumber ? `${t('unit')} ${result.unit.unitNumber}` : t('taskDetail.commonArea'),
          category: result.category,
          startDate: result.startDate,
          priority: result.priority,
          assignment: result.contractor?.id !== undefined ? t('taskDetail.assigned') : t('taskDetail.notAssigned'),
          endDate: result.endDate,
          billedAmount: result.billedAmount,
          rejectionDate: result.rejectionDate,
          taskEvents: result.taskEvents,
        },
      ];
      setData(dataAux);
    } catch (err: any) {
      setFetching(false);
      if (err.status === 404) {
        goToPage(RouteName.ErrorPage);
      }
    }
  };

  useEffect(() => {
    if (!showBillATask || !showModalResolved) {
      getTask();
    }
  }, [showBillATask, showModalResolved]);

  if (fetching || task === null) {
    return (<Spinner />);
  }

  const solveTask = async (taskData: SolvedTaskModalType) => {
    try {
      const newTask: SolveATask = {
        id: task.id,
        endDate: taskData.endDate,
        additionalComments: taskData.additionalComments,
        status: 'Solved',
      };
      setFetching(true);
      const editedTask = await MaintenanceController.editTask(newTask);

      if (taskData.billedAmount && taskData.currency && taskData.dueDate
        && editedTask.property.id) {
        try {
          const billedTask: SerializedBillWithTask = {
            billedAmount: taskData.billedAmount,
            currency: taskData.currency,
            dueDate: taskData.dueDate,
            taskId: editedTask.id,
            unitId: editedTask.unit?.id ?? undefined,
          };
          await BillsController.addBilWithTask(billedTask, editedTask.property.id);
        } catch (err: any) {
          setBillCreationFailed(true);
        }
      }

      setShowModalResolved(false);
      setShowModalResolvedOk(true);
      getTask();
      setFetching(false);
    } catch (ex) {
      setFetching(false);
      setShowError(true);
    }
  };

  const rejectTask = async (reason: string) => {
    try {
      setFetching(true);
      const newTask: RejectTask = {
        id,
        reason,
      };
      await MaintenanceController.rejectTask(newTask);
      setShowModalReject(false);
      setShowModalRejectedOk(true);
      getTask();
      setFetching(false);
    } catch (ex) {
      setFetching(false);
      setShowError(true);
    }
  };

  const handleChange = (option: string) => {
    setPriority(option);
  };

  const acceptTask = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      const newTask: AcceptATask = {
        id: task.id,
        priority,
        status: STATUS.inProgress,
      };
      try {
        setFetching(true);
        await MaintenanceController.acceptTask(newTask);
        getTask();
        setFetching(false);
        setShowModalAcceptedTask(true);
      } catch (ex) {
        setFetching(false);
        setShowError(true);
      }
    }
  };

  const cancelTask = async () => {
    try {
      setFetching(true);
      await MaintenanceController.cancelTask(task.id);
      setFetching(false);
      setShowModalCancelTask(false);
      getTask();
    } catch (ex) {
      setFetching(false);
      setShowError(true);
    }
  };

  const getButtons = () => {
    if (task.status === STATUS.new && task.property.condition !== Condition.Archived) {
      return (
        <CreateFooter
          nextName={t('taskDetail.acceptTask')}
          nextButtonType={ButtonType.Submit}
          Icon={Check}
          iconStyle={styles.checkIcon}
          secondButtonName={t('taskDetail.rejectTask')}
          secondButtonFn={() => setShowModalReject(true)}
          SecondIconButton={Close}
          secondIconButtonStyle={classnames(styles.button, styles.closeIcon)}
          rejectButton
        />
      );
    }

    if (task.status === STATUS.inProgress && task.property.condition !== Condition.Archived) {
      return (
        <CreateFooter
          nextName={t('taskDetail.markAsResolved')}
          nextFn={() => setShowModalResolved(true)}
          Icon={Check}
          iconStyle={classnames(styles.button, styles.checkIcon)}
          secondButtonName={
            task.contractor && task.contractor.id !== undefined
              ? t('taskDetail.assignToAnotherContractor')
              : t('taskDetail.assignToAContractor')
          }
          secondButtonFn={() => goToPage(RouteName.AssignContractor, { taskId: id })}
        />
      );
    }

    if (task.status === STATUS.solved && !task.bill
      && task.property.condition !== Condition.Archived) {
      return (
        <CreateFooter
          nextName={t('taskDetail.billThisTask')}
          nextFn={() => setShowBillATask(true)}
          disableNextCondition={task.property.condition === Condition.Archived}
          Icon={Dollar}
          iconStyle={classnames(styles.button, styles.dollarIcon)}
        />
      );
    }
    return null;
  };

  const getTasksEvents = () => task.taskEvents.map(
    (element, index, list) => (
      <p key={element.id} className={classnames(styles.margin, index === list.length - 1 ? 'text__heading6__textNeutral40' : 'text__body__medium__textNeutral40')}>
        {getTaskEventText(t, element)}
      </p>
    ),
  );

  const cancelAssigment = async () => {
    setFetching(true);
    const result = await MaintenanceController.cancelAssignement(task.id);
    setTask(result);
    setFetching(false);
    setShowModalCancelAssignment(false);
    setShowModalCancelAssignmentOk(true);
  };

  const getHeader = () => {
    let header;
    switch (task.status) {
      case STATUS.new:
        header = headerNewTask;
        break;
      case STATUS.inProgress:
        header = headerInProgressTask;
        break;
      case STATUS.solved:
        header = headerSolvedTask;
        break;
      default:
        header = headerRejectedTask;
        break;
    }
    return task.property.type === PropertyType.Building ? header : header.slice(1);
  };

  const getIcon = () => {
    if (task.status === STATUS.new) {
      return (
        <p className={styles.iconNew}>{t('taskDetail.new')}</p>);
    }
    if (task.status === STATUS.inProgress) {
      return (
        <p className={styles.iconInProgress}>{t('taskDetail.inProgress')}</p>);
    }
    if (task.status === STATUS.solved) {
      return (
        <p className={styles.iconSolved}>{t('taskDetail.solved')}</p>);
    }
    return (
      <p className={styles.iconRejected}>{t('taskDetail.rejected')}</p>);
  };

  return (
    <div className={styles.container}>

      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}

      <div>
        {showBillATask && (
          <BillATaskModal
            taskId={task.id}
            propertyId={task.property.id}
            closeFn={() => setShowBillATask(false)}
            unitId={task.unit?.id ? task.unit.id : undefined}
          />
        )}
        {showError
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
        {showModalReject && (
          <ModalRejectTask
            close={() => setShowModalReject(false)}
            rejectTask={rejectTask}
          />
        )}
        {showModalResolved && (
          <ModalSolvedTask
            close={() => setShowModalResolved(false)}
            solveTask={solveTask}
            startDate={task.startDate}
          />
        )}
        {showModalAcceptedTask && (
          <ModalResult
            title={t('taskDetail.modalResult.title')}
            subtitle={t('taskDetail.modalResult.subtitle')}
            Icon={Tool}
            buttonTextLeft={t('taskDetail.modalResult.cancel')}
            buttonTextRight={t('taskDetail.modalResult.assignNow')}
            handleButtonClose={() => setShowModalAcceptedTask(false)}
            handleButtonRight={() => goToPage(RouteName.AssignContractor, { taskId: id })}
            handleButtonLeft={() => setShowModalAcceptedTask(false)}
            withCheckIcon
          />
        )}
        {showModalCancelTask && (
          <ModalResult
            title={t('taskDetail.modalCancel.title')}
            subtitle={t('taskDetail.modalCancel.subtitle')}
            Icon={Tool}
            buttonTextLeft={t('taskDetail.modalCancel.goBack')}
            buttonTextRight={t('taskDetail.modalCancel.cancel')}
            handleButtonClose={() => setShowModalCancelTask(false)}
            handleButtonLeft={() => setShowModalCancelTask(false)}
            handleButtonRight={cancelTask}
            iconStyle={styles.iconBlue}
          />
        )}
        {showModalResolvedOk && (
          <ModalResult
            title={t('taskDetail.modalResolvedOk.title')}
            subtitle={t(`taskDetail.modalResolvedOk.${billCreationFailed ? 'subtitleBill' : 'subtitle'}`)}
            Icon={Tool}
            buttonTextRight={t('taskDetail.modalResolvedOk.ok')}
            handleButtonClose={() => setShowModalResolvedOk(false)}
            handleButtonRight={() => setShowModalResolvedOk(false)}
            withCheckIcon
          />
        )}
        {showModalRejectedOk && (
          <ModalResult
            title={t('taskDetail.modalRejectedOk.title')}
            subtitle={t('taskDetail.modalRejectedOk.subtitle')}
            Icon={Tool}
            buttonTextRight={t('taskDetail.modalRejectedOk.ok')}
            handleButtonClose={() => setShowModalRejectedOk(false)}
            handleButtonRight={() => setShowModalRejectedOk(false)}
            withCheckIcon
          />
        )}
        {showModalCancelAssignment && (
          <ModalResult
            title={t('taskDetail.modalCancelAssignment.title')}
            subtitle={t('taskDetail.modalCancelAssignment.subtitle')}
            Icon={Tool}
            iconStyle={styles.cancelAssignemntModal}
            handleButtonClose={() => setShowModalCancelAssignment(false)}
            buttonTextRight={t('taskDetail.modalCancelAssignment.cancelAssigment')}
            handleButtonRight={() => cancelAssigment()}
            buttonTextLeft={t('taskDetail.modalCancelAssignment.goBack')}
            handleButtonLeft={() => setShowModalCancelAssignment(false)}
          />
        )}
        {showModalCancelAssignmentOk && (
          <ModalResult
            title={t('taskDetail.modalCancelAssignmentOk.title')}
            subtitle={t('taskDetail.modalCancelAssignmentOk.subtitle')}
            Icon={Tool}
            withCheckIcon
            handleButtonClose={() => setShowModalCancelAssignmentOk(false)}
            buttonTextRight={t('taskDetail.modalCancelAssignmentOk.ok')}
            handleButtonRight={() => setShowModalCancelAssignmentOk(false)}
          />
        )}
      </div>
      <div>
        <form onSubmit={acceptTask} noValidate>
          <div className={classnames(styles.row, styles.titleRow)}>
            <p className="text__heading4__textNeutral50">
              {t('taskDetail.taskId')}
              {' '}
              { task.id}
            </p>
            {getIcon()}
          </div>
          <div className={styles.row}>
            <div className={styles.leftText}>
              <p className="text__heading5__textNeutral50">
                { `${task.unit.unitNumber ? `App. ${task.unit.unitNumber} - ` : ''}${task.property.fullAddress}`}
              </p>
            </div>
            { task.status === STATUS.inProgress
          && task.property.condition !== Condition.Archived && (
          <div className={styles.buttonsTop}>
            { task.contractor?.id
            && (
            <button className={styles.buttonText} type="button" onClick={() => setShowModalCancelAssignment(true)}>
              <div className="row text__body__medium__primary40">
                {t('taskDetail.cancelAssignment')}
                <UserX className={styles.iconPrimary} />
              </div>
            </button>
            )}
            <button className={styles.buttonText} type="button" onClick={() => goToPage(RouteName.EditTask, { id }, { propertyId: task.property.id })}>
              <div className="row text__body__medium__primary40">
                {t('taskDetail.editTask')}
                <Edit className={styles.iconEdit} />
              </div>
            </button>
            <button className={styles.buttonText} type="button" onClick={() => setShowModalCancelTask(true)}>
              <div className="row text__body__medium__primary40">
                {t('taskDetail.cancelTask')}
                <Close className={styles.iconPrimary} />
              </div>
            </button>
          </div>
            )}
          </div>
          <div className={styles.table}>
            <Table
              headerNames={getHeader()}
              data={data}
              dataProperties={getHeader()}
              dashboardName="taskDetail.newDashboard"
              uniqueId="id"
            />
          </div>
          <div className={styles.card}>
            <p className="text__heading6__textNeutral50">
              {t('taskDetail.tracking')}
              :
              {' '}
            </p>
            <div>
              {getTasksEvents()}
            </div>
          </div>
          { (task.status === 'Solved' && task.additionalInformation) && (
            <div className={styles.cardDescription}>
              <p className="text__heading6__textNeutral50">
                {t('taskDetail.additionalComments')}
              </p>
              <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                { task.additionalInformation}
              </p>
            </div>
          )}
          <div className={styles.cardDescription}>
            <p className="text__heading6__textNeutral50">
              {t('taskDetail.description')}
            </p>
            <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
              { task.description}
            </p>
          </div>
          { task.status === STATUS.rejected && (
            <div className={styles.cardDescription}>
              <p className="text__heading6__textNeutral50">
                {t('taskDetail.reasonForRejection')}
              </p>
              <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                {
                 task.rejectionReason ? task.rejectionReason : t('taskDetail.cancelledByManager')
                }
              </p>
            </div>
          )}
          <div className={styles.card}>
            <p className="text__heading6__textNeutral50">
              {t('taskDetail.photos')}
            </p>
            <div className={styles.photos}>
              {task.photos.length > 0
                ? (
                  <div className={styles.photoRow}>
                    {task.photos.map((photo, index) => (
                      <img
                        role="presentation"
                        onClick={() => showOverlay(index, task?.photos)}
                        key={photo.url}
                        className={styles.img}
                        src={photo.url}
                        alt="TaskPhoto"
                      />
                    ))}
                  </div>
                )
                : (
                  <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                    {t('taskDetail.emptyPhotos')}
                  </p>
                )}
            </div>
          </div>
          { task.status === STATUS.new
        && (
        <div className={styles.card}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.please')}
          </p>
          <div className={styles.select}>
            <Select
              options={['Low', 'Medium', 'High']}
              onClickOption={(option) => handleChange(option)}
              required
              type="text"
              id="country"
              label={t('taskDetail.selectPriority')}
              placeholder={t('taskDetail.selectPriority')}
              inputStyle={InputStyle.FORM}
              value={priority ? `${t(`taskDetail.options.${priority}`)}` : ''}
              formError={formErrors}
              translationPrefix="taskDetail.options"
              t={t}
            />
          </div>
        </div>
        )}
          <div className={styles.buttons}>
            {getButtons()}
          </div>
        </form>
      </div>
    </div>
  );
};

export { TaskDetail };
