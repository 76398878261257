import { PropertySerializer } from 'networking/serializers/property-serializer';
import { UnitSerializer } from 'networking/serializers/unit-serializer';

class BillSerializer {
  static deSerialize(data: RawBill): SerializedBill {
    return {
      billedAmount: data.billed_amount,
      category: data.category,
      currency: data.currency,
      dueDate: data.due_date,
      id: data.id,
      paymentDate: data.payment_date,
      property: PropertySerializer.deSerializeSimpleProperty(data.property),
      unit: data.unit ? UnitSerializer.deSerialize(data.unit) : undefined,
      taskId: data.task_id || undefined,
      status: data.status,
      additionalComments: data.additional_comments,
    };
  }
}

export { BillSerializer };
