import { ButtonType } from '@mapix/common/src/common/button';
import { CreateFooter } from 'common/creation';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { Spinner } from '@mapix/common/src/common/spinner';
import { checkHTMLErrors, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { useParams } from 'react-router-dom';
import { Menu } from 'common/menu';
import styles from './create-edit-claim.module.scss';
import { CommonForm } from './commonForm';

type List = {
  name: string,
  route: RouteName,
  pathParams?: {},
};

const EditClaim = () => {
  const [claim, setClaim] = useState<SerializedCreateClaim>();
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const { t } = useTranslation();
  const { claimId } = useParams<{ claimId: string }>();

  const listPath: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
    { name: t('editClaim.title'), route: RouteName.EditClaim, pathParams: { claimId } },
  ];
  const getClaimDetail = async () => {
    try {
      const claimComing = await MaintenanceController.getClaim(claimId);
      const claimNew: SerializedCreateClaim = {
        category: claimComing.category,
        propertyId: claimComing.property.id,
        startDate: claimComing.startDate,
        description: claimComing.description,
        photos: claimComing.photos,
      };
      setClaim(claimNew);
    } catch {
      setErrorServer(true);
    }
  };

  useEffect(() => {
    getClaimDetail();
  }, []);

  if (fetching || !claim) {
    return (
      <Spinner />
    );
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      setFormErrors({});
      try {
        setFetching(true);
        const newClaim: SerializedEditClaim = {
          category: claim.category,
          startDate: claim.startDate,
          description: claim.description,
          photos: claim.photos,
        };
        await MaintenanceController.editClaim(claimId, newClaim);
        setFetching(false);
        setShowModal(true);
      } catch (ex) {
        setFetching(false);
        setErrorServer(true);
      }
    }
  };

  const handleClickModal = () => {
    setShowModal(false);
    goToPage(RouteName.Claims);
  };

  return (
    <>
      {showModal && (
      <ModalResult
        handleButtonRight={handleClickModal}
        title={t('editClaim.modal.title')}
        buttonTextRight={t('editProfile.modal.ok')}
        Icon={Tool}
        withCheckIcon
        handleButtonClose={handleClickModal}
      />
      )}
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <form onSubmit={onSubmit} noValidate>
        <div className={styles.container}>
          { mobile && (
          <Menu
            list={listPath}
            className={styles.menu}
          />
          ) }
          <CommonForm
            claim={claim}
            setClaim={setClaim}
            formErrors={formErrors}
            edit
          />
        </div>
        <div className={styles.footer}>
          {mobile
            ? (
              <CreateFooter
                nextButtonType={ButtonType.Submit}
                nextName={t('editClaim.button')}
                backFn={() => goToPage(RouteName.Claims)}
                showBack
                withBackIcon={false}
                backName={t('createClaim.cancel')}
                Icon={null}
              />
            )
            : (
              <CreateFooter
                nextButtonType={ButtonType.Submit}
                nextName={t('editClaim.button')}
                Icon={null}
              />
            )}
        </div>
      </form>
    </>
  );
};

export { EditClaim };
