import { classnames } from '../../helpers/utils';
import { AvatarOrInitials } from '../avatar-or-initials';
import styles from './simple-message.module.scss';

type MessageProps = {
  text: string,
  senderName: string,
  senderLastName: string,
  senderUrl?: string,
  containerClass?: string,
};

const SimpleMessage = ({
  text, senderName, senderLastName, senderUrl, containerClass = '',
}: MessageProps) => (
  <div className={classnames(styles.container, containerClass)}>
    <AvatarOrInitials
      firstName={senderName}
      lastName={senderLastName}
      className={styles.avatar}
      url={senderUrl}
    />
    <div className={classnames(styles.body, 'text__body__small__textNeutral40')}>
      {text}
    </div>
  </div>
);

export { SimpleMessage };
