/**
 * Utility function to concat classNames.
 *
 * Usage: classnames('css_class1', 'css_class1')
 *
 * Can be used with objects where the keys are css classes and the
 * values are booleans that decide if classes are active or not:
 *
 * Example: classnames('input', { 'input-error': has_errors })
 *
 * @param  {...any} args
 * @returns string
 */
import { SetStateAction } from 'react';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsYesterday from 'dayjs/plugin/isYesterday';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import IsToday from 'dayjs/plugin/isToday';
import { Address } from '../types';
import { InputStyle } from '../common/enums';
import { FileType } from '../types/file';

dayjs.extend(AdvancedFormat);
dayjs.extend(IsYesterday);
dayjs.extend(IsToday);

 type HTMLValidationError = {
   [n: string]: string
 };

type ClassnameObject = {
  [key: string]: string | boolean | number,
};

type Classname = ClassnameObject | string;

function classnames(...args: Classname[]): string {
  if (args.length === 1) {
    const [firstEntry] = args;
    if (firstEntry && typeof firstEntry === 'object') {
      /* firstEntry's keys whose value is truthy */
      const activeClasses = Object.entries(firstEntry)
        .filter(([, value]) => value).map(([key]) => key);
      return activeClasses.join(' ');
    }
    return firstEntry;
  }
  return args.filter((entry) => !!entry).map((value) => classnames(value)).join(' ');
}

const addOrRemoveFromArrayString = (list: string[], value: string) => {
  const index = list.findIndex((item) => item.toLowerCase() === value.toLowerCase());
  const newList = [...list];
  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(value);
  }
  return newList;
};

/* Returns HTML5 form errors in an object accessed by the element id */
const checkHTMLErrors = (target: HTMLFormElement): HTMLValidationError => {
  const errors: HTMLValidationError = {};
  const { elements } = target;
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i] as HTMLObjectElement;
    if (element.validationMessage && element.id) {
      errors[element.id] = element.validationMessage;
    }
  }
  return errors;
};

const addError = (
  errorMessage: string,
  propertyString: string,
  setState: (value: SetStateAction<HTMLValidationError>) => void,
) => setState(
  (prevState) => (
    { ...prevState, [propertyString]: errorMessage }),
);

const removeError = (
  propertyString: string,
  setState: (value: SetStateAction<HTMLValidationError>) => void,
) => setState(
  (prevState) => {
    const copy = { ...prevState };
    delete copy[propertyString];
    return copy;
  },
);

const addOrRemoveFromArrayObject = (list: any[], listItem: any, attribute: string) => {
  const index = list.findIndex((item) => item[attribute] === listItem[attribute]);
  const newList = [...list];
  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(listItem);
  }
  return newList;
};

// First check is for any string contianing from 8 to 70 valid ascii characters
const isPasswordValid = (password: string): boolean => (/^[ -~]{8,70}$/.test(password))
                                                    // Must contain an uppercase letter
                                                    && !!(password.match(/[A-Z]/))
                                                    // Must contain an lowercase letter
                                                    && !!(password.match(/[a-z]/))
                                                    // Must contain a number
                                                    && !!(password.match(/[\d]/));

const getAvatarText = (firstName: string, lastName: string) => (
  firstName[0] + lastName[0]).toUpperCase();

const hourOptions = () => {
  const hourArray: string[] = [];
  Array.from(Array(24).keys()).forEach((hourNumber: number) => {
    hourArray.push(`${hourNumber}:00`);
    hourArray.push(`${hourNumber}:30`);
  });
  return hourArray;
};

const emailRegex = /^\S+@\S+\.(\S){2,}$/;

const emailPattern = () => emailRegex.toString().slice(1, -1);

const postalCodeRegex = (): RegExp => /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/;

// Original regex: '^((?![\[;=*\]])[\x00-\x7E])+$'
// eslint-disable-next-line no-control-regex
const asciiWithoutSomeSpecialCharacters = (): RegExp => /^((?![[;=*\]])[\x00-\x7E])+$/;

const noNumbersInString = (str: string) => /^[\D]{0,}$/.test(str);

const isEmptyObject = (obj: Object) => !Object.keys(obj).length
|| Object.values(obj).every((x) => x === undefined || x === null);

const checkCreditCardType = (cardNumber: string) => {
  const visaRegEx = /^4[0-9]{12}(?:[0-9]{3})?/;
  const mastercardRegEx = /^5[1-5][0-9]{14}/;
  const amexpRegEx = /^3[47][0-9]{13}/;
  if (visaRegEx.test(cardNumber)) {
    return 'visa';
  } if (mastercardRegEx.test(cardNumber)) {
    return 'mastercard';
  } if (amexpRegEx.test(cardNumber)) {
    return 'amex';
  }
  return '';
};

const helperTextFn = (
  required: boolean,
  helperText: string,
  t: (text: string) => void,
  plainHelperText?: boolean,
  disabled?: boolean,
  inputStyle?: InputStyle,
) => {
  if (required || disabled || plainHelperText || inputStyle !== InputStyle.FORM) {
    return helperText;
  }
  if (helperText) {
    return `${t('optional')} - ${helperText}`;
  }
  return `${t('optional')}`;
};

const convertToBlob = async (dataURL: string) => (await fetch(dataURL)).blob();

const range = (totalNumbers: number, startingPoint?: number) => (
  Array.from({ length: totalNumbers }, (_, index) => index + 1 + (startingPoint || 0))
);

const mapFileToFileArray = (file: FileType | null) => (file?.url
  ? [{
    url: file?.url,
    filename: file?.filename,
  }]
  : []);

const groupByAttribute = <Type extends Record<K, any>, K extends keyof Type>(
  array: Type[], attribute: K) => array
    .reduce((group: any, object: Type) => {
      const category = object[attribute];
      const grouping = group;
      grouping[category as keyof any] = grouping[category] ?? [];
      grouping[category].push(object);
      return group;
    }, {});

const resolveDate = (date: string, locale: string, t: (text: string) => string, format: string) => {
  dayjs.locale(locale);
  // TODO use UTC once the function is done
  const datejs = dayjs(date);
  if (datejs.isYesterday()) {
    return t('dates.yesterday');
  }
  if (datejs.isToday()) {
    return t('dates.today');
  }
  return datejs.format(format).toLocaleUpperCase();
};

const resolveDateHour = (
  date: string,
  locale: string,
  t: (text: string) => string,
  format: string,
) => {
  dayjs.locale(locale);
  // TODO use UTC once the function is done
  const datejs = dayjs(date);
  if (datejs.isYesterday()) {
    return `${t('dates.yesterday')} - ${datejs.format('HH:mm')}`;
  }
  if (datejs.isToday()) {
    return `${t('dates.today')} - ${datejs.format('HH:mm')}`;
  }
  return datejs.format(format);
};

const parseServerError = (t: (text: string) => string, error: string | null) => (error
  ? t('error.genericValueError') : undefined);
const getDisplayFileSize = (size: number) => (size / (1024 * 1024)).toFixed(1);

const resolveAddress = (address: Address) => `${address.civicNumber} ${address.streetName} ${address.country} ${address.province} ${address.zipCode}`;

const getRandomInt = (min: number, max: number) => Math.floor(
  Math.random() * (max - min + 1),
) + min;

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export {
  classnames, addOrRemoveFromArrayString, checkHTMLErrors, helperTextFn,
  addOrRemoveFromArrayObject, isPasswordValid, emailPattern,
  hourOptions, getAvatarText, noNumbersInString, isEmptyObject,
  addError, removeError, convertToBlob, range, postalCodeRegex, mapFileToFileArray,
  asciiWithoutSomeSpecialCharacters, checkCreditCardType, groupByAttribute,
  resolveDate, resolveDateHour, parseServerError, getDisplayFileSize, resolveAddress,
  capitalizeFirstLetter, getRandomInt, emailRegex,
};

export type { HTMLValidationError };
