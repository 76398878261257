import {
  RawTypeOfCost, TypeOfCost, RawAddress, Address, NewTaskEventType, RawNewTaskEventType,
  RejectPrivateRequest, RawRejectPrivateRequest, ModifyRate, BasicUserFields,
  OldTypeOfCost, PrivateRequestEvent, RawBasicUserFields,
  RawOldTypeOfCost, RawPrivateRequestEvent, RawTaskMessage, TaskMessage,
} from '@mapix/common/src/types';
import { PrivateRequestId, RawModifyRate, RawPrivateRequestId } from 'networking/types/private-request';
import {
  PrivateRequest, RawNewTask, RawPrivateRequest, NewTask,
} from 'networking/types';
import { PropertySerializer } from './property-serializer';
import { UnitSerializer } from './unit-serializer';

class PrivateRequestSerializer {
  static serializeNewTask(data: CreateNewTaskData): RawCreateNewTaskData {
    return {
      category_id: data.category.id,
      property_id: data.propertyId as number,
      description: data.description,
      name: data.name,
      other_category: data.otherCategory || null,
      priority: data.priority,
      task_photos: data.taskPhotos,
      task_type: data.taskType,
      unit_id: data.unitId || null,
      contractor_id: data.contractorId || null,
    };
  }

  static deSerializeBasicUserFields = (data: RawBasicUserFields): BasicUserFields => ({
    id: data.id,
    name: data.name,
    lastName: data.last_name,
  });

  static deSerializeAddress(data: RawAddress): Address {
    return {
      civicNumber: data.civic_number,
      streetName: data.street_name,
      country: data.country,
      province: data.province,
      city: data.city,
      zipCode: data.zip_code,
      unitNumber: data.unit_number || undefined,
      provinceId: data.province_id || undefined,
    };
  }

  static deSerializeNewTaskEvent = (data: RawNewTaskEventType): NewTaskEventType => ({
    id: data.id,
    createdAt: data.created_at,
    eventType: data.event_type,
  });

  static deSerializeNewTaskEvents = (data: RawNewTaskEventType[]): NewTaskEventType[] => data.map(
    (event) => this.deSerializeNewTaskEvent(event),
  );

  static deSerializePrivateRequestId = (data: RawPrivateRequestId): PrivateRequestId => ({
    id: data.id,
  });

  static deSerializeNewTask(data: RawNewTask): NewTask {
    return {
      id: data.id,
      name: data.name,
      category: data.category,
      priority: data.priority,
      description: data.description,
      status: data.status,
      buildingName: data.building_name,
      address: this.deSerializeAddress(data.address),
      taskPhotos: data.task_photos || undefined,
      createdAt: data.created_at,
      privateRequests: data.private_requests && data.private_requests.map(
        (pr: RawPrivateRequestId) => this.deSerializePrivateRequestId(pr),
      ),
      inProgressManagerValidation: data.in_progress_manager_validation,
      inProgressContractorValidation: data.in_progress_contractor_validation,
      inProgressRejectionExpiration: data.in_progress_rejection_expiration,
      newTaskEvents: this.deSerializeNewTaskEvents(data.new_task_events),
      property: PropertySerializer.deSerialize(data.property),
      unit: data.unit ? UnitSerializer.deSerialize(data.unit) : null,
      taskType: data.task_type,
    };
  }

  static serializeTypeOfCost = (data: TypeOfCost): RawTypeOfCost => {
    switch (data.typeOfCost) {
      case 'budget':
        return {
          type_of_cost: 'budget',
          budget: data.budget,
          amount_of_hours: data.amountOfHours || null,
        };
      case 'hourlyRate':
        return {
          type_of_cost: 'hourly_rate',
          hourly_rate: data.hourlyRate,
          amount_of_hours: data.amountOfHours,
        };
      default:
        throw new Error('Invalid type of cost');
    }
  };

  static deSerializeTypeOfCost = (data: RawTypeOfCost): TypeOfCost => {
    switch (data.type_of_cost) {
      case 'budget':
        return {
          typeOfCost: 'budget',
          budget: data.budget,
          amountOfHours: data.amount_of_hours || undefined,
        };
      case 'hourly_rate':
        return {
          typeOfCost: 'hourlyRate',
          hourlyRate: data.hourly_rate,
          amountOfHours: data.amount_of_hours,
        };
      case null:
        return {
          budget: null,
          typeOfCost: null,
          hourlyRate: null,
          amountOfHours: null,
        };
      default:
        throw new Error('Invalid type of cost');
    }
  };

  static deSerializeOldTypeOfCost = (data: RawOldTypeOfCost): OldTypeOfCost => {
    switch (data.old_type_of_cost) {
      case 'budget':
        return {
          oldTypeOfCost: 'budget',
          oldBudget: data.old_budget,
          oldAmountOfHours: data.old_amount_of_hours || undefined,
        };
      case 'hourly_rate':
        return {
          oldTypeOfCost: 'hourlyRate',
          oldHourlyRate: data.old_hourly_rate,
          oldAmountOfHours: data.old_amount_of_hours,
        };
      default:
        throw new Error('Invalid old type of cost');
    }
  };

  static deSerializePrivateRequestEvent = (data: RawPrivateRequestEvent): PrivateRequestEvent => ({
    id: data.id,
    createdAt: data.created_at,
    eventType: data.event_type,
    messageToContractor: data.message_to_contractor || undefined,
    messageToManager: data.message_to_manager || undefined,
    ...this.deSerializeOldTypeOfCost(data),
  });

  static deSerializeMessage = (data: RawTaskMessage): TaskMessage => ({
    id: data.id,
    text: data.text,
    createdAt: data.created_at,
    sender: data.sender,
  });

  static deSerializeMessages = (data: RawTaskMessage[]): TaskMessage[] => data.map(
    (message) => this.deSerializeMessage(message),
  );

  static deSerializePrivateRequest(data: RawPrivateRequest): PrivateRequest {
    return {
      id: data.id,
      status: data.status,
      createdAt: data.created_at,
      reasonForRejection: data.reason_for_rejection || undefined,
      estimatedEndDate: data.estimated_end_date,
      startDate: data.start_date,
      managerMessage: data.manager_message || undefined,
      contractorMessage: data.contractor_message,
      newTask: this.deSerializeNewTask(data.new_task),
      contractor: this.deSerializeBasicUserFields(data.contractor),
      privateRequestEvents: data.private_request_events.map(
        (event) => this.deSerializePrivateRequestEvent(event),
      ),
      taskMessages: this.deSerializeMessages(data.task_messages),
      manager: this.deSerializeBasicUserFields(data.manager),
      ...this.deSerializeTypeOfCost(data),
    };
  }

  static serializeRejectPrivateRequestData = (
    data: RejectPrivateRequest,
  ): RawRejectPrivateRequest => ({
    reason_for_rejection: data.reasonForRejection,
  });

  static serializeModifyRatePrivateRequest(data: ModifyRate): RawModifyRate {
    return {
      message_to_contractor: data.message,
      ...this.serializeTypeOfCost(data),
    };
  }
}

export { PrivateRequestSerializer };
