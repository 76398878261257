/* eslint-disable react/jsx-props-no-spreading */
import {
  HTMLProps, useRef, useState,
} from 'react';
import { Button, ButtonStyle } from '../button';
import { Breakpoints } from '../enums';
import { Spinner } from '../spinner';
import { useMediaQuery } from '../../hooks/use-media-query';
import { classnames } from '../../helpers/utils';
import { useClickOutside } from '../../hooks/click-outside';
import { InputProps } from '../input/input';
import { Input, InputStyle } from '../input';
import styles from './select.module.scss';

type SelectProps<Type extends string | number> = {
  options: Type[],
  onClickOption: (option: Type) => void,
  optionTextClass?: string,
  translationPrefix?: string,
  backgroundBlue?: boolean,
  t: (text: string) => string,
  selectState?: SelectState,
  handleRetry?: () => void,
  plainHelperText?: boolean,
  containerClass?: string,
};

enum SelectState {
  ShowOptions = 'ShowOptions',
  ShowSpinner = 'ShowSpinner',
  ShowError = 'ShowError',
}

type ShowContentParams<Type extends string | number> = {
  options: Type[],
  onClick: (option: Type) => void,
  optionTextClass?: string,
  translationPrefix?: string,
  t: (text: string) => string,
  selectState?: SelectState,
  handleRetry?: () => void,
  mobile: boolean,
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const handleContentToShow = <Type extends string | number,>({
  options, onClick, optionTextClass, translationPrefix, selectState, t, handleRetry, mobile,
}: ShowContentParams<Type>) => {
  switch (selectState) {
    case SelectState.ShowSpinner:
      return (<Spinner fixed={false} small />);
    case SelectState.ShowError:
      return (
        <div className={classnames(styles.itemCenter, 'text__heading4__textNeutral50')}>
          <div>{t('error.somethingWentWrong')}</div>
          <Button
            buttonStyle={ButtonStyle.Primary}
            className={classnames('text__button__small__textNeutral10', styles.retry)}
            onClick={handleRetry}
          >
            {t('retry')}
          </Button>
        </div>
      );
    default:
      return (options.map((option) => (
        <button key={option} onClick={() => onClick(option)} type="button" className={styles.item}>
          <div className={optionTextClass || mobile ? 'text__body__medium__textNeutral50' : 'text__body__medium__textNeutral50'}>
            { translationPrefix && typeof option === 'string'
              ? t(`${translationPrefix}.${option}`)
              : option}
          </div>
        </button>
      ))
      );
  }
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Select = <Type extends string | number,>({
  options, onClickOption, optionTextClass = '',
  id, label, inputStyle = InputStyle.FORM, helperText = '', translationPrefix,
  backgroundBlue = false, formError, t, selectState = SelectState.ShowOptions,
  plainHelperText = false, handleRetry, containerClass = '', ...props
}: Omit<InputProps, 'containerClass'> & SelectProps<Type> & HTMLProps<HTMLInputElement>) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShow(false));
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const onClick = (option: Type) => {
    setShow(false);
    onClickOption(option);
  };

  return (
    <div ref={containerRef} className={classnames(styles.selectContainer, containerClass)}>
      <Input
        autoComplete="off"
        isSelect
        onClick={() => setShow(!show)}
        activeSelect={show}
        {...props}
        id={id}
        inputStyle={inputStyle}
        helperText={helperText}
        formError={formError}
        backgroundBlue={backgroundBlue}
        label={label}
        t={t}
        inputMode="none"
        plainHelperText={plainHelperText}
        // This value is needed because, otherwise, a warning will be shown indicating
        // onChange or readonly props are missing. Cannot add readonly because, if we do,
        // validation (like being required) would not be performed on the component
        onChange={() => {}}
      >
        {show
        && (
        <div className={classnames(styles.content)}>
          {handleContentToShow({
            options,
            onClick,
            optionTextClass,
            translationPrefix,
            selectState,
            t,
            handleRetry,
            mobile,
          })}
        </div>
        )}
      </Input>
    </div>
  );
};

export { Select, SelectState };
