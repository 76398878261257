import { classnames, resolveDateHour } from '../../helpers/utils';
import { AvatarOrInitials, AvatarSize } from '../avatar-or-initials';
import styles from './message.module.scss';
import { FileType, Sender } from '../../types';

export type MessageType = {
  id: number,
  sender: Sender,
  body: string,
  senderName: string,
  senderLastName: string,
  senderUrl?: string,
  date: string,
  files?: FileType[]
};

type MessageProps = {
  message: MessageType,
  language: string,
  containerClass?: string,
  userRole: Sender,
  t: (text: string) => string,
};

const translPrefix = 'message';

const showSeeMore = false;

const Message = ({
  message, containerClass, t, language, userRole,
}: MessageProps) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <div className={classnames(message.sender === userRole ? styles.container : styles.containerReverse, containerClass || '')}>
      <AvatarOrInitials
        firstName={message.senderName}
        lastName={message.senderLastName}
        className={styles.avatar}
        size={AvatarSize.Small}
        url={message.senderUrl}
      />
      <div className={styles.informationWrapper}>
        <div className={styles.messageBubble}>
          <div className={classnames(styles.body, 'text__body__medium__textNeutral40')}>
            {message.body}
          </div>
          {showSeeMore && (
          <button
            type="button"
            onClick={onClick}
            className={classnames(styles.seeMore, 'text__body__medium__textNeutral40')}
          >
            {t(`${translPrefix}.seeMore`)}
          </button>
          )}
        </div>
        <div className={styles.dateAndName}>
          <div className="text__body__tiny__textNeutral30">
            {resolveDateHour(message.date, language, t, 'MMMM Do, YYYY - HH:mm')}
          </div>
          <div className="text__body__tiny__textNeutral30">{`${message.senderName} ${message.senderLastName}`}</div>
        </div>
      </div>
      {// TODO add files
      }
    </div>
  );
};

export { Message };
