import { PrivateRequest } from 'networking/types';
import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { TaskUpdatesOverview } from '@mapix/common/src/common/task-updates-overview';
import { useTranslation } from 'react-i18next';
import { parseTaskStatus } from 'helpers/utils';
import { MessageType } from '@mapix/common/src/common/message';
import { TaskStatus, RawNewTaskEvent } from '@mapix/common/src/common/enums';
import { NewTaskEventType, TaskMessage, TypeOfCost } from '@mapix/common/src/types';
import { TaskStatusDates } from '@mapix/common/src/common/task-dates-overview/task-date-overview';
import styles from './status-tab.module.scss';
import { UnassignedTaskDetailRow } from './unassigned-task-detail-row';
import { ContractorDetails } from './contractor-details';

type StatusTabProps = {
  className?: string;
  privateRequest: PrivateRequest;
  taskStatus: TaskStatus | '';
  changeContractorFn: () => void;
};

const translPrefix = 'newTaskDetail.tabs.status';

const getDates = (events: NewTaskEventType[], creationDate?: string): TaskStatusDates => ({
  negotiating: events.find((event) => event.eventType === RawNewTaskEvent
    .WAITING_FOR_CONTRACTOR_APPROVAL_TO_NEGOTIATION_PHASE)?.createdAt,
  solved: events.find((event) => event.eventType === RawNewTaskEvent
    .IN_PROGRESS_TO_SOLVED)?.createdAt,
  inProgress: events.find((event) => event.eventType === RawNewTaskEvent
    .READY_TO_IN_PROGRESS)?.createdAt,
  rejected: events.find((event) => event.eventType === RawNewTaskEvent
    .AWAITING_TO_REJECTED)?.createdAt,
  applied: events.find((event) => event.eventType === RawNewTaskEvent
    .NEGOTIATION_PHASE_TO_READY)?.createdAt,
  created: creationDate,
});

const mapMessages = (
  privateRequest: PrivateRequest,
): MessageType[] => privateRequest.taskMessages.map((message: TaskMessage) => ({
  id: message.id,
  date: message.createdAt,
  message: message.text,
  sender: message.sender,
  senderLastName: message.sender === 'Contractor'
    ? privateRequest.contractor.lastName : privateRequest.manager.lastName,
  senderName: message.sender === 'Contractor'
    ? privateRequest.contractor.name : privateRequest.manager.name,
  body: message.text,
}
));

const StatusTab = ({
  privateRequest, taskStatus, changeContractorFn, className = '',
}: StatusTabProps) => {
  const { t, i18n } = useTranslation();

  const isUnassigned = taskStatus === TaskStatus.UNASSIGNED;

  const showStatusUpdates = taskStatus === TaskStatus.NEGOTIATING;

  const getTypeOfCost = (): (TypeOfCost | null) => {
    switch (privateRequest.typeOfCost) {
      case 'budget':
        return {
          typeOfCost: 'budget',
          budget: privateRequest.budget,
        };
      case 'hourlyRate':
        return {
          typeOfCost: 'hourlyRate',
          hourlyRate: privateRequest.hourlyRate,
          amountOfHours: privateRequest.amountOfHours,
        };
      default:
        return null;
    }
  };

  return (
    <div className={className}>
      {isUnassigned && (
      <UnassignedTaskDetailRow
        t={t}
        newTask={privateRequest.newTask}
        translPrefix={translPrefix}
        typeOfCostObject={getTypeOfCost()}
      />
      )}
      <ContractorDetails
        privateRequest={privateRequest}
        t={t}
        translPrefix={translPrefix}
        taskStatus={taskStatus}
        changeContractorFn={changeContractorFn}
      />

      <hr className={styles.divider} />
      <TaskDatesOverview
        t={t}
        dates={getDates(
          privateRequest.newTask.newTaskEvents,
          isUnassigned ? privateRequest.newTask.createdAt : undefined,
        )}
        estimatedStartDate={parseTaskStatus(privateRequest.newTask.status) === TaskStatus
          .NEGOTIATING ? privateRequest.startDate : undefined}
        language={i18n.language}
        translPrefix={translPrefix}
      />

      {showStatusUpdates && (
      <>
        <hr className={styles.divider} />
        <TaskUpdatesOverview
          t={t}
          messages={mapMessages(privateRequest)}
          language={i18n.language}
          translPrefix={translPrefix}
          userRole="Manager"
        />
      </>
      )}
    </div>
  );
};

export { StatusTab };
