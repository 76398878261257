import { PropertyAddress } from 'models/property-address';
import { PropertyOwner } from 'models/property-owner';
import { addOrRemoveFromArrayObject, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Country } from 'models/country';
import { Contractor } from 'models/contractor';
import { Lease } from 'models/lease';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { Coordinates } from '@mapix/common/src/common/map-view';

const translPrefix = 'createCondo';

const buildingSteps = [`${translPrefix}.steps.general`, `${translPrefix}.steps.legal`, `${translPrefix}.steps.contractors`];

type CreatePropertyCondoState = {
  step: number,
  address: PropertyAddress,
  landlord: PropertyOwner,
  otherLandlord: PropertyOwner,
  formErrors: HTMLValidationError,
  steps: string[],
  countries: Country[],
  contractors: Contractor[],
  legal: Lease,
  status: string,
  finish: boolean,
  modal: boolean,
  errorModal: boolean,
  draftModal: boolean,
  finishDraft: boolean,
  id?: number,
  [key: string]: any
};

type Action =
  | { type: 'GO_BACK' }
  | { type: 'CHANGE_INPUT', value: string | number, object: string, field: string | number }
  | { type: 'CHANGE_COORDINATES', value: Coordinates }
  | { type: 'FINISH_STEP_1', address: PropertyAddress, owner: PropertyOwner, otherOwner: PropertyOwner }
  | { type: 'FINISH_STEP_2', lease: Lease, status: string }
  | { type: 'FINISH', contractors: Contractor[] }
  | { type: 'COUNTRIES_FETCHED', fetchedCountries: Country[] }
  | { type: 'ADD_REMOVE_CONTRACTOR', contractor: Contractor }
  | { type: 'FINISH_MODAL' }
  | { type: 'FINISH_ERROR' }
  | { type: 'CLOSE_ERROR' }
  | { type: 'DRAFT_MODAL' }
  | { type: 'FINISH_DRAFT' };

const initBuilding = (property: DetailedDraftProperty | undefined) => {
  if (property) {
    return {
      buildingName: property.buildingName,
    };
  }
  return {
    buildingName: '',
  };
};

const initProperty = (property: DetailedDraftProperty | undefined) => ({
  address: property ? property.address : new PropertyAddress(null),
  landlord: property ? property.owner : new PropertyOwner(null),
  otherLandlord: property && property.otherOwner ? property.otherOwner : new PropertyOwner(null),
  contractors: property ? property.contractors : [],
  legal: property && property.lease ? property.lease : new Lease(null),
  status: property && property.status ? property.status : '',
  id: property && property.id ? property.id : undefined,
});

const initialState = (property: DetailedDraftProperty | undefined) => ({
  step: 0,
  building: initBuilding(property),
  ...initProperty(property),
  formErrors: {},
  steps: buildingSteps,
  countries: [],
  finish: false,
  modal: false,
  errorModal: false,
  finishDraft: false,
  draftModal: false,
});

function CreatePropertyCondoReducer(state: CreatePropertyCondoState, action: Action) {
  switch (action.type) {
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'CHANGE_INPUT':
      return {
        ...state,
        [action.object]: {
          ...state[action.object],
          [action.field]: action.value,
        },
      };
    case 'CHANGE_COORDINATES':
      return {
        ...state,
        address: { ...state.address, lat: action.value[0], long: action.value[1] },
      };
    case 'FINISH_STEP_1':
      return {
        ...state,
        address: action.address,
        landlord: action.owner,
        otherLandlord: action.otherOwner,
        step: state.step + 1,
      };
    case 'FINISH_STEP_2':
      return {
        ...state,
        legal: action.lease,
        status: action.status,
        step: state.step + 1,
      };
    case 'COUNTRIES_FETCHED':
      return {
        ...state,
        countries: action.fetchedCountries,
      };
    case 'ADD_REMOVE_CONTRACTOR':
      return {
        ...state,
        contractors: addOrRemoveFromArrayObject(state.contractors, action.contractor, 'email'),
      };
    case 'FINISH':
      return {
        ...state,
        contractors: action.contractors,
        finish: true,
      };
    case 'FINISH_MODAL':
      return {
        ...state,
        modal: true,
      };
    case 'FINISH_ERROR':
      return {
        ...state,
        errorModal: true,
      };
    case 'CLOSE_ERROR':
      return {
        ...state,
        errorModal: false,
      };
    case 'DRAFT_MODAL':
      return {
        ...state,
        draftModal: !state.draftModal,
      };
    case 'FINISH_DRAFT':
      return {
        ...state,
        finishDraft: true,
      };
    default:
      return state;
  }
}

export { CreatePropertyCondoReducer, initialState };
export type { Action };
