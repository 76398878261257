import { DateFilter } from 'common/filter-by-date/filter-by-date';

import { PropertyAddress } from 'models/property-address';
import {
  DateCodes, MaintenanceTabs, PaymentStates, TaskEvents,
} from 'common/enums';
import dayjs from 'dayjs';
import { ParamsHelper } from 'helpers/params-helper';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ChangeEvent } from 'react';
import { Contractor } from 'models/contractor';
import { RawNewTaskStatus, TaskStatus } from '@mapix/common/src/common/enums';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

type ModalFilterItem = {
  name: string,
  code: string,
  parentType: string,
};

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

type ParamObject = {
  [key: string]: string[],
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  dateFilters: DateFilter,
  tab: string | undefined,
};

const queryFiltersBlackList = ['showModal'];

const camelCaseToStringWithSpaces = (string: string): string => string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

const addFiltersToURL = (filters?: ModalFilterItem[]): string => {
  let addQueryParams = '';
  if (filters && filters.length > 0) {
    filters.forEach((item) => {
      addQueryParams = addQueryParams.concat(`${!addQueryParams ? '' : '&'}${item.parentType}[]=${item.code}`);
    });
  }
  return addQueryParams;
};

const addDateFilterToURL = (dateFilters?: DateFilter): string => {
  let addQueryParams = '';
  if (dateFilters) {
    if (dateFilters.startDate !== '') {
      addQueryParams = addQueryParams.concat(`${!addQueryParams ? '' : '&'}from_date=${dateFilters.startDate}`);
    }
    if (dateFilters.endDate !== '') {
      addQueryParams = addQueryParams.concat(`${!addQueryParams ? '' : '&'}to_date=${dateFilters.endDate}`);
    }
    if (dateFilters.startDate !== '' && dateFilters.endDate === '') {
      addQueryParams = addQueryParams.concat(`${!addQueryParams ? '' : '&'}to_date=${dayjs().format('YYYY-MM-DD')}`);
    }
  }
  return addQueryParams;
};

const getFiltersByUrl = (url: string) => {
  const result: URLFilters = {
    appliedFilters: [],
    dateFilters: {
      startDate: '',
      endDate: '',
      code: DateCodes.All,
    },
    tab: '',
  };
  const arrayParams = Object.entries(ParamsHelper.parseQueryParams(url, 0));
  arrayParams.forEach((elem) => {
    const key = elem[0]?.toString() || '';
    const value = elem[1]?.toString() || '';
    if (!queryFiltersBlackList.includes(key)) {
      if (key === 'tab') {
        result.tab = value;
      } else if (key === 'startDate') {
        result.dateFilters.startDate = value;
      } else if (key === 'endDate') {
        result.dateFilters.endDate = value;
      } else if (key === 'codeDate') {
        result.dateFilters.code = value;
      } else {
        value.split(',').forEach((code) => {
          const codeWithSpaces = code.replace(/\+/g, ' ');
          result.appliedFilters.push({ name: key, code: codeWithSpaces, parentType: key });
        });
      }
    }
  });
  return result;
};

const groupFilters = (tab: string, dateFilter?: DateFilter, appliedFilters?: ModalFilterItem[]) => {
  const result: ParamObject = { };
  let url = '';
  if (appliedFilters) {
    appliedFilters.forEach((elem) => {
      if (!(elem.parentType in result)) {
        result[elem.parentType] = [elem.code];
      } else {
        result[elem.parentType].push(elem.code);
      }
    });
  }
  Object.keys(result).forEach((key) => {
    const encodedValue = window.encodeURIComponent(result[key].join(','));
    const encodedKey = window.encodeURIComponent(key);
    url = url.concat(`${!url ? '' : '&'}${encodedKey}=${encodedValue}`);
  });
  url = url.concat(`${!url ? '' : '&'}tab=${tab}`);
  if (dateFilter && dateFilter.startDate) {
    url = url.concat(`${!url ? '' : '&'}startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}&codeDate=${dateFilter.code}`);
  }
  window.history.pushState('', '', '?'.concat(url));
};

const addDateAndFiltersToURL = (
  dateFilters?: SelectedValueFilter | DateFilter,
  filters?: ModalFilterItem[],
): string => {
  let addQueryParams = '';
  if (filters && filters.length > 0) {
    filters.forEach((item) => {
      addQueryParams = addQueryParams.concat(`${!addQueryParams ? '' : '&'}${item.parentType}[]=${item.code}`);
    });
  }
  if (addQueryParams && dateFilters) {
    addQueryParams = addQueryParams.concat(`&${addDateFilterToURL(dateFilters)}`);
  } else {
    addQueryParams = addDateFilterToURL(dateFilters);
  }
  return addQueryParams;
};

const months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'];

const getDetailCardMonth = (expirationDate?: string): string => {
  if (!expirationDate) {
    return 'N/A';
  }
  return months[Number(expirationDate.split('-')[1])];
};

const getExpirationTitle = (expirationDate?: string): string => {
  if (!expirationDate) {
    return 'N/A';
  }

  return `${getDetailCardMonth(expirationDate)} ${expirationDate.split('-')[0]}`;
};

const getFullAddress = (address: PropertyAddress) => `${address.unitNumber ? `App. ${address.unitNumber} - ` : ''}${address.civicNumber} ${address.streetName}, ${address.city} ${address.province} ${address.zipCode} `;

const getPaymentStatus = (dueDateParam: string, paymentDateParam?: string): PaymentStates => {
  const dueDate = dayjs(dueDateParam);
  if (paymentDateParam) {
    const paymentDate = dayjs(paymentDateParam);
    const days = dueDate.diff(paymentDate);
    if (days < 0) {
      return PaymentStates.PaidLate;
    }
    return PaymentStates.PaidOnTime;
  }
  const today = dayjs();
  const days = dueDate.diff(today);
  if (days < 0) {
    return PaymentStates.Late;
  }
  return PaymentStates.OnTime;
};

const checkNumberInput = (e: ChangeEvent<HTMLInputElement>) => {
  const validCharacters = /^\d+$/;
  return e.target.value === '' || validCharacters.test(e.target.value);
};

const checkTextInput = (e: ChangeEvent<HTMLInputElement>) => {
  const validCharacters = /^[A-Za-z ]+$/;
  return e.target.value === '' || validCharacters.test(e.target.value);
};

const checkPhoneInput = (e: ChangeEvent<HTMLInputElement>) => {
  const validCharacters = /^\+?\d*$/;
  return e.target.value === '' || validCharacters.test(e.target.value);
};

const getTimeZoneFormat = (date: string, format: string) => {
  const timeZone = dayjs.tz.guess();
  return `${dayjs.utc(date).tz(timeZone).format(format)}`;
};

const getTaskEventText = (t: (key: string) => string, element: SerializedTaskEvents) => {
  let formatDate: string;
  if (element.eventType === TaskEvents.started) {
    formatDate = getTimeZoneFormat(element.createdAt, 'DD/MM/YYYY');
  } else {
    formatDate = getTimeZoneFormat(element.createdAt, 'DD/MM/YYYY H:mm');
  }

  if (element.eventType === TaskEvents.created) {
    return (`${t('taskDetail.creationDate')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.started) {
    return (`${t('taskDetail.startedDate')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.newToInProgress) {
    return (`${t('taskDetail.inProgress')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.newToRejected) {
    return (`${t('taskDetail.rejected')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.inProgressToRejected) {
    return (`${t('taskDetail.cancelled')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.inProgressToSolved) {
    return (`${t('taskDetail.solved')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.assigned) {
    return (`${t('taskDetail.assignedTo')}:  ${element.contractor.category}, ${element.contractor.name} - ${formatDate}`);
  } if (element.eventType === TaskEvents.reassigned) {
    return (`${t('taskDetail.reassignedTo')}:  ${element.contractor.category}, ${element.contractor.name} - ${formatDate}`);
  } if (element.eventType === TaskEvents.assignementCancelled) {
    return (`${t('taskDetail.cancelledAssignmentTo')}:  ${element.contractor.category}, ${element.contractor.name} - ${formatDate}`);
  } if (element.eventType === TaskEvents.billed) {
    return (`${t('taskDetail.billed')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.payment) {
    return (`${t('taskDetail.paymentRegisteredOn')}:  ${formatDate}`);
  } if (element.eventType === TaskEvents.billDeleted) {
    return (`${t('taskDetail.billDeleted')}:  ${formatDate}`);
  } return null;
};

// Merge countryCode and phoneNumber to not modify the table component
const mergePhoneNumberObject = (
  contractorsList: Contractor[],
): Contractor[] => contractorsList.map(
  (cont) => {
    const contractorCopy = { ...cont };
    contractorCopy.phoneNumber = `${contractorCopy.countryCode}${contractorCopy.phoneNumber}`;
    return contractorCopy;
  },
);

const parseTaskStatus = (status: RawNewTaskStatus): TaskStatus | '' => {
  switch (status) {
    case RawNewTaskStatus.NEGOTIATION_PHASE:
    case RawNewTaskStatus.READY:
      return TaskStatus.NEGOTIATING;
    case RawNewTaskStatus.IN_PROGRESS:
      return TaskStatus.IN_PROGRESS;
    case RawNewTaskStatus.UNPUBLISHED:
      return TaskStatus.UNPUBLISHED;
    case RawNewTaskStatus.WAITING_FOR_CONTRACTOR_ASSIGNATION:
    case RawNewTaskStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
      return TaskStatus.UNASSIGNED;
    default:
      return '';
  }
};

const maintenanceTypeConverter = (type?: string): string => {
  switch (type) {
    case MaintenanceTabs.New:
      return 'New';
    case MaintenanceTabs.InProgress:
      return 'In Progress';
    case MaintenanceTabs.Solved:
      return 'Solved';
    case MaintenanceTabs.Rejected:
      return 'Rejected';
    default:
      return '';
  }
};

const maintenanceTypeConverterV2 = (type?: string): string => {
  switch (type) {
    case MaintenanceTabs.New:
      return 'new';
    case MaintenanceTabs.Unassigned:
      return 'unassigned';
    case MaintenanceTabs.Negotiating:
      return 'negotiating';
    case MaintenanceTabs.InProgress:
      return 'in_progress';
    case MaintenanceTabs.Solved:
      return 'solved';
    case MaintenanceTabs.Rejected:
      return 'rejected';
    case MaintenanceTabs.Archived:
      return 'archived';
    default:
      return '';
  }
};

const getCityCommaProvinceId = (city: string, provinceId?: string) => `${city}${provinceId ? `, ${provinceId}` : ''}`;

export {
  camelCaseToStringWithSpaces, addFiltersToURL, getDetailCardMonth, getExpirationTitle,
  addDateAndFiltersToURL, addDateFilterToURL, getFullAddress, getPaymentStatus,
  groupFilters, getFiltersByUrl, getTaskEventText, checkNumberInput, checkTextInput,
  checkPhoneInput, mergePhoneNumberObject, getCityCommaProvinceId, parseTaskStatus,
  maintenanceTypeConverter, maintenanceTypeConverterV2,
};
