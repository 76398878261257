import { NewTask } from 'networking/types';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useState } from 'react';
import { PropertyType } from 'common/enums';
import { ImageOverlay, ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import styles from './task-view-tab.module.scss';

type TaskViewTabProps = {
  className?: string;
  task: NewTask,
  t: (text: string) => string,
  translPrefix: string,
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const TaskViewTab = ({
  task, t, translPrefix, className = '',
}: TaskViewTabProps) => {
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const getAffectedAreaValue = () => {
    const { property, unit } = task;

    if (property.type === PropertyType.Building) {
      if (!unit) {
        return `${t(`${translPrefix}.commonArea`)}`;
      }
      return unit.unitNumber;
    }

    if (property.type === PropertyType.CondoHouse) {
      return property.address.unitNumber;
    }

    return '-';
  };

  const showImageOverlay = () => (
    <ImageOverlay
      photos={overlayState.photos}
      selectedIndex={overlayState.index}
      closeFn={hideOverlay}
      t={t}
    />
  );

  const showPhotos = () => (
    <div className={styles.photoRow}>
      {task.taskPhotos!.map((photo, index) => (
        <img
          role="presentation"
          onClick={() => showOverlay(index, task?.taskPhotos!)}
          key={photo.url}
          className={styles.img}
          src={photo.url}
          alt="TaskPhoto"
        />
      ))}
    </div>
  );

  const showEmptyPhotos = () => (
    <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
      {t('taskDetail.emptyPhotos')}
    </p>
  );

  return (
    <div className={classnames(className, styles.taskViewContainer)}>
      {overlayState.show && showImageOverlay()}

      <div className={classnames('text__heading6__textNeutral50')}>{`${t(`${translPrefix}.description`)}:`}</div>
      <div className={classnames('mt-15', 'text__body__medium__textNeutral40')}>{task.description}</div>

      <div className={classnames('mt-40', 'text__heading6__textNeutral50')}>{`${t(`${translPrefix}.affectedArea`)}:`}</div>
      <div className={classnames('mt-15', 'text__body__medium__textNeutral40')}>{getAffectedAreaValue()}</div>

      <div className={classnames('mt-40', 'text__heading6__textNeutral50')}>{`${t(`${translPrefix}.photos`)}:`}</div>
      <div className={styles.photos}>
        {(task?.taskPhotos?.length ?? 0) > 0 ? showPhotos() : showEmptyPhotos()}
      </div>
    </div>
  );
};

export { TaskViewTab };
