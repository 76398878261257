import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewTask } from 'networking/types/private-request';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { logger } from 'helpers/logger';
import { RouteName, goToPage } from 'routes';
import { useTranslation } from 'react-i18next';
import { UnassignedTaskDetailRow } from 'common/new-task-detail/tabs/status/unassigned-task-detail-row';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { ButtonStyle } from '@mapix/common/src/common/button';
import { TaskStatus } from '@mapix/common/src/common/enums';
import { parseTaskStatus } from 'helpers/utils';
import styles from './unpublished-task-detail.module.scss';
import { MarkAsSolvedModal } from './mark-as-solved-modal';

type ParamType = {
  id: string,
};

enum ModalState {
  None = 'none',
  ReviewEdit = 'reviewEdit',
  AssignContractor = 'assignContractor',
  MarkAsSolved = 'markedAsSolved',
}

const translPrefix = 'newTaskDetail';

const UnpublishedTaskDetail = () => {
  const [fetching, setFetching] = useState(true);
  const [newTask, setNewTask] = useState<NewTask>({} as NewTask);
  const [currentTab, setCurrentTab] = useState<string>(TaskDetailTabs.Status);
  const [modalState, setModalState] = useState<ModalState>(ModalState.None);

  const { id } = useParams<ParamType>();

  const { t, i18n } = useTranslation();

  const getTask = async () => {
    try {
      const actualTask = await MaintenanceController.getNewTask(id);
      setNewTask(actualTask);

      if (parseTaskStatus(actualTask.status) !== TaskStatus.UNPUBLISHED) {
        goToPage(RouteName.ErrorPage);
      }

      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  if (fetching) {
    return <Spinner />;
  }

  const getContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <div className={styles.tabsContainer}>
            <UnassignedTaskDetailRow
              newTask={newTask}
              translPrefix={`${translPrefix}.tabs.status`}
              t={t}
            />
            <hr className={styles.divider} />
            <TaskDatesOverview
              dates={{ created: newTask.createdAt }}
              translPrefix={`${translPrefix}.tabs.status`}
              t={t}
              language={i18n.language}
            />
          </div>
        );
      case TaskDetailTabs.TaskView:
        return (
          <TaskViewTab
            className={styles.tabsContainer}
            task={newTask}
            translPrefix={`${translPrefix}.tabs.taskView`}
            t={t}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <NewTaskDetail
        currentTab={currentTab as TaskDetailTabs}
        newTask={newTask}
        getTabContent={getContent}
        setCurrentTab={setCurrentTab}
        translPrefix={translPrefix}
        textButtonRight={t(`${translPrefix}.footer.markAsSolved`)}
        textButtonLeft={t(`${translPrefix}.footer.publishTask`)}
        leftButtonFn={() => setModalState(ModalState.ReviewEdit)}
      />
      {modalState === ModalState.ReviewEdit && (
      <ModalInformation
        handleButtonClose={() => setModalState(ModalState.None)}
        handleButtonRight={() => goToPage(RouteName.EditNewTask, { id })}
        handleButtonCenter={modalState === ModalState.ReviewEdit
          ? () => setModalState(ModalState.AssignContractor)
          : () => {}}
        title={t(`${translPrefix}.publishTaskModal.title`)}
        body={t(`${translPrefix}.publishTaskModal.reviewOrEditTask`)}
        buttonTextCenter={t('no')}
        buttonTextRight={t('yes')}
        containerClass={styles.modalContainer}
        buttonStyleCenter={ButtonStyle.Secondary}
        buttonsWrapperClass={styles.buttonsWrapperSlim}
      />
      )}
      {modalState === ModalState.AssignContractor && (
      <ModalInformation
        handleButtonClose={() => setModalState(ModalState.None)}
        handleButtonRight={() => {}}
        handleButtonCenter={() => {}}
        title={t(`${translPrefix}.publishTaskModal.title`)}
        body={t(`${translPrefix}.publishTaskModal.assignContractor`)}
        buttonTextCenter={t('taskType.private_task')}
        buttonTextRight={t('taskType.public_task')}
        containerClass={styles.modalContainer}
        buttonsWrapperClass={styles.buttonsWrapper}
        buttonStyleCenter={ButtonStyle.Secondary}
      />
      )}
      {modalState === ModalState.MarkAsSolved && (
      <MarkAsSolvedModal
        translPrefix={translPrefix}
        handleButtonClose={() => setModalState(ModalState.None)}
      />
      )}
    </>
  );
};

export { UnpublishedTaskDetail };
