import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import { classnames } from '@mapix/common/src/helpers/utils';
import { TaskStatus } from '@mapix/common/src/common/enums';
import { useTranslation } from 'react-i18next';
import styles from '../new-task-detail.module.scss';

type FooterProps = {
  translPrefix: string,
  taskStatus: TaskStatus | '',
  textButtonLeft: string,
  textButtonRight: string,
  disableRightButton?: boolean,
  withTooltip?: boolean
  toolTipText?: string,
  rightButtonFn?: () => void,
  leftButtonFn: () => void,
};

const getButtonRight = (
  taskStatus: TaskStatus | '',
  disableRightButton: boolean,
  withTooltip: boolean,
  textButtonRight: string,
  toolTipText: string,
  rightButtonFn?: () => void,
) => {
  if (taskStatus === TaskStatus.UNASSIGNED) return null;

  const button = (
    <Button
      buttonStyle={ButtonStyle.Primary}
      className={classnames('text__body__large__textNeutral10', styles.button)}
      onClick={rightButtonFn}
      disabled={disableRightButton}
    >
      {textButtonRight}
    </Button>
  );

  if (withTooltip) {
    return (
      <Tooltip
        textComponent={button}
        hoverComponent={toolTipText}
        position={TooltipPosition.Left}
        containerWidth="280px"
        hideTooltip={!disableRightButton}
      />
    );
  }
  return button;
};

const Footer = ({
  translPrefix, taskStatus, disableRightButton, withTooltip, textButtonRight,
  textButtonLeft, toolTipText, rightButtonFn, leftButtonFn,
}: FooterProps) => {
  const { t } = useTranslation();

  const getLeftButtonColor = () => {
    if (taskStatus === TaskStatus.UNPUBLISHED) return ButtonStyle.Secondary;
    return ButtonStyle.SecondaryOutlined;
  };

  const getContentLeft = () => {
    switch (taskStatus) {
      case TaskStatus.UNASSIGNED:
        return (
          <div className={classnames('text__body__small__textNeutral30', styles.flexCenter)}>
            {t(`${translPrefix}.footer.contractorSelectionDisclaimer`)}
          </div>
        );
      case TaskStatus.UNPUBLISHED:
        return '';
      default:
        return (
          <Button
            buttonStyle={ButtonStyle.Back}
            className={classnames('text__body__small__secondary90', styles.reportProblem)}
            onClick={() => {}}
          >
            {t('reportProblem')}
          </Button>
        );
    }
  };

  return (
    <>
      <div className={styles.footerPadding} />
      <div className={styles.footer}>
        <hr className={styles.divider} />
        <div className={getContentLeft() ? styles.buttonsWrapper : styles.buttonsWrapperFlexEnd}>
          {getContentLeft()}
          <div className={classnames('text__body__medium__textNeutral10', 'row')}>
            <Button
              buttonStyle={getLeftButtonColor()}
              className={classnames('text__body__large__secondary80', styles.button)}
              onClick={leftButtonFn}
            >
              {textButtonLeft}
            </Button>
            {getButtonRight(
              taskStatus,
              disableRightButton || false,
              withTooltip || false,
              textButtonRight,
              toolTipText || '',
              rightButtonFn,
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Footer };
