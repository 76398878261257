import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from 'pages/bills-table/bills-table-container.module.scss';
import { BillsTable } from 'common/bills-table';
import { useState } from 'react';

const translPrefix = 'bills';
const BillsTableContainer = () => {
  const [hideHeader, setHideHeader] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.billsContainer}>

      {!hideHeader && (
        <>
          <div className={classnames(styles.title, 'text__heading4__textNeutral40')}>
            {t(`${translPrefix}.title`)}
          </div>
          <div className={classnames(styles.subTitle, 'text__body__small__textNeutral30')}>
            {t(`${translPrefix}.subTitle`)}
          </div>
        </>
      )}

      <BillsTable hideHeader={() => setHideHeader(!hideHeader)} withTabs />

    </div>
  );
};

export { BillsTableContainer };
