import { PaymentInformationStatus } from '@mapix/common/src/common/enums';
import type {
  RawCanadaProvinces,
  RawTypeOfOwnership,
  RawBusinessDescription,
  LegalBusinessInfoType,
  RawLegalBusinessInfo,
  DBAinformationType,
  RawDBAinformation,
  RawTimezones,
  RawPaymentInformation,
  PaymentInformationSummary,
  OwnersOrOfficersType,
  RawOwnersOrOfficers,
  SalesProfileType,
  RawSalesProfile,
  ElectronicDebitCreditAuthorizationType,
  RawElectronicDebitCreditAuthorization,
  RawBankInstitution,
  BankInstitution,
  RawBankInstitutions,
  SerializedElectronicDebitCreditAuthorization,
  DocumentsInformationType,
  RawDocumentsInformation,
  ErrorFields,
  RawErrorFields,
} from '@mapix/common/src/types';
import { parseServerError } from '@mapix/common/src/helpers/utils';
import { PaymentInformationType } from '@mapix/common/src/types/payment-information';
import { FileSerializer } from './file-serializer';

const emptyPaymentInformationStatus = { status: PaymentInformationStatus.PENDING, percentage: 0 };

class PaymentInformationSerializer {
  static deSerializePaymentInformation(data: RawPaymentInformation): PaymentInformationSummary {
    return {
      completedInfo: data.summary.completed_info,
      legalInformation: data.summary.legal_information || emptyPaymentInformationStatus,
      dbaInformation: data.summary.dba_information || emptyPaymentInformationStatus,
      ownersOrOfficers: data.summary.owners_or_officer || emptyPaymentInformationStatus,
      electronicAuthorization: data.summary.electronic_authorization
        || emptyPaymentInformationStatus,
      salesProfile: data.summary.sales_profile || emptyPaymentInformationStatus,
      documentInformation: data.summary.document_information || emptyPaymentInformationStatus,
      pricing: data.summary.pricing || emptyPaymentInformationStatus,
    };
  }

  // Legal business information
  static serializeNewLegalBusinessInfo(data: LegalBusinessInfoType): RawLegalBusinessInfo {
    return {
      legal_name: data.legalName || null,
      civic_number: data.civicNumber || null,
      street_name: data.streetName || null,
      po_box: data.poBox || null,
      unit: data.unit || null,
      postal_code: data.postalCode || null,
      primary_country_code: data.primaryCountryCode || null,
      primary_phone_number: data.primaryPhoneNumber || null,
      gst_tax_id: data.gstTaxId || null,
      url: data.url || null,
      mobile_country_code: data.mobileCountryCode || null,
      mobile_phone_number: data.mobilePhoneNumber || null,
      city: data.city || null,
      type_of_ownership: data.typeOfOwnership || null,
      province: data.province || null,
      business_description: data.businessDescription || null,
    };
  }

  static deserializeLegalBusinessInfo(data: RawLegalBusinessInfo): LegalBusinessInfoType {
    return {
      typeOfOwnership: data.type_of_ownership || '',
      legalName: data.legal_name || '',
      civicNumber: data.civic_number || '',
      streetName: data.street_name || '',
      poBox: data.po_box || '',
      unit: data.unit || '',
      city: data.city || '',
      province: data.province || '',
      postalCode: data.postal_code || '',
      primaryCountryCode: data.primary_country_code || '',
      primaryPhoneNumber: data.primary_phone_number || '',
      gstTaxId: data.gst_tax_id || '',
      url: data.url || '',
      businessDescription: data.business_description || '',
      mobileCountryCode: data.mobile_country_code || '',
      mobilePhoneNumber: data.mobile_phone_number || '',
    };
  }

  static deserializeLegalBusinessInfoErrors(
    data: RawErrorFields<RawLegalBusinessInfo>,
    t: (text: string) => string,
  ): ErrorFields<LegalBusinessInfoType> {
    return {
      typeOfOwnership: parseServerError(t, data.type_of_ownership),
      legalName: parseServerError(t, data.legal_name),
      civicNumber: parseServerError(t, data.civic_number),
      streetName: parseServerError(t, data.street_name),
      poBox: parseServerError(t, data.po_box),
      unit: parseServerError(t, data.unit),
      city: parseServerError(t, data.city),
      province: parseServerError(t, data.province),
      postalCode: parseServerError(t, data.postal_code),
      primaryCountryCode: parseServerError(t, data.primary_country_code),
      primaryPhoneNumber: parseServerError(t, data.primary_phone_number),
      gstTaxId: parseServerError(t, data.gst_tax_id),
      url: parseServerError(t, data.url),
      businessDescription: parseServerError(t, data.business_description),
      mobileCountryCode: parseServerError(t, data.mobile_country_code),
      mobilePhoneNumber: parseServerError(t, data.mobile_phone_number),
    };
  }

  // DBA information
  static serializeDBAInformation(data: DBAinformationType): RawDBAinformation {
    return {
      name_of_business: data.nameOfBusiness || null,
      civic_number: data.civicNumber || null,
      street_name: data.streetName || null,
      unit: data.unit || null,
      city: data.city || null,
      province: data.province || null,
      postal_code: data.postalCode || null,
      primary_phone_number: data.primaryPhoneNumber || null,
      primary_country_code: data.primaryCountryCode || null,
      timezone: data.timezone || null,
      product_or_service: data.productOrService || null,
      years_in_business: data.lessThanOneYearInBusiness ? 0 : data.yearsInBusiness as number,
      months_in_business: data.monthsInBusiness || null,
      email_customer_service: data.emailCustomerService || null,
      email_statement: data.emailStatement || null,
      authorized_contact: data.authorizedContact || null,
      customer_service_phone_number: data.customerServicePhoneNumber || null,
      customer_service_country_code: data.customerServiceCountryCode || null,
      visa_or_mastercard: data.visaOrMastercard,
      visa_or_mastercard_risk_program: data.visaOrMastercard && data.visaOrMastercardRiskProgram
        ? data.visaOrMastercardRiskProgram : null,
      visa_or_mastercard_date: data.visaOrMastercard && data.visaOrMastercardDate
        ? data.visaOrMastercardDate : null,
      previous_processor: data.previousProcessor,
      mailing_address: data.mailingAddress || null,
    };
  }

  static deSerializeDBAInformation(data: RawDBAinformation): DBAinformationType {
    return {
      nameOfBusiness: data.name_of_business || '',
      civicNumber: data.civic_number || '',
      streetName: data.street_name || '',
      unit: data.unit || '',
      city: data.city || '',
      province: data.province || '',
      postalCode: data.postal_code || '',
      primaryPhoneNumber: data.primary_phone_number || '',
      primaryCountryCode: data.primary_country_code || '',
      timezone: data.timezone || '',
      productOrService: data.product_or_service || '',
      lessThanOneYearInBusiness: data.years_in_business === 0,
      yearsInBusiness: data.years_in_business || '',
      monthsInBusiness: data.months_in_business || '',
      emailCustomerService: data.email_customer_service || '',
      emailStatement: data.email_statement || '',
      authorizedContact: data.authorized_contact || '',
      customerServicePhoneNumber: data.customer_service_phone_number || '',
      customerServiceCountryCode: data.customer_service_country_code || '',
      visaOrMastercard: data.visa_or_mastercard || false,
      visaOrMastercardRiskProgram: data.visa_or_mastercard_risk_program || '',
      visaOrMastercardDate: data.visa_or_mastercard_date || '',
      previousProcessor: data.previous_processor || false,
      mailingAddress: data.mailing_address || '',
    };
  }

  static deSerializeDBAInformationErrors(
    data: RawErrorFields<RawDBAinformation>,
    t: (text: string) => string,
  ): ErrorFields<DBAinformationType> {
    return {
      nameOfBusiness: parseServerError(t, data.name_of_business),
      civicNumber: parseServerError(t, data.civic_number),
      streetName: parseServerError(t, data.street_name),
      unit: parseServerError(t, data.unit),
      city: parseServerError(t, data.city),
      province: parseServerError(t, data.province),
      postalCode: parseServerError(t, data.postal_code),
      primaryPhoneNumber: parseServerError(t, data.primary_phone_number),
      primaryCountryCode: parseServerError(t, data.primary_country_code),
      timezone: parseServerError(t, data.timezone),
      productOrService: parseServerError(t, data.product_or_service),
      lessThanOneYearInBusiness: parseServerError(t, data.years_in_business),
      yearsInBusiness: parseServerError(t, data.years_in_business),
      monthsInBusiness: parseServerError(t, data.months_in_business),
      emailCustomerService: parseServerError(t, data.email_customer_service),
      emailStatement: parseServerError(t, data.email_statement),
      authorizedContact: parseServerError(t, data.authorized_contact),
      customerServicePhoneNumber: parseServerError(t, data.customer_service_phone_number),
      customerServiceCountryCode: parseServerError(t, data.customer_service_country_code),
      visaOrMastercard: parseServerError(t, data.visa_or_mastercard),
      visaOrMastercardRiskProgram: parseServerError(t, data.visa_or_mastercard_risk_program),
      visaOrMastercardDate: parseServerError(t, data.visa_or_mastercard_date),
      previousProcessor: parseServerError(t, data.previous_processor),
      mailingAddress: parseServerError(t, data.mailing_address),
    };
  }

  // Owners or officers

  static serializeOwnersOrOfficers(data: OwnersOrOfficersType): RawOwnersOrOfficers {
    return {
      first_name: data.name || null,
      last_name: data.lastName || null,
      email_address: data.email || null,
      percent_ownership: data.percentOwnership || null,
      date_of_birth: data.dateOfBirth || null,
      civic_number: data.civicNumber || null,
      street_name: data.streetName || null,
      unit_suite_apt_floor: data.unit || null,
      city: data.city || null,
      province: data.province || null,
      postal_code: data.postalCode || null,
      primary_phone_number: data.primaryPhoneNumber || null,
      primary_phone_country_code: data.primaryCountryCode || null,
      sin: data.sin || null,
      drivers_lic: data.driversLic || null,
      drivers_lic_province: data.driversLicProvince || null,
      cell_phone_no: data.cellPhoneNumber || null,
      cell_phone_country_code: data.cellCountryCode || null,
    };
  }

  static deSerializeOwnersOrOfficers(data: RawOwnersOrOfficers): OwnersOrOfficersType {
    return {
      name: data.first_name || '',
      lastName: data.last_name || '',
      email: data.email_address || '',
      confirmEmail: data.email_address || '',
      percentOwnership: data.percent_ownership || '',
      dateOfBirth: data.date_of_birth || '',
      civicNumber: data.civic_number || '',
      streetName: data.street_name || '',
      unit: data.unit_suite_apt_floor || '',
      city: data.city || '',
      province: data.province || '',
      postalCode: data.postal_code || '',
      primaryPhoneNumber: data.primary_phone_number || '',
      primaryCountryCode: data.primary_phone_country_code || '',
      sin: data.sin || '',
      driversLic: data.drivers_lic || '',
      driversLicProvince: data.drivers_lic_province || '',
      cellPhoneNumber: data.cell_phone_no || '',
      cellCountryCode: data.cell_phone_country_code || '',
    };
  }

  static deSerializeOwnersOrOfficersErrors(
    data: RawErrorFields<RawOwnersOrOfficers>,
    t: (text: string) => string,
  ): ErrorFields<OwnersOrOfficersType> {
    return {
      name: parseServerError(t, data.first_name),
      lastName: parseServerError(t, data.last_name),
      email: parseServerError(t, data.email_address),
      confirmEmail: parseServerError(t, data.email_address),
      percentOwnership: parseServerError(t, data.percent_ownership),
      dateOfBirth: parseServerError(t, data.date_of_birth),
      civicNumber: parseServerError(t, data.civic_number),
      streetName: parseServerError(t, data.street_name),
      unit: parseServerError(t, data.unit_suite_apt_floor),
      city: parseServerError(t, data.city),
      province: parseServerError(t, data.province),
      postalCode: parseServerError(t, data.postal_code),
      primaryPhoneNumber: parseServerError(t, data.primary_phone_number),
      primaryCountryCode: parseServerError(t, data.primary_phone_country_code),
      sin: parseServerError(t, data.sin),
      driversLic: parseServerError(t, data.drivers_lic),
      driversLicProvince: parseServerError(t, data.drivers_lic_province),
      cellPhoneNumber: parseServerError(t, data.cell_phone_no),
      cellCountryCode: parseServerError(t, data.cell_phone_country_code),
    };
  }

  // Sales profile

  static serializeSalesProfile(data: SalesProfileType): RawSalesProfile {
    return {
      average_visa_monthly_volume: data.averageVisaMonthlyVolume || null,
      average_visa_ticket_size: data.averageVisaTicketSize || null,
      visa_high_ticket_size: data.visaHighTicketSize || null,
      average_m_c_monthly_volume: data.averageMCMonthlyVolume || null,
      average_m_c_ticket_size: data.averageMCTicketSize || null,
      m_c_high_ticket_size: data.MCHighTicketSize || null,
      average_debit_monthly_volume: data.averageDebitMonthlyVolume || null,
      average_debit_ticket_size: data.averageDebitTicketSize || null,
      debit_high_ticket_size: data.debitHighTicketSize || null,
    };
  }

  static deSerializeSalesProfile(data: RawSalesProfile): SalesProfileType {
    return {
      averageVisaMonthlyVolume: data.average_visa_monthly_volume || '',
      averageVisaTicketSize: data.average_visa_ticket_size || '',
      visaHighTicketSize: data.visa_high_ticket_size || '',
      averageMCMonthlyVolume: data.average_m_c_monthly_volume || '',
      averageMCTicketSize: data.average_m_c_ticket_size || '',
      MCHighTicketSize: data.m_c_high_ticket_size || '',
      averageDebitMonthlyVolume: data.average_debit_monthly_volume || '',
      averageDebitTicketSize: data.average_debit_ticket_size || '',
      debitHighTicketSize: data.debit_high_ticket_size || '',
    };
  }

  static deSerializeSalesProfileErrors(
    data: RawErrorFields<RawSalesProfile>,
    t: (text: string) => string,
  ): ErrorFields<SalesProfileType> {
    return {
      averageVisaMonthlyVolume: parseServerError(t, data.average_visa_monthly_volume),
      averageVisaTicketSize: parseServerError(t, data.average_visa_ticket_size),
      visaHighTicketSize: parseServerError(t, data.visa_high_ticket_size),
      averageMCMonthlyVolume: parseServerError(t, data.average_m_c_monthly_volume),
      averageMCTicketSize: parseServerError(t, data.average_m_c_ticket_size),
      MCHighTicketSize: parseServerError(t, data.m_c_high_ticket_size),
      averageDebitMonthlyVolume: parseServerError(t, data.average_debit_monthly_volume),
      averageDebitTicketSize: parseServerError(t, data.average_debit_ticket_size),
      debitHighTicketSize: parseServerError(t, data.debit_high_ticket_size),
    };
  }

  // Electronic authorization
  static serializeElectronicDebitCreditAuthorization(
    data: ElectronicDebitCreditAuthorizationType,
  ): SerializedElectronicDebitCreditAuthorization {
    return {
      different_accounts_deposits_withdrawals: data.differentAccountDepositsWithdrawals || false,
      account_holder_name: data.accountHolderName || null,
      institution_id: data.institutionId || null,
      transit: data.transit || null,
      account: data.account || null,
      postal_code: data.postalCode || null,
      withdrawal_account_holder_name: data.differentAccountDepositsWithdrawals
      && data.withdrawalAccountHolderName
        ? data.withdrawalAccountHolderName : null,
      withdrawal_institution_id: data.differentAccountDepositsWithdrawals
      && data.withdrawalInstitutionId
        ? data.withdrawalInstitutionId : null,
      withdrawal_transit: data.differentAccountDepositsWithdrawals && data.withdrawalTransit
        ? data.withdrawalTransit : null,
      withdrawal_postal_code: data.differentAccountDepositsWithdrawals && data.withdrawalPostalCode
        ? data.withdrawalPostalCode : null,
    };
  }

  static deSerializeElectronicDebitCreditAuthorization(
    data: RawElectronicDebitCreditAuthorization,
  ): ElectronicDebitCreditAuthorizationType {
    return {
      differentAccountDepositsWithdrawals: data.different_accounts_deposits_withdrawals || false,
      accountHolderName: data.account_holder_name || '',
      institutionId: data.institution?.id || '',
      institution: data.institution ? `${data.institution?.number} - ${data.institution?.name}` : '',
      transit: data.transit || '',
      account: data.account || '',
      postalCode: data.postal_code || '',
      withdrawalAccountHolderName: data.withdrawal_account_holder_name || '',
      withdrawalInstitutionId: data.withdrawal_institution?.id,
      withdrawalInstitution: data.withdrawal_institution
        ? `${data.withdrawal_institution?.number} - ${data.withdrawal_institution?.name}` : '',
      withdrawalTransit: data.withdrawal_transit || '',
      withdrawalPostalCode: data.withdrawal_postal_code || '',
    };
  }

  static deSerializeElectronicDebitCreditAuthorizationErrors(
    data: RawErrorFields<RawElectronicDebitCreditAuthorization>,
    t: (text: string) => string,
  ): ErrorFields<ElectronicDebitCreditAuthorizationType> {
    return {
      differentAccountDepositsWithdrawals: parseServerError(
        t,
        data.different_accounts_deposits_withdrawals,
      ),
      accountHolderName: parseServerError(t, data.account_holder_name),
      institutionId: parseServerError(t, data.institution),
      institution: parseServerError(t, data.institution),
      transit: parseServerError(t, data.transit),
      account: parseServerError(t, data.account),
      postalCode: parseServerError(t, data.postal_code),
      withdrawalAccountHolderName: parseServerError(t, data.withdrawal_account_holder_name),
      withdrawalInstitutionId: parseServerError(t, data.withdrawal_institution),
      withdrawalInstitution: parseServerError(t, data.withdrawal_institution),
      withdrawalTransit: parseServerError(t, data.withdrawal_transit),
      withdrawalPostalCode: parseServerError(t, data.withdrawal_postal_code),
    };
  }

  // Documents information
  static serializeDocumentsInformation(data: DocumentsInformationType): RawDocumentsInformation {
    return {
      application_and_agreement: data.applicationAndAgreement?.url
        ? FileSerializer.serialize(data.applicationAndAgreement) : null,
      proof_of_business: data.proofOfBusiness?.url
        ? FileSerializer.serialize(data.proofOfBusiness) : null,
      void_check: data.voidCheck?.url ? FileSerializer.serialize(data.voidCheck) : null,
      processing_statements: data.processingStatements?.url
        ? FileSerializer.serialize(data.processingStatements) : null,
      equipment_form: data.equipmentForm?.url
        ? FileSerializer.serialize(data.equipmentForm) : null,
      non_profit_evidence: data.nonProfitEvidence?.url
        ? FileSerializer.serialize(data.nonProfitEvidence) : null,
      other: data.other?.url ? FileSerializer.serialize(data.other) : null,
    };
  }

  static deSerializeDocumentsInformation(data: RawDocumentsInformation): DocumentsInformationType {
    return {
      applicationAndAgreement: data.application_and_agreement != null
        ? FileSerializer.deSerialize(data.application_and_agreement) : null,
      proofOfBusiness: data.proof_of_business != null
        ? FileSerializer.deSerialize(data.proof_of_business) : null,
      voidCheck: data.void_check != null
        ? FileSerializer.deSerialize(data.void_check) : null,
      processingStatements: data.processing_statements != null
        ? FileSerializer.deSerialize(data.processing_statements) : null,
      equipmentForm: data.equipment_form != null
        ? FileSerializer.deSerialize(data.equipment_form) : null,
      nonProfitEvidence: data.non_profit_evidence != null
        ? FileSerializer.deSerialize(data.non_profit_evidence) : null,
      other: data.other != null ? FileSerializer.deSerialize(data.other) : null,
    };
  }

  static serializeDocumentName = (
    data: keyof DocumentsInformationType,
  ): keyof RawDocumentsInformation => {
    switch (data) {
      case 'applicationAndAgreement':
        return 'application_and_agreement';
      case 'proofOfBusiness':
        return 'proof_of_business';
      case 'voidCheck':
        return 'void_check';
      case 'processingStatements':
        return 'processing_statements';
      case 'equipmentForm':
        return 'equipment_form';
      case 'nonProfitEvidence':
        return 'non_profit_evidence';
      case 'other':
        return 'other';
      default:
        throw new Error('Invalid type of document information filename');
    }
  };

  // Create application

  static deSerializeCreateApplication(data: RawPaymentInformation): PaymentInformationType {
    return {
      id: data.id,
      applicationId: data.application_id,
      submitted: data.submitted,
      summary: this.deSerializePaymentInformation(data),
    };
  }

  // Dropdown contents

  static deserializeCanadaProvinces(data: RawCanadaProvinces): string[] {
    return data.canada_provinces;
  }

  static deSerializeTimezones(data: RawTimezones): string[] {
    return data.timezones;
  }

  static deserializeTypeOfOwnership(data: RawTypeOfOwnership): string[] {
    return data.types_of_ownership;
  }

  static deserializeBusinessDescriptions(data: RawBusinessDescription): string[] {
    return data.business_descriptions;
  }

  static deserializeBankInstitution(data: RawBankInstitution): BankInstitution {
    return {
      id: data.id,
      name: data.name,
      number: data.number,
    };
  }

  static deserializeBankInstitutions(data: RawBankInstitutions): BankInstitution[] {
    return data.bank_institutions.map(
      (bankInstitution: RawBankInstitution) => this.deserializeBankInstitution(bankInstitution),
    );
  }
}

export { PaymentInformationSerializer };
