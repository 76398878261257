import { useEffect, useState } from 'react';
import { classnames, convertToBlob } from '../../helpers/utils';
import { ProgressBar } from '../browse-files-progress-bar';
import styles from './upload-item-compact.module.scss';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as FileUploaded } from '../../assets/icons/file-uploaded.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { FileState } from '../enums';
import { Button, ButtonStyle } from '../button';

type UploadItemProps = {
  url?: string,
  fileState?: FileState,
  filename?: string,
  retries?: number,
  removeFn: () => void,
  onClickFn?: () => void,
  t: (text: string) => string,
  disableFileRemoval?: boolean,
  handleRetry?: () => void,
  handleChangeFile?: () => void,
  progressBarRefreshRate?: number,
};

const translPrefix = 'fileUpload';

const handleRightButton = (
  fileState: FileState,
  disableFileRemoval: boolean,
  handleRetry: () => void,
  handleChangeFile: () => void,
  removeFn: () => void,
  t: (text: string) => string,
  retry: boolean,
) => {
  switch (fileState) {
    case FileState.LOADING:
      if (!disableFileRemoval) {
        return (
          <button className={styles.crossContainer} onClick={() => removeFn()} type="button">
            <Cross className={styles.cross} />
          </button>
        );
      }
      return null;
    case FileState.COMPLETED:
      if (!disableFileRemoval) {
        return (
          <button className={styles.trashContainer} onClick={() => removeFn()} type="button">
            <Trash className={styles.trash} />
          </button>
        );
      }
      return null;
    case FileState.FAILED:
      return (
        <Button
          onClick={retry ? () => handleRetry() : () => handleChangeFile()}
          buttonStyle={ButtonStyle.SecondaryFilled}
          className={classnames('text__button__small__secondary90', styles.rightButton)}
        >
          <p>{t(`${translPrefix}.${retry ? 'retry' : 'changeFile'}`)}</p>
        </Button>
      );
    default:
      return null;
  }
};

const UploadItemCompact = ({
  url = '', fileState = FileState.COMPLETED, filename = '', removeFn, onClickFn = () => null, t,
  disableFileRemoval = false, retries = 0, handleRetry = () => null, handleChangeFile = () => null,
  progressBarRefreshRate = 1000,
}: UploadItemProps) => {
  const [isPdf, setIsPdf] = useState(false);
  const fileAndProgressbar = (
    <>
      <FileUploaded className={styles.file} />
      <ProgressBar
        fileState={fileState}
        t={t}
        filename={filename}
        refreshRateMS={progressBarRefreshRate}
      />
    </>
  );

  const blobConversion = async () => {
    const resource = await convertToBlob(url);
    setIsPdf(resource.type === 'application/pdf');
  };

  useEffect(() => {
    blobConversion();
  }, []);

  if (fileState === FileState.CANCELLED) return null;

  return (
    <>
      <div className={styles.uploadedImageContainer}>
        {isPdf ? (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className={styles.iconAndImgNameContainer}
          >
            {fileAndProgressbar}
          </a>
        ) : (
          <button
            type="button"
            className={styles.iconAndImgNameContainer}
            onClick={onClickFn}
          >
            {fileAndProgressbar}
          </button>
        )}
        {handleRightButton(
          fileState,
          disableFileRemoval,
          handleRetry,
          handleChangeFile,
          removeFn,
          t,
          retries < 2,
        )}
      </div>
    </>
  );
};
export { UploadItemCompact };
