import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import { Modal } from '@mapix/common/src/common/modal';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '@mapix/common/src/common/button/button';
import dayjs from 'dayjs';
import { InputDate } from '@mapix/common/src/common/input-date';
import { Select } from '@mapix/common/src/common/select';
import { Currency } from 'common/enums';
import styles from './modal-solved-task.module.scss';

type ModalProps = {
  close: () => void,
  solveTask: (task: SolvedTaskModalType) => void,
  startDate: string,
};

type SolvedTaskModalType = {
  endDate: string,
  additionalComments: string,
  currency?: string,
  billedAmount?: number,
  dueDate?: string
};

const ModalSolvedTask = ({ close, solveTask, startDate }: ModalProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [formData, setFormData] = useState<SolvedTaskModalType>({
    endDate: '',
    additionalComments: '',
    currency: '',
    billedAmount: 0,
    dueDate: '',
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onChangeDateFn = (date: string, option: string) => {
    const newData = {
      ...formData,
      [option]: date,
    };
    setFormData(newData);
  };

  const handleSend = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      solveTask(formData);
    }
  };

  const onClickSelect = (option: string) => {
    setFormData((prevState) => ({ ...prevState, currency: option }));
  };

  const handleCheckbox = () => {
    setShowMore(!showMore);
  };
  return (
    <Modal>
      <form onSubmit={handleSend} noValidate>
        <div className={styles.modalContainer}>
          <div className={styles.crossContainer}>
            <button type="button" onClick={() => close()}>
              <Cross className={styles.crossIcon} />
            </button>
          </div>
          <div className={styles.centered}>
            <div className={styles.containerIcon}>
              <Tool className={styles.icon} />
              <Alert
                className={styles.alertIcon}
              />
            </div>
            <p className={classnames(styles.marginTop, 'text__heading5__textNeutral50')}>
              {t('taskDetail.modalSolved.title')}
            </p>
          </div>
          <InputDate
            containerClass={styles.date}
            required
            id="endDate"
            name="endDate"
            label={t('taskDetail.modalSolved.endDate')}
            placeholder={t('taskDetail.modalSolved.endDate')}
            value={formData.endDate !== '' ? dayjs(formData.endDate).toDate() : undefined}
            helperText="MM/DD/YYYY"
            formErrors={formErrors}
            onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'endDate')}
            min={dayjs(startDate).toDate()}
            t={t}
          />
          <p className={classnames(styles.additionalComments, 'text__heading6__textNeutral50')}>
            {t('taskDetail.modalSolved.additionalComments')}
          </p>
          <Input
            id="additionalComments"
            placeholder={t('taskDetail.modalSolved.placeholderComments')}
            inputStyle={InputStyle.FORM}
            value={formData.additionalComments}
            onChange={handleChange}
            formError={formErrors}
            maxLength={140}
            t={t}
          />
          <p className={classnames(styles.count, 'text__body__small__textNeutral40')}>
            {formData.additionalComments.length}
            {' '}
            / 140
          </p>
          <Checkbox onChangeFn={handleCheckbox} labelContent={t('taskDetail.modalSolved.billThisTask')} />
          {showMore && (
          <>
            <p className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>
              {t('taskDetail.modalSolved.billInfo')}
            </p>
            <div className={styles.row}>
              <Select
                options={Object.values(Currency)}
                onClickOption={(option: string) => onClickSelect(option)}
                optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
                required
                id="currency"
                label={t('taskDetail.modalSolved.currency')}
                placeholder={t('taskDetail.modalSolved.currency')}
                value={formData.currency}
                inputStyle={InputStyle.FORM}
                containerClass={styles.currency}
                formError={formErrors}
                t={t}
              />

              <Input
                containerClass={styles.input}
                required
                id="billedAmount"
                label="$"
                inputStyle={InputStyle.FORM}
                value={formData.billedAmount}
                onChange={handleChange}
                formError={formErrors}
                helperText={t('taskDetail.modalSolved.billImport')}
                type="number"
                min={1}
                t={t}
              />

              <InputDate
                containerClass={styles.input}
                required
                id="dueDate"
                name="dueDate"
                label={t('taskDetail.modalSolved.dueDate')}
                placeholder={t('taskDetail.modalSolved.dueDate')}
                value={formData.dueDate !== '' ? dayjs(formData.dueDate).toDate() : undefined}
                helperText="MM/DD/YYYY"
                formErrors={formErrors}
                onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'dueDate')}
                min={dayjs(startDate).toDate()}
                t={t}
                fromTop
              />
            </div>

          </>
          )}
          <div className={styles.buttons}>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              className={styles.button}
              onClick={() => close()}
            >
              {t('taskDetail.modalSolved.cancel')}
            </Button>
            <Button
              className={styles.button}
              buttonType={ButtonType.Submit}
            >
              {t('taskDetail.modalSolved.confirm')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { ModalSolvedTask };
export type { SolvedTaskModalType };
