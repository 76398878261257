import { Contractor } from 'models/contractor';
import { SpecializationSerializer } from './specialization-serializer';
import { ResourcesSerializer } from './resources-serializer';

class ContractorSerializer {
  static deSerialize(data: RawContractor): SerializedContractor {
    return {
      category: data.category,
      email: data.email,
      id: data.id,
      name: data.name,
      lastName: data.last_name,
      phoneNumber: data.phone_number,
      propertyId: data.property_id,
      countryCode: data.country_code,
    };
  }

  static serialize(data: Contractor): RawContractor {
    return {
      phone_number: data.phoneNumber,
      name: data.name,
      last_name: data.lastName,
      id: data.id,
      email: data.email,
      category: data.category,
      property_id: data.propertyId,
      country_code: data.countryCode,
    };
  }

  static serializeAssignContractor(data: AssignContractor): RawAssignContractor {
    return {
      contractor_id: data.contractorId,
      contractor_message: data.contractorMessage,
    };
  }

  static deSerializeMinimal(data: RawContractorMinimal): ContractorMinimal {
    return {
      id: data.id,
      name: data.name,
      lastName: data.last_name,
      specializations: SpecializationSerializer.deSerializeAllSpecializations(data.specializations),
      profilePhoto: data?.profile_photo
        ? ResourcesSerializer.deSerializeFileType(data.profile_photo) : undefined,
    };
  }

  static deSerializeMinimalArray(data: RawContractorMinimal[]): ContractorMinimal[] {
    return data.map((item) => this.deSerializeMinimal(item));
  }
}

export { ContractorSerializer };
