import { checkNumberInput } from 'helpers/utils';
import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import {
  BillArea, ContractorCategories, Currency, PropertyType,
} from 'common/enums';
import { RadioButton } from 'common/radio-button';
import { UnitNumberInput } from 'common/unit-number-input';
import { Select } from '@mapix/common/src/common/select';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ChangeEvent } from 'react';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { TextArea } from '@mapix/common/src/common/textarea';
import { useTranslation } from 'react-i18next';
import { BillCreate } from 'models/bill-create';
import { SimpleUnit } from 'models/simple-unit';
import styles from '../../pages/add-bill/add-bill.module.scss';

type BillFormProps = {
  propertyId: number,
  propertyType: string,
  formErrors: HTMLValidationError,
  bill: BillCreate,
  changeBillFn: (option: string, field: string) => void,
  onUnitSelectedFn: (unit: SimpleUnit) => void,
};

const translPrefix = 'addBill';
const BillForm = ({
  propertyId, formErrors, bill, changeBillFn, onUnitSelectedFn, propertyType,
}: BillFormProps) => {
  const { t } = useTranslation();

  const getDisabledValue = (billArea: BillCreate) => {
    if (billArea.affectedArea === BillArea.CommonArea) {
      return true;
    }

    return false;
  };

  const onChangeBillAmount = (e: ChangeEvent<HTMLInputElement>) => {
    if (checkNumberInput(e)) {
      changeBillFn(e.target.value, 'billedAmount');
    }
  };

  return (
    <>
      {propertyType === PropertyType.Building && !bill.taskId && (
        <>
          <p className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
            {t(`${translPrefix}.buildingArea`)}
          </p>

          <div className="row">
            <RadioButton
              required
              onChangeFn={() => changeBillFn(BillArea.CommonArea, 'affectedArea')}
              name="affectedArea"
              labelContent={t(`${translPrefix}.commonArea`)}
              labelClass={classnames(styles.label, 'text__body__medium__textNeutral40')}
              containerClass={styles.radios}
              checked={bill.affectedArea === BillArea.CommonArea}
            />
            <RadioButton
              required
              onChangeFn={() => changeBillFn(BillArea.Unit, 'affectedArea')}
              name="affectedArea"
              labelContent={t(`${translPrefix}.specificUnit`)}
              labelClass={classnames(styles.label, 'text__body__medium__textNeutral40')}
              containerClass={classnames(styles.radios, styles.second)}
              checked={bill.affectedArea === BillArea.Unit}
            />

            <UnitNumberInput
              disabled={getDisabledValue(bill)}
              prefix="unitSearch"
              propertyId={propertyId}
              onSelectUnitFn={onUnitSelectedFn}
              query={bill.unitNumber || ''}
            />

          </div>
        </>
      )}

      <p className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${translPrefix}.generalInformation`)}
      </p>

      <div className={styles.row}>
        <Select
          options={Object.values(ContractorCategories)}
          onClickOption={(option: string) => changeBillFn(option, 'category')}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="category"
          label={t(`${translPrefix}.form.category`)}
          placeholder={t(`${translPrefix}.form.category`)}
          value={bill.category ? `${t(`${translPrefix}.category.${bill.category}`)}` : ''}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          translationPrefix={`${translPrefix}.category`}
          t={t}
        />

        <Select
          options={Object.values(Currency)}
          onClickOption={(option: string) => changeBillFn(option, 'currency')}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="currency"
          label={t(`${translPrefix}.form.currency`)}
          placeholder={t(`${translPrefix}.form.currency`)}
          value={bill.currency || ''}
          inputStyle={InputStyle.FORM}
          containerClass={styles.smallInput}
          formError={formErrors}
          t={t}
        />

        <Input
          id="amount"
          containerClass={styles.input}
          label={t(`${translPrefix}.form.billedAmount`)}
          placeholder={t(`${translPrefix}.form.billedAmount`)}
          formError={formErrors}
          required
          value={bill.billedAmount || ''}
          inputStyle={InputStyle.FORM}
          onChange={onChangeBillAmount}
          t={t}
        />

        <InputDate
          id="dueDate"
          name="dueDate"
          label={t(`${translPrefix}.form.dueDate`)}
          placeholder={t(`${translPrefix}.form.dueDate`)}
          onChangeFn={(date: Date) => changeBillFn(dayjs(date).format('YYYY-MM-DD'), 'dueDate')}
          formErrors={formErrors}
          required
          value={bill.dueDate !== '' ? dayjs(bill.dueDate).toDate() : undefined}
          helperText={t(`${translPrefix}.form.dateHelper`)}
          containerClass={styles.input}
          t={t}
        />

      </div>

      <p className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${translPrefix}.additionalComments`)}
      </p>

      <TextArea
        id="additionalComments"
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => changeBillFn(e.target.value, 'additionalComments')}
        placeholder={t(`${translPrefix}.form.additionalCommentsPlaceholder`)}
        maxLength={200}
        value={bill.additionalComments}
        containerClass={styles.textarea}
        t={t}
      />

      <p className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${translPrefix}.paidBill`)}
      </p>

      <div className="row">
        <RadioButton
          required
          onChangeFn={() => changeBillFn('no', 'billPaid')}
          name="paidBill"
          labelContent={t(`${translPrefix}.form.paidNo`)}
          labelClass={classnames(styles.label, 'text__body__medium__textNeutral40')}
          containerClass={styles.radios}
          checked={bill.billPaid === 'no'}
        />
        <RadioButton
          required
          onChangeFn={() => changeBillFn('yes', 'billPaid')}
          name="paidBill"
          labelContent={t(`${translPrefix}.form.paidYes`)}
          labelClass={classnames(styles.label, 'text__body__medium__textNeutral40')}
          containerClass={classnames(styles.radios, styles.second)}
          checked={bill.billPaid === 'yes'}
        />

        <InputDate
          id="paymentDate"
          name="paymentDate"
          label={t(`${translPrefix}.form.paymentDate`)}
          placeholder={t(`${translPrefix}.form.paymentDate`)}
          onChangeFn={(date: Date) => changeBillFn(dayjs(date).format('YYYY-MM-DD'), 'paymentDate')}
          formErrors={formErrors}
          required={bill.billPaid === 'yes'}
          value={bill.paymentDate ? dayjs(bill.paymentDate).toDate() : undefined}
          helperText={t(`${translPrefix}.form.dateHelper`)}
          containerClass={styles.input}
          t={t}
        />

      </div>
    </>
  );
};

export { BillForm };
