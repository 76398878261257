import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints, RentalsStatus } from 'common/enums';
import { MakePayment } from '@mapix/common/src/common/make-payment';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context';
import { RentInfo } from '@mapix/common/src/common/rent-info';
import { useContext, useState } from 'react';
import { ReactComponent as CheckFilled } from 'assets/icons/check-filled.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { RentalTenant } from 'models/rental-tenant';
import { logger } from 'helpers/logger';
import { ReactComponent as User } from '@mapix/common/src/assets/icons/user16.svg';

import { goToPage, RouteName } from 'routes';
import { CreatePaymentController } from 'networking/controllers/create-payment-controller';
import { OpenOrderData, PaymentMethod as PaymentMethodType, UpdatePayment } from '@mapix/common/src/types';
import { MakePaymentModalState } from '@mapix/common/src/common/enums';
import styles from './payments-tenant.module.scss';

type PaymentCardProps = {
  rental: RentalTenant,
  contactFn: (rental: RentalTenant) => void,
  storedCards: PaymentMethodType[],
  updatePaymentFn: (updatePayment: UpdatePayment) => void,
  refreshData: () => void
  deletePaymentMethod: (id: number) => Promise<void>
  toggleShowInfoModal: () => void

};

const translPrefix = 'tenantPayments.card';

const PaymentCard = ({
  rental, contactFn, storedCards, updatePaymentFn, refreshData,
  deletePaymentMethod, toggleShowInfoModal,
}: PaymentCardProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const [paymentMethod, setPaymentMethod] = useState(false);

  const openOrderFn = async (openOrderData: OpenOrderData, setCreatePaymentResult: (
    createPaymentResult: MakePaymentModalState) => void) => {
    try {
      return await CreatePaymentController.openOrder(openOrderData);
    } catch (err) {
      logger.error(err as Error);
      setCreatePaymentResult(MakePaymentModalState.ShowNuveiError);
      return null;
    }
  };

  const handleOnClickPayNow = () => {
    if (!mobile) {
      return setPaymentMethod(true);
    }
    return goToPage(RouteName.MakePaymentPage, { rentalId: rental.id });
  };

  return (
    <>
      {paymentMethod && (
      <MakePayment
        onClose={() => setPaymentMethod(false)}
        t={t}
        email={state.user.email}
        rental={rental}
        openOrderFn={openOrderFn}
        storedCards={storedCards}
        updatePaymentFn={updatePaymentFn}
        refreshData={refreshData}
        deletePaymentMethod={deletePaymentMethod}
        toggleShowInfoModal={toggleShowInfoModal}
      />
      )}
      <div className={styles.paymentCard}>
        {mobile
          ? (
            <>
              <RentInfo
                rental={rental}
                t={t}
                translPrefix={translPrefix}
                toggleShowInfoModal={toggleShowInfoModal}
              />

              {rental.paymentDate
                ? (
                  <div className={styles.iconContainer}>
                    <CheckFilled className={styles.icon} />
                  </div>
                )
                : (
                  <div className={styles.buttonContainer}>
                    <button className={styles.buttonText} onClick={() => contactFn(rental)} type="button">
                      <div className="row text__body__medium__primary40">
                        {t(`${translPrefix}.contactInfo`)}
                        <User className={styles.userIcon} />
                      </div>
                    </button>
                    {(rental.status !== RentalsStatus.Paid) && (
                    <Button
                      onClick={handleOnClickPayNow}
                      buttonStyle={ButtonStyle.Primary}
                      className={styles.payNowButton}
                    >
                      <div className="text__button__medium__surface10">
                        {t(`${translPrefix}.payNow`)}
                      </div>
                    </Button>
                    )}
                  </div>
                )}

            </>
          )
          : (
            <div className={styles.cardInfo}>
              <RentInfo
                rental={rental}
                t={t}
                translPrefix={translPrefix}
                toggleShowInfoModal={toggleShowInfoModal}
                separatorMoreMargin
              />

              <div className={styles.buttonContainer}>
                {rental.paymentDate
                  ? <CheckFilled className={styles.icon} />
                  : (
                    <Button
                      onClick={() => contactFn(rental)}
                      buttonStyle={ButtonStyle.Primary}
                    >
                      <div className="text__button__medium__surface10">
                        {t(`${translPrefix}.contactInfo`)}
                      </div>
                    </Button>
                  )}
                {(rental.status !== RentalsStatus.Paid) && (
                <Button
                  onClick={handleOnClickPayNow}
                  buttonStyle={ButtonStyle.Primary}
                  className={styles.payNowButton}
                >
                  <div className="text__button__medium__surface10">
                    {t(`${translPrefix}.payNow`)}
                  </div>
                </Button>
                )}
              </div>

            </div>
          )}
      </div>
    </>
  );
};

export { PaymentCard };
