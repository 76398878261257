import { Unit } from 'models/unit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { goToPage, RouteName } from 'routes';
import { getDetailCardMonth, getExpirationTitle } from 'helpers/utils';
import { classnames } from '@mapix/common/src/helpers/utils';
import { DetailCard, DetailCardItem } from 'common/detail-card/detail-card';
import { Condition, PropertyStatus } from 'common/enums';
import { Tab } from '@mapix/common/src/common/tab';
import { UnitGeneralInfoTab } from 'pages/property-detail/unit-tabs/unit-general-info-tab';
import { BillsTable } from 'common/bills-table';
import { CommonDashboard } from 'pages/rentals-dashboard/common-dashboard';
import styles from './property-detail.module.scss';
import { MaintenanceTasksTab } from './common-tabs/maintenance-tab/maintenance-tasks-tab';

const translPrefix = 'propertyDetail';

const rentalHeaders = ['fees', 'tenant', 'dueDate', 'paidOn', 'status'];
const rentalDataProperties = ['billedAmount', 'tenant', 'dueDate', 'paidOn', 'status'];

const breadcrumbs = (
  breadcrumbStarter: string,
  propertyName: string,
  unitNumber: string,
  propertyId: number,
): BreadcrumbsItem[] => [
  {
    name: breadcrumbStarter,
    goToPageFn: () => goToPage(RouteName.Properties),
  },
  {
    name: propertyName,
    goToPageFn: () => goToPage(RouteName.PropertyDetail, { id: propertyId }),
  },
  {
    name: unitNumber,
    goToPageFn: () => null,
  },
];

const billHeaders = ['billedAmount', 'taskCategory', 'taskId', 'dueDate', 'paidOn', 'status'];
const billDataProperties = ['billedAmount', 'category', 'taskId', 'dueDate', 'paidOn', 'status'];

const getPanelItems = (unit: Unit, t: (value: string) => string): DetailCardItem[] => {
  const availableTasks = {
    indicator: unit.inProgressTasksCount.toString(),
    indicatorClass: classnames(styles.inProgressTask, 'text__heading5__info40'),
    title: t(`${translPrefix}.cards.taskInProgressTitle`),
    subTitle: t(`${translPrefix}.cards.taskInProgressSubTitle`),
    titleClass: 'text__body__large__info40',
  };

  if (unit.status === PropertyStatus.Available) {
    return [availableTasks];
  }
  return ([
    {
      indicator: unit.newTasksCount.toString(),
      indicatorClass: classnames(styles.task, 'text__heading5__primary70'),
      title: t(`${translPrefix}.cards.taskTitle`),
      subTitle: t(`${translPrefix}.cards.taskSubTitle`),
      titleClass: 'text__body__large__primary70',
    },
    availableTasks,
    {
      indicator: unit.overdueRentalsCount.toString(),
      indicatorClass: classnames(styles.overdue, 'text__heading5__yellow50'),
      title: t(`${translPrefix}.cards.overdueTitle`),
      subTitle: t(`${translPrefix}.cards.overdueSubTitle`),
      titleClass: 'text__body__large__yellow50',
    },
    {
      isDate: true,
      monthClass: 'text__body__tiny__success40',
      month: getDetailCardMonth(unit.lease?.expirationDate).substr(0, 3),
      indicator: unit.lease?.expirationDate.split('-')[2] || 'N/A',
      indicatorClass: classnames(styles.occupancy, 'text__body__large__success40'),
      title: getExpirationTitle(unit.lease?.expirationDate),
      subTitle: t(`${translPrefix}.cards.expirationSubTitle`),
      titleClass: 'text__body__large__success50',
    },
  ]);
};

const tabs = [
  'generalInfo', 'maintenance', 'rentals', 'bills',
];

type UnitDetailProps = {
  unit: Unit,
  propertyId: number,
  propertyName: string,
  fullAddress: string,
  propertyCondition: string
};

const UnitDetail = ({
  unit, propertyId, propertyName, fullAddress, propertyCondition,
}: UnitDetailProps) => {
  const [tab, setTab] = useState<string>('generalInfo');

  const { t } = useTranslation();

  const changeTab = (newTab: string) => {
    setTab(newTab);
  };
  return (
    <div className={styles.detailContainer}>
      <Breadcrumbs
        values={breadcrumbs(t(`${translPrefix}.breadcrumbStarter`), propertyName || 'Building', `Unit ${unit.unitNumber}`, propertyId)}
      />

      <div className={styles.dashContainer}>

        <div className="text__heading4__textNeutral40">
          {`Unit ${unit.unitNumber} ${propertyName ? ` - ${propertyName}` : ''} - ${fullAddress}`}
        </div>

        <div className={classnames(styles.buildingInfo, 'text__body__small__textNeutral30')}>
          {t(`${translPrefix}.infoUnit`)}
        </div>

        {propertyCondition !== Condition.Archived && (
          <div className={classnames(styles.cardsContainer, 'row')}>
            {getPanelItems(unit, t).map((item) => (
              <DetailCard key={item.title} item={item} cardWidth="25%" />
            ))}
          </div>
        )}

        <div className={styles.tabContainer}>
          {tabs.map((itemTab) => (
            <Tab
              key={itemTab}
              name={t(`${translPrefix}.tabs.${itemTab}`)}
              isActive={tab === itemTab}
              onClickFn={() => changeTab(itemTab)}
            />
          ))}
        </div>

        {tab === 'generalInfo' && (
          <UnitGeneralInfoTab
            unit={unit}
            buildingName={propertyName}
            fullAddress={fullAddress}
            propertyId={propertyId}
          />
        )}
        {tab === 'maintenance' && (
          <MaintenanceTasksTab
            id={propertyId}
            type="Unit"
            unitId={unit.id}
          />
        )}
        {tab === 'bills' && (
          <BillsTable
            fixedDataProperties={billDataProperties}
            fixedTableHeader={billHeaders}
            fromPropertyDashboard
            unitId={unit.id}
          />
        )}
        {tab === 'rentals' && (
          <CommonDashboard
            id={propertyId}
            unitId={unit.id}
            tableHeaders={rentalHeaders}
            tableDataProperties={rentalDataProperties}
            showCreateRental={!!unit.lease}
          />
        )}

      </div>

    </div>
  );
};

export { UnitDetail };
