import { ChangeEvent, FormEvent, useState } from 'react';
import { GenericErrorModal } from '../generic-error-modal';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { ReactComponent as Tool } from '../../assets/icons/tool.svg';
import { logger } from '../../helpers/logger';
import { Spinner } from '../spinner';
import { ModifyRate, TypeOfCost } from '../../types';
import { checkHTMLErrors, classnames } from '../../helpers/utils';
import { Input, InputStyle } from '../input';
import { TextArea } from '../textarea';
import { ModalInformation } from '../modal-information';
import { Select } from '../select';
import styles from './modify-rate-modals.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';

type ModifyRateModalsProps = {
  handleButtonClose: () => void,
  t: (key: string) => string,
  confirmationModal?: boolean,
  modifyRateApiCall: (modifyRateData: ModifyRate) => Promise<any>,
  previousTypeOfCost: TypeOfCost,
};

export type ModifyRateData = {
  message: string
  hourlyRateOrBudget: number | '' | null,
  amountOfHours: number | '',
  typeOfCost: 'hourlyRate' | 'budget' | null
};

const translPrefix = 'modifyRateModals';

const getHourlyRateOrBudget = (previousTypeOfCost: TypeOfCost) => {
  if (!previousTypeOfCost.typeOfCost) return '';

  if (previousTypeOfCost.typeOfCost === 'budget') {
    return previousTypeOfCost.budget;
  }

  return previousTypeOfCost.hourlyRate;
};

const initModifyRate = (previousTypeOfCost: TypeOfCost): ModifyRateData => ({
  typeOfCost: previousTypeOfCost.typeOfCost,
  hourlyRateOrBudget: getHourlyRateOrBudget(previousTypeOfCost),
  message: '',
  amountOfHours: previousTypeOfCost.amountOfHours || '',
});

enum ModalState {
  ConfirmationModal,
  ModifyModal,
  SuccessModal,
  ErrorModal,
}

const ModifyRateModals = ({
  handleButtonClose, t, confirmationModal = false, modifyRateApiCall, previousTypeOfCost,
}: ModifyRateModalsProps) => {
  const [modalState, setModalState] = useState<ModalState>(
    confirmationModal ? ModalState.ConfirmationModal : ModalState.ModifyModal,
  );
  const [modifyRate, setModifyRateData] = useState<ModifyRateData>(
    initModifyRate(previousTypeOfCost),
  );
  const [formErrors, setFormErrors] = useState({});
  const [fetching, setFetching] = useState(false);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleSelect = (option: string) => {
    setModifyRateData((prevState) => ({
      ...prevState, typeOfCost: option as HourlyRateOrBudget,
    }));
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setModifyRateData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  type HourlyRateOrBudget = 'budget' | 'hourlyRate';

  const options = ['budget', 'hourlyRate'];

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }
    setFetching(true);

    const modifyRateData: any = {
      amountOfHours: modifyRate.amountOfHours,
      typeOfCost: modifyRate.typeOfCost,
      message: modifyRate.message,
    };

    if (modifyRate.typeOfCost === 'budget') {
      modifyRateData.budget = modifyRate.hourlyRateOrBudget;
    } else {
      modifyRateData.hourlyRate = modifyRate.hourlyRateOrBudget;
    }
    try {
      await modifyRateApiCall(modifyRateData);
      setModalState(ModalState.SuccessModal);
    } catch (error) {
      logger.log(error);
      setModalState(ModalState.ErrorModal);
    } finally {
      setFetching(false);
    }
  };

  if (modalState === ModalState.ConfirmationModal) {
    return (
      <ModalInformation
        handleButtonClose={handleButtonClose}
        handleButtonRight={() => setModalState(ModalState.ModifyModal)}
        title={t(`${translPrefix}.title`)}
        buttonTextCenter={t('cancel')}
        buttonTextRight={t('modify')}
        handleButtonCenter={() => {}}
        body={t(`${translPrefix}.body`)}
        containerClass={mobile ? '' : styles.modal}
      />

    );
  }

  if (fetching) return <Spinner />;

  if (modalState === ModalState.SuccessModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.successModal.title`)}
        subtitle={t(`${translPrefix}.successModal.subtitle`)}
        Icon={Tool}
        buttonTextRight={t('ok')}
        handleButtonClose={handleButtonClose}
        handleButtonRight={handleButtonClose}
        modalAccentColor={ModalAccentColor.GREEN}
        withBackground
      />
    );
  }

  if (modalState === ModalState.ErrorModal) {
    return (
      <GenericErrorModal
        handleButtonClose={handleButtonClose}
        handleOk={handleButtonClose}
        t={t}
      />
    );
  }

  return (
    <ModalInformation
      handleButtonClose={handleButtonClose}
      handleButtonRight={() => {}}
      title={t(`${translPrefix}.title`)}
      buttonTextCenter={t('cancel')}
      buttonTextRight={t('modify')}
      handleButtonCenter={() => {}}
      containerClass={mobile ? '' : styles.modal}
      onSubmit={handleSubmit}
    >
      <div className={styles.container}>
        <div className={styles.topInputsWrapper}>
          <div className={styles.typeOfCostWrapper}>
            <Select
              options={options}
              onClickOption={(option: string) => handleSelect(option)}
              optionTextClass={classnames(styles.inputSmall, 'text__body__medium__textNeutral50')}
              required
              id="costType"
              name="costType"
              value={t(`${translPrefix}.costType.${modifyRate.typeOfCost}`)}
              label={t(`${translPrefix}.costType.label`)}
              inputStyle={InputStyle.FORM}
              formError={formErrors}
              t={t}
              translationPrefix={`${translPrefix}.costType`}
              containerClass={styles.inputSmall}
            />
            <Input
              id="hourlyRateOrBudget"
              name="hourlyRateOrBudget"
              t={t}
              inputStyle={InputStyle.FORM}
              formError={formErrors}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
              type="number"
              required
              price
              containerClass={styles.inputSmall}
              value={modifyRate.hourlyRateOrBudget || ''}
            />
          </div>

          <Input
            id="amountOfHours"
            name="amountOfHours"
            label={t(`${translPrefix}.hoursToFinish.placeholder`)}
            placeholder={t(`${translPrefix}.hoursToFinish.placeholder`)}
            helperText={t(`${translPrefix}.hoursToFinish.helperText`)}
            t={t}
            inputStyle={InputStyle.FORM}
            formError={formErrors}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            type="number"
            required={modifyRate.typeOfCost === 'hourlyRate'}
            value={modifyRate.amountOfHours}

          />
        </div>
        {mobile && (<hr className={styles.divider} />)}

        <TextArea
          id="message"
          name="message"
          t={t}
          value={modifyRate.message}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeInput(e)}
          placeholder={t(`${translPrefix}.message.placeholder`)}
          maxLength={200}
          required
          formError={formErrors}
        />
      </div>
    </ModalInformation>
  );
};

export { ModifyRateModals };
