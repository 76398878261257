import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ApiError } from 'models/api-error';
import { UserController } from 'networking/controllers/user-controller';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './resend-confirm-email-button.module.scss';

const ResendConfirmEmailButton = () => {
  const { t } = useTranslation();

  const [serverError, setServerError] = useState(false);
  const [resendEmailError, setResendEmailError] = useState(false);
  const [showSend, setShowSend] = useState(false);

  const translPrefix = 'verifyUser.resendEmail';

  const handleResendEmail = async () => {
    try {
      await UserController.resendConfirmEmail();
      setShowSend(true);
    } catch (err) {
      if (err instanceof ApiError && err.code === 4022) {
        setResendEmailError(true);
      }
      setServerError(true);
    }
  };

  return (
    <div className={styles.container}>
      <Button
        buttonStyle={ButtonStyle.Link}
        onClick={() => handleResendEmail()}
        className="text__body__medium__primary"
      >
        {t(`${translPrefix}.resendEmailButton`)}
      </Button>
      {showSend
      && (
      <ModalResult
        title={t(`${translPrefix}.title`)}
        subtitle={t(`${translPrefix}.subtitle`)}
        Icon={Email}
        buttonTextRight={t(`${translPrefix}.ok`)}
        handleButtonRight={() => setShowSend(false)}
        handleButtonClose={() => setShowSend(false)}
        withCheckIcon
      />
      )}
      {serverError
      && (
        <ErrorMessage
          message={resendEmailError ? t(`${translPrefix}.subtitle`) : t('error.errorMessage')}
          handleClose={() => setServerError(false)}
        />
      )}
    </div>
  );
};

export { ResendConfirmEmailButton };
