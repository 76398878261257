import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints, DateCodes } from 'common/enums';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { SelectProperty } from 'common/select-property';
import { FilterByDate } from 'common/filter-by-date';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { PaymentCard } from 'pages/payments-tenant/payment-card';
import { RouteName } from 'routes';
import { List, Menu } from 'common/menu/menu';
import { ContactInfo } from 'pages/payments-tenant/contact-info';
import { RentalTenant } from 'models/rental-tenant';
import { Paginator } from 'models/paginator';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { AppContext } from 'context';
import { Pagination } from '@mapix/common/src/common/paginator';
import { EmptyState } from 'common/empty-state';
import { PaymentMethod as PaymentMethodType, UpdatePayment } from '@mapix/common/src/types';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { CreatePaymentController } from 'networking/controllers/create-payment-controller';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import styles from './payments-tenant.module.scss';

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: DateCodes.All,
};

type State = {
  paginator: Paginator<RentalTenant>,
  code: string
  showContactInfo: boolean
  selectedRental?: RentalTenant
  dateFilter: DateFilter,
};

const initialState: State = {
  paginator: new Paginator<RentalTenant>(null, []),
  code: 'FETCHING',
  showContactInfo: false,
  dateFilter: initDateFilter,
};

const translPrefix = 'tenantPayments';
const PaymentsTenant = () => {
  const [paymentState, setPaymentState] = useState<State>(initialState);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [storedCards, setStoredCards] = useState<PaymentMethodType[]>([]);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { t } = useTranslation();

  const { state } = useContext(AppContext);

  const breadCrumbs: List[] = [
    { name: t(`${translPrefix}.breadcrumbHome`), route: RouteName.HomeTenant },
    { name: t(`${translPrefix}.breadcrumbPayment`), route: RouteName.PaymentsTenant },
  ];

  const getRentals = async () => {
    setPaymentState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    try {
      const response = await RentalsController
        .getTenantRentals(
          state.property.id,
          paymentState.paginator.currentPage,
          paymentState.paginator.pageSize,
          paymentState.dateFilter,
          state.property.unitId,
        );

      setPaymentState((prevState) => ({
        ...prevState,
        code: 'READY',
        paginator: response,
      }));
    } catch (err: any) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (state.property.id) {
      getRentals();
    }
  }, [state.property, paymentState.paginator.currentPage, paymentState.dateFilter]);

  const showContactInfo = (rental: RentalTenant) => {
    setPaymentState(
      (prevState) => ({ ...prevState, showContactInfo: true, selectedRental: rental }),
    );
  };

  const closeContactInfo = () => {
    setPaymentState((prevState) => ({ ...prevState, showContactInfo: false }));
  };

  const changePage = (newPage: number) => {
    const newPaginator = { ...paymentState.paginator };
    newPaginator.currentPage = newPage;
    setPaymentState((prevState) => ({ ...prevState, paginator: newPaginator }));
  };

  const applyFilterDate = (startDate: string, endDate: string, code: string) => {
    const newDate: DateFilter = {
      code,
      startDate,
      endDate,
    };
    setPaymentState((prevState) => ({ ...prevState, dateFilter: newDate }));
  };

  const clearFilters = () => {
    setPaymentState((prevState) => ({ ...prevState, dateFilter: initDateFilter }));
  };

  const storedCardsFn = async () => {
    try {
      const response = await CreatePaymentController.getPaymentMethod();
      setStoredCards(response);
      return response;
    } catch (err) {
      logger.error(err as Error);
      return [];
    }
  };

  const refreshData = async () => {
    getRentals();
    storedCardsFn();
  };

  const updatePaymentFn = async (updatePayment: UpdatePayment) => {
    try {
      await CreatePaymentController.updatePayment(updatePayment);
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const deletePaymentMethodFn = async (id: number) => {
    await CreatePaymentController.deletePaymentMethod(id);
    await storedCardsFn();
  };

  useEffect(() => {
    storedCardsFn();
  }, []);

  return (
    <>
      {showInfoModal && (
      <ModalInformation
        handleButtonClose={() => setShowInfoModal(false)}
        handleButtonRight={() => setShowInfoModal(false)}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
      >
        <div className={classnames('text__body__medium__textNeutral50', styles.modalText)}>
          {t(`${translPrefix}.card.amountTooltip`)}
        </div>
      </ModalInformation>
      )}
      {paymentState.showContactInfo && paymentState.selectedRental
        ? (paymentState.selectedRental.managers.map((manager) => (
          <ContactInfo
            owner={manager}
            closeFn={closeContactInfo}
            key={manager.id}
          />
        ))
        )
        : (
          <div className={styles.container}>

            {mobile && <Menu className={styles.menu} list={breadCrumbs} />}

            <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral40'}>
              {t(`${translPrefix}.title`)}
            </div>
            {!mobile && (
            <div className="text__body__small__textNeutral30">{t(`${translPrefix}.subtitle`)}</div>
            )}
            <>
              <div className={styles.showingContainer}>
                <div className="text__body__small__textNeutral50">{t(`${translPrefix}.showing`)}</div>
                <div className={styles.select}>
                  <SelectProperty />
                </div>
              </div>
            </>

            <div className={styles.filterContainer}>
              <div className={styles.filter}>
                <FilterByDate
                  onClickOption={applyFilterDate}
                  mobile={mobile}
                />
              </div>
            </div>

            {paymentState.code === 'FETCHING'
              ? (<Spinner />)
              : (
                <>
                  {paymentState.paginator.results.length > 0
                    ? (
                      <>
                        {paymentState.paginator.results.map((rental) => (
                          <div key={rental.id} className={styles.paymentCardContainer}>
                            <PaymentCard
                              contactFn={showContactInfo}
                              rental={rental}
                              storedCards={storedCards}
                              key={rental.id}
                              updatePaymentFn={updatePaymentFn}
                              refreshData={refreshData}
                              deletePaymentMethod={deletePaymentMethodFn}
                              toggleShowInfoModal={() => setShowInfoModal(true)}
                            />
                          </div>
                        ))}
                        {paymentState.paginator.lastPage > 1
                      && (
                        <div className={styles.paginationContainer}>
                          <Pagination
                            pageLimit={paymentState.paginator.lastPage}
                            onClickFn={(newPage: number) => changePage(newPage)}
                            currentPage={paymentState.paginator.currentPage}
                          />
                        </div>
                      )}
                      </>
                    )
                    : (
                      <div className={styles.emptyState}>
                        <EmptyState
                          Icon={NoResultsIcon}
                          title={t('emptyState.search')}
                          handleOnClick={clearFilters}
                        />
                      </div>
                    )}
                </>
              )}
          </div>
        )}
    </>
  );
};

export { PaymentsTenant };
