import { OpenOrderData, RawUpdatePayment, UpdatePayment } from '@mapix/common/src/types';
import { API_ROUTES } from 'networking/api-routes';
import { CreatePaymentSerializer } from 'networking/serializers/create-payment-serializer';
import { ApiService } from '../api-service';

class CreatePaymentController {
  static async openOrder(data: OpenOrderData) {
    const serializedOpenOrderData = CreatePaymentSerializer.serialize(data);
    const result = await ApiService
      .post<RawOpenOrderResponse>(API_ROUTES.OPEN_ORDER, serializedOpenOrderData);
    return CreatePaymentSerializer.deserialize(result.data);
  }

  static async updatePayment(data: UpdatePayment) {
    const serializedUpdatePayment = CreatePaymentSerializer.serializeUpdatePayment(data);
    return ApiService.patch<RawUpdatePayment>(API_ROUTES.OPEN_ORDER, serializedUpdatePayment);
  }

  static async getPaymentMethod() {
    const results = await ApiService.get<RawPaymentMethod[]>(API_ROUTES.GET_PAYMENT_METHOD);
    return results.data.map((item) => CreatePaymentSerializer.deserializePaymentMethod(item));
  }

  static async deletePaymentMethod(id: number) {
    return ApiService.delete(API_ROUTES.DELETE_PAYMENT_METHOD(id));
  }
}
export { CreatePaymentController };
