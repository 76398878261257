import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Modal } from '@mapix/common/src/common/modal';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '@mapix/common/src/common/button/button';
import styles from './modal-reject-task.module.scss';

type ModalProps = {
  close: () => void,
  rejectTask: (reason: string) => void,
};

const ModalRejectTask = ({ close, rejectTask }: ModalProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };
  const handleSend = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      rejectTask(reason);
    }
  };
  return (
    <Modal>
      <form onSubmit={handleSend} noValidate>
        <div className={styles.modalContainer}>
          <div className={styles.crossContainer}>
            <button type="button" onClick={() => close()}>
              <Cross className={styles.crossIcon} />
            </button>
          </div>
          <div className={styles.centered}>
            <div className={styles.containerIcon}>
              <Tool className={styles.icon} />
              <Alert
                className={styles.alertIcon}
              />
            </div>
            <p className="text__heading5__textNeutral50">
              {t('taskDetail.modalReject.title')}
            </p>
          </div>
          <p className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>
            {t('taskDetail.modalReject.subtitle')}
          </p>
          <Input
            id="reason"
            label="reason"
            placeholder={t('taskDetail.modalReject.placeholder')}
            inputStyle={InputStyle.FORM}
            value={reason}
            onChange={handleChange}
            maxLength={140}
            required
            formError={formErrors}
            t={t}
          />
          <p className={classnames(styles.count, 'text__body__small__textNeutral40')}>
            {reason.length}
            {' '}
            / 140
          </p>
          <div className={styles.buttons}>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              className={styles.button}
              onClick={() => close()}
            >
              {t('taskDetail.modalReject.cancel')}
            </Button>
            <Button className={styles.button} buttonType={ButtonType.Submit}>{t('taskDetail.modalReject.confirmAndSend')}</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { ModalRejectTask };
