import { ReactComponent as Check } from '../../assets/icons/check-filled.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { ReactComponent as Alert } from '../../assets/icons/alertFilled.svg';
import { ReactComponent as AlertCircle } from '../../assets/icons/alert-circle-filled.svg';
import { Button, ButtonStyle } from '../button';
import { classnames } from '../../helpers/utils';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { Modal } from '../modal';
import styles from './modal-result.module.scss';

enum ModalAccentColor {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  NEUTRAL = 'NEUTRAL',
}

type ModalProps = {
  title: string,
  subtitle?: string,
  Icon: any,
  buttonTextLeft?: string,
  buttonTextRight: string,
  buttonLeftStyle?: string
  buttonRightStyle?: string
  handleButtonLeft?: () => void,
  handleButtonRight: () => void,
  handleButtonClose?: () => void,
  iconStyle?: string,
  buttonStyle?: ButtonStyle,
  cross?: boolean,
  titleTextStyle?: string,
  modalStyle?: string,
  modalAccentColor?: ModalAccentColor
  withBackground?: boolean
};

function handleIconContainerClass(modalAccentColor: ModalAccentColor) {
  switch (modalAccentColor) {
    case ModalAccentColor.RED:
      return styles.containerIconRed;
    case ModalAccentColor.GREEN:
      return styles.containerIconGreen;
    case ModalAccentColor.YELLOW:
      return styles.containerIconYellow;
    default:
      return '';
  }
}

function handleIconClass(modalAccentColor: ModalAccentColor) {
  switch (modalAccentColor) {
    case ModalAccentColor.RED:
      return styles.iconRed;
    case ModalAccentColor.GREEN:
      return styles.iconGreen;
    case ModalAccentColor.YELLOW:
      return styles.iconYellow;
    default:
      return '';
  }
}

function modalAuxiliarIcon(modalAccentColor: ModalAccentColor) {
  switch (modalAccentColor) {
    case ModalAccentColor.RED:
      return (<Alert className={styles.auxiliarIcon} />);
    case ModalAccentColor.GREEN:
      return (<Check className={styles.auxiliarIcon} />);
    case ModalAccentColor.YELLOW:
      return (<AlertCircle className={styles.auxiliarIcon} />);
    default:
      return undefined;
  }
}

const ModalResult = ({
  title, subtitle, Icon, buttonTextLeft, buttonTextRight,
  handleButtonLeft, handleButtonRight, handleButtonClose, iconStyle,
  buttonStyle = ButtonStyle.Primary, cross = true, titleTextStyle = '',
  modalStyle = '', buttonLeftStyle = '', buttonRightStyle = '', withBackground = false, modalAccentColor = ModalAccentColor.NEUTRAL,
}: ModalProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <Modal withBackground={withBackground}>
      <div className={modalStyle || styles.modalContainer}>
        <div className={styles.center}>
          {cross && (
          <div className={styles.crossContainer}>
            <button type="button" onClick={handleButtonClose}>
              <Cross className={styles.crossIcon} />
            </button>
          </div>
          )}
          <div className={styles.iconWrapper}>
            <div className={handleIconContainerClass(modalAccentColor)}>
              <Icon className={iconStyle || handleIconClass(modalAccentColor)} />
              {modalAuxiliarIcon(modalAccentColor)}
            </div>
          </div>
          <p className={classnames(titleTextStyle || (mobile ? 'text__body__heading4__textNeutral50' : 'text__heading5__textNeutral50'), styles.title)}>
            {title}
          </p>
          <p className={classnames(styles.subtitle, 'text__body__medium__textNeutral40')}>
            {subtitle}
          </p>
          <div className={buttonTextLeft ? styles.buttons : styles.oneButton}>
            {!!buttonTextLeft
          && (
          <Button
            buttonStyle={ButtonStyle.Secondary}
            className={classnames('text__body__large__textNeutral10', styles.marginRight, buttonLeftStyle || styles.button)}
            onClick={handleButtonLeft}
          >
            {buttonTextLeft}
          </Button>
          )}
            <Button
              buttonStyle={buttonStyle || styles.button}
              className={classnames('text__body__large__textNeutral10', buttonRightStyle || styles.button)}
              onClick={handleButtonRight}
            >
              {buttonTextRight}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalResult, ModalAccentColor };
