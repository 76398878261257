import { ButtonClose } from '@mapix/common/src/common/button-close';
import { Breakpoints, BuildingArea, ContractorCategories } from 'common/enums';
import { FileUpload } from 'common/image-upload';
import { InputStyle } from '@mapix/common/src/common/input';
import { InputDate } from '@mapix/common/src/common/input-date';
import { Select } from '@mapix/common/src/common/select';
import { TextArea } from '@mapix/common/src/common/textarea';
import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { RadioButton } from 'common/radio-button';
import { AppContext } from 'context';
import dayjs from 'dayjs';
import { constants } from 'config/constants';
import styles from './create-edit-claim.module.scss';

type CommonFormProps = {
  claim: SerializedCreateClaim,
  setClaim: (claim: SerializedCreateClaim) => void,
  formErrors: HTMLValidationError,
  edit?: boolean,
  setBuildingCommonArea?: (buildingCommonArea: boolean) => void,
};

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

const CommonForm = ({
  claim, setClaim, formErrors, edit = false, setBuildingCommonArea,
}: CommonFormProps) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleChange = (event: ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>) => {
    const newClaim = {
      ...claim,
      description: event.target.value,
    };
    setClaim(newClaim);
  };

  const onChangeDateFn = (date: string, option: string) => {
    const newClaim = {
      ...claim,
      [option]: date,
    };
    setClaim(newClaim);
  };

  const onClickSelect = (option: string, objField: string) => {
    const newClaim = {
      ...claim,
      [objField]: option,
    };
    setClaim(newClaim);
  };

  const setPhotos = (photos: FileType[]) => {
    const newClaim = {
      ...claim,
    };
    newClaim.photos = photos;
    setClaim(newClaim);
  };

  const setArea = (area: string) => {
    if (area === 'common' && setBuildingCommonArea !== undefined) {
      setBuildingCommonArea(true);
    }
  };

  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.column}>
          <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral50'}>
            {edit ? t('editClaim.title') : t('createClaim.title')}
          </div>
          <div className={classnames(styles.subtitle, mobile ? 'text__body__large__textNeutral40' : 'text__heading5__textNeutral50')}>
            {`${t('createClaim.for')}: ${state.property.fullAddress}`}
          </div>
        </div>
        <div className={styles.button}>
          <ButtonClose closeFn={() => goToPage(RouteName.Claims)} closeText={t('myProfile.close')} />
        </div>
      </div>
      <>
        {state.property.unitId
        && setBuildingCommonArea !== undefined && (
        <>

          {mobile
            && <div className={classnames('text__heading3__textNeutral50', styles.info)}>{t('createClaim.info')}</div>}
          <div className={classnames(styles.firstMargin, mobile ? 'text__body__large__textNeutral40'
            : 'text__heading6__textNeutral50')}
          >
            {t('createClaim.area.title')}
          </div>
          <div className={styles.checkboxContainer}>
            <div className={styles.area}>
              {Object.keys(BuildingArea).map((item) => (
                <RadioButton
                  key={item}
                  max={1}
                  onChangeFn={() => setArea(item)}
                  className={styles.input}
                  maxLength={1}
                  containerClass={styles.checkbox}
                  labelClass="text__body__medium__textNeutral40"
                  labelContent={t(`createClaim.area.${(item)}`)}
                  name="area"
                />
              ))}
            </div>
          </div>
        </>
        )}
      </>
      {mobile && !edit
      && <div className={classnames(styles.subtitleMobileMargin, styles.bold, 'text__heading3__textNeutral50')}>{t('createClaim.claimInfo')}</div>}
      {!mobile
      && <div className={classnames('text__heading6__textNeutral50', styles.firstMargin)}>{t('createClaim.claim')}</div>}
      <Select
        options={Object.values(ContractorCategories)}
        onClickOption={(option) => onClickSelect(option, 'category')}
        optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
        required
        id="category"
        name="category"
        label={t('createClaim.category')}
        value={claim?.category ? `${t(`maintenanceDashboard.filters.category.${claim?.category}`)}` : ''}
        placeholder={t('createClaim.select')}
        inputStyle={InputStyle.FORM}
        containerClass={classnames(styles.input, styles.large)}
        formError={formErrors}
        translationPrefix="maintenanceDashboard.filters.category"
        t={t}
      />
      {!mobile
      && <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createClaim.issueStartDate')}</div>}
      <InputDate
        containerClass={classnames(styles.input, styles.large, styles.inputMargin)}
        required
        id="startDate"
        name="startDate"
        label={t('createClaim.issueStartDate')}
        placeholder={t('createClaim.issueStartDate')}
        value={claim?.startDate !== '' ? dayjs(claim.startDate).toDate() : undefined}
        formErrors={formErrors}
        onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'startDate')}
        helperText="DD/MM/YYYY"
        t={t}
      />
      {!mobile
      && <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createClaim.description')}</div>}
      <TextArea
        required
        id="description"
        placeholder={t('createClaim.descriptionPlaceholder')}
        value={claim?.description}
        maxLength={500}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e)}
        containerClass={classnames(styles.input, styles.description, styles.inputMargin)}
        formError={formErrors}
        t={t}
      />
      <div className={classnames(styles.margin, mobile
        ? (classnames(styles.bold, 'text__heading3__textNeutral50'))
        : 'text__heading6__textNeutral50')}
      >
        {t('createClaim.photos.title')}
      </div>
      <FileUpload
        fetchedFiles={claim?.photos || []}
        fileAddedFn={(files: FileType[]) => setPhotos(files)}
        title={t('createTask.photos.description')}
        restrictionText={t('createClaim.photos.size', { size: imageMaxSize / (1000 * 1000), amount: MAX_PHOTO_AMOUNT })}
      />
    </>
  );
};

export { CommonForm };
