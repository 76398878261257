import { ReactNode } from 'react';
import { classnames } from '../../helpers/utils';
import styles from './tooltip.module.scss';

export enum TooltipPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

type TooltipProps = {
  textComponent: ReactNode,
  hoverComponent: ReactNode,
  containerWidth?: string,
  position?: TooltipPosition,
  hideTooltip?: boolean,
};

const Tooltip = ({
  textComponent,
  hoverComponent,
  containerWidth = '141px',
  position = TooltipPosition.Right,
  hideTooltip = false,
}: TooltipProps) => {
  const textClasses = classnames(styles.text, {
    [styles.topPositioned]: position === TooltipPosition.Top,
    [styles.rightPositioned]: position === TooltipPosition.Right,
    [styles.bottomPositioned]: position === TooltipPosition.Bottom,
    [styles.leftPositioned]: position === TooltipPosition.Left,
    [styles.hidden]: hideTooltip,
  });

  return (
    <div className={styles.tooltip}>
      {textComponent}
      <span style={{ width: containerWidth }} className={textClasses}>
        {hoverComponent}
      </span>
    </div>
  );
};

export { Tooltip };
