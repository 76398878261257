import { useState } from 'react';
import { logger } from '../../../helpers/logger';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { Breakpoints, RawPrivateRequestStatus, RawNewTaskEvent } from '../../enums';
import { Pill, PillColor, PillIcon } from '../../pill';
import { capitalizeFirstLetter, classnames } from '../../../helpers/utils';
import { Button, ButtonStyle } from '../../button';
import { SimpleMessage } from '../../simple-message';
import { ModifyRateModals } from '../../modify-rate-modals';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import {
  BasicUserFields, ModifyRate, PrivateRequestEvent, TypeOfCost,
} from '../../../types';
import styles from './rate-tab.module.scss';

type RateTabProps = {
  privateRequestEvents: PrivateRequestEvent[];
  rawStatus: RawPrivateRequestStatus;
  privateRequestId: number;
  contractorMessage?: string;
  managerMessage?: string;
  contractor: BasicUserFields;
  manager: BasicUserFields;
  typeOfCost: TypeOfCost;
  refreshData: () => Promise<void>,
  modifyRate: (id: number, modifyRateData: ModifyRate) => Promise<any>,
  notifyAcceptRate: () => void;
  t: (text: string) => string,
  isContractor?: boolean,
};

const translPrefix = 'newTaskDetail.tabs.rate';

const getLastEvent = (privateRequestEvents: PrivateRequestEvent[]) => {
  if (privateRequestEvents.length) {
    return privateRequestEvents[privateRequestEvents.length - 1];
  }

  return undefined;
};

const parsePillContent = (
  status: RawPrivateRequestStatus,
  t: (text: string) => string,
) => {
  switch (status) {
    case RawPrivateRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
      return t(`${translPrefix}.pills.waitingForContractorApproval`);
    case RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL:
      return t(`${translPrefix}.pills.waitingForManagerApproval`);
    case RawPrivateRequestStatus.ACCEPTED:
      return t(`${translPrefix}.pills.accepted`);
    default:
      return '';
  }
};

const RateTab = ({
  privateRequestEvents, modifyRate, contractorMessage, managerMessage, rawStatus,
  contractor, manager, typeOfCost, privateRequestId, notifyAcceptRate, t,
  isContractor, refreshData,
}: RateTabProps) => {
  const [showModifyRate, setShowModifyRate] = useState(false);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const showModifyButton = isContractor
    ? rawStatus === RawPrivateRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL
    : rawStatus === RawPrivateRequestStatus.WAITING_FOR_MANAGER_APPROVAL;

  const rateAccepted = rawStatus === RawPrivateRequestStatus.ACCEPTED;

  const lastEvent = getLastEvent(privateRequestEvents);

  const oldRate = lastEvent
    ? t(`$${lastEvent.oldTypeOfCost === 'budget'
      ? lastEvent.oldBudget : lastEvent.oldHourlyRate}`)
    : undefined;

  const handleButtonClose = async () => {
    setShowModifyRate(false);
    try {
      await refreshData();
    } catch (err) {
      logger.log(err);
    }
  };

  return (
    <>
      {(rateAccepted || mobile) && (
      <div className={classnames(mobile ? 'text__heading2__textNeutral50' : 'text__body__large__textNeutral40', styles.rateTitle)}>
        {t(`${translPrefix}.tabName`)}
      </div>
      )}
      <Pill
        content={parsePillContent(rawStatus, t)}
        color={rateAccepted ? PillColor.Green : PillColor.Yellow}
        textStyle={classnames('text__body__xsmall__textNeutral10', styles.ratePill)}
        icon={rateAccepted ? PillIcon.Check : PillIcon.Alert}
      />
      {(lastEvent) && (

      <SimpleMessage
        senderLastName={lastEvent.eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
          ? contractor.lastName : manager.lastName}
        senderName={lastEvent.eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
          ? contractor.name : manager.name}
        text={lastEvent.messageToContractor || lastEvent.messageToManager
        || contractorMessage || managerMessage!}
        containerClass={styles.message}
      />
      )}

      <hr className={styles.divider} />
      <div className={showModifyButton ? styles.column : styles.spaceBetween}>
        <div>

          <div className={styles.margin}>
            <span className="text__body__medium__textNeutral40">
              {t(typeOfCost.typeOfCost === 'budget' ? `${translPrefix}.budget` : `${translPrefix}.hourlyRate`)}
            </span>
            {oldRate && (
            <span className={classnames('text__body__medium__warning50', styles.priceDashed)}>
              {oldRate}
            </span>
            )}
            <span className={classnames('text__body__medium__primary50', styles.price)}>
              {t(`$${typeOfCost.typeOfCost === 'budget' ? typeOfCost.budget : typeOfCost.hourlyRate}`)}

            </span>
          </div>
          <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
            { typeOfCost.amountOfHours && (
            <span>
              {`${
                capitalizeFirstLetter(t(`${translPrefix}.hours`))
              }: ${typeOfCost.amountOfHours} ${
                typeOfCost.amountOfHours === 1 ? t(`${translPrefix}.hour`) : t(`${translPrefix}.hours`)
              }`}
            </span>
            )}
          </div>
          <Button
            buttonStyle={ButtonStyle.Back}
            className={classnames('text__body__medium__info30', styles.taskHistory)}
            onClick={() => {}}
          >
            {t(`${translPrefix}.seeTaskHistory`)}
          </Button>
        </div>
        <div className={styles.acceptModifyWrapper}>

          {showModifyButton && (
          <Button
            buttonStyle={ButtonStyle.Primary}
            className={classnames('text__body__medium__textNeutral40', styles.accept)}
            onClick={notifyAcceptRate}
          >
            {t('accept')}
          </Button>
          )}
          {
            // TODO
            // Button will be currently available only if the other party modified the rate
            // Left the ternary operator for future use
          }
          {showModifyButton && (
          <Button
            buttonStyle={ButtonStyle.Back}
            className={classnames('text__body__medium__primary50', styles.modify)}
            onClick={() => setShowModifyRate(true)}
          >
            <EditIcon className={styles.editIcon} />
            {`${t(`${translPrefix}.modify`)}${showModifyButton
              ? t(`${translPrefix}.resubmit`) : ''}` }
          </Button>
          )}
        </div>
        {showModifyRate && (
        <ModifyRateModals
          handleButtonClose={handleButtonClose}
          modifyRateApiCall={(
            modifyRateData: ModifyRate,
          ) => modifyRate(privateRequestId, modifyRateData)}
          previousTypeOfCost={typeOfCost}
          t={t}
          confirmationModal
        />
        )}
      </div>
    </>
  );
};

export { RateTab };
