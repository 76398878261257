import React from 'react';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ContractorCategoryIcon } from '@mapix/common/src/common/contractor-category-icon';
import { AvatarOrInitials } from '@mapix/common/src/common/avatar-or-initials';
import { ReactComponent as ChevronRight } from '@mapix/common/src/assets/icons/chevron-right.svg';
import styles from './contractor-card.module.scss';

type ContractorCardProps = {
  name: string,
  lastName: string,
  categories: string[],
  t: (text: string) => string,
  url?: string,
  containerClass?: string,
};

const translPrefix = 'contractorCard';

const ContractorCard = ({
  name, lastName, t, url, containerClass, categories,
}: ContractorCardProps) => (
  <div className={classnames(styles.cardContainer, containerClass || '')}>
    <AvatarOrInitials
      firstName={name}
      lastName={lastName}
      className={styles.avatar}
      url={url}
    />
    <div className={styles.cardInfo}>
      <div>
        <span
          className={classnames(styles.cardName, 'text__body__large__textNeutral50')}
        >
          {`${name} ${lastName}`}

        </span>
        <span
          className={classnames(styles.cardRole, 'text__body__medium__textNeutral30')}
        >
          {t(`${translPrefix}.assignedContractor`)}
        </span>
      </div>
      <div className={styles.categoryIconWrapper}>
        {categories.map((category) => (
          <ContractorCategoryIcon
            key={category}
            category={category}
            className={styles.categoryIcon}
          />
        ))}
      </div>
      <button
        type="button"
        className={classnames(styles.button)}
      >
        <ChevronRight className={styles.chevron} />
      </button>
    </div>

  </div>
);

export { ContractorCard };
