import {
  useReducer, useEffect, useState, useRef,
} from 'react';
import { CreateHeader, CreateFooter } from 'common/creation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ModalResult } from 'common/modal-result';
import { classnames } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { Task } from 'models/task';
import { AssignContractorContainer } from 'common/assign-contractor-container-v2';
import { useHistory, useParams } from 'react-router-dom';
import { TaskType } from 'common/enums';
import { RouteName, goToPage } from 'routes';
import { getCityCommaProvinceId } from 'helpers/utils';
import { NewTask } from 'networking/types';
import { CreateNewTaskStep1 } from './create-edit-new-task-step-1';
import { CreateNewTaskStep2 } from './create-edit-new-task-step-2';
import { CreateNewTaskReducer, initialState } from './create-edit-new-task-reducer';
import styles from './create-edit-new-task.module.scss';

const translPrefix = 'createTask';
const translError = 'error';

// TODO link to task creation buttons
// As of now, the normal flow is to create a V1 task. This should be updated once we
// handle V2 tasks. In the meantime, can access this page by the route /create-new-task

type ParamType = {
  id: string,
};

type CreateTaskState = {
  code: string,
  showError: boolean,
  property?: Property,
  task?: Task,
};

const initialStepState: CreateTaskState = {
  code: 'INITIAL',
  showError: false,
};

const CreateEditNewTask = () => {
  const params = new URLSearchParams(window.location.search);
  const propertyId = params.get('propertyId');
  const { id } = useParams<ParamType>();
  const history = useHistory();

  const [state, dispatch] = useReducer(CreateNewTaskReducer, initialState(Number(propertyId)));
  const [stepState, setStepState] = useState(initialStepState);
  const [fromPropertyDashboard, setFromPropertyDashboard] = useState(false);
  const { t } = useTranslation();

  const taskCreated = useRef<NewTask | null>(null);

  const close = () => {
    history.goBack();
  };

  const setFinishModal = () => {
    if (state.taskType === TaskType.Unpublished) {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_LATER' });
    } else {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_TASK' });
    }
  };

  const toggleErrorModal = () => {
    setStepState((prevState) => ({ ...prevState, showError: !stepState.showError }));
  };

  const setContractorData = (field: string, value: string) => {
    dispatch({
      type: 'CHANGE_FIELD', field, value,
    });
  };

  const saveTask = async (task: CreateNewTaskData) => {
    try {
      if (id) {
        await MaintenanceController.updateNewTask(task, Number(id));
      } else {
        const taskResponse = await MaintenanceController.createNewTask(task);
        taskCreated.current = taskResponse;
      }
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  const getProperty = async () => {
    setStepState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    try {
      const fetchedProperty = await PropertyController.getDetailedProperty(propertyId!);
      setStepState((prevState) => (
        {
          ...prevState,
          code: 'FROM_DASHBOARD',
        }));
      dispatch({
        type: 'STEPS', steps: ['Complete task info', 'Assign to a contractor'],
      });
      dispatch({ type: 'GO_NEXT' });
      const address = fetchedProperty.address.fullAddress;
      const idProperty = fetchedProperty.id;
      const location = getCityCommaProvinceId(
        fetchedProperty.address.city,
        fetchedProperty.address.provinceId,
      );
      const { type } = fetchedProperty;
      dispatch({
        type: 'PROPERTY_CHANGED',
        propertyAddress: address || '',
        buildingType: type === 'Building',
        propertyId: idProperty,
        location,
      });
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  const getTaskInformation = async () => {
    try {
      const fetchedTask = await MaintenanceController.getNewTask(id);
      if (fetchedTask.taskType === TaskType.Unpublished) {
        dispatch({ type: 'SET_NEW_TASK', taskData: fetchedTask });
      } else {
        goToPage(RouteName.ErrorPage);
      }
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  useEffect(() => {
    if (state.finish) {
      saveTask(state);
    }
  }, [state.finish]);

  useEffect(() => {
    if (id) {
      getTaskInformation();
    }
    if (propertyId) {
      setFromPropertyDashboard(true);
      getProperty();
    }
  }, []);

  if (stepState.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      <CreateHeader
        closeText={t('createGeneric.close')}
        title={t(`createTask.${id ? 'editTitle' : 'title'}`)}
        closeFn={close}
        currentStep={state.step}
        stepper={state.steps.map((step) => t(step))}
      />
      {state.step >= 1 && (
      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {`${state.buildingName ? `${state.buildingName} -` : t(`${translPrefix}.type.condoHouse`)} ${state.propertyAddress}`}
      </div>
      )}
      {state.errorModal
      && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}
      {state.modalAssignTask
      && (
      <>
        { (state.contractorId) ? (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        ) : (
          <ModalResult
            title={t(`${translPrefix}.modalAssignError.title`)}
            subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
            Icon={Tool}
            iconStyle={styles.iconStyle}
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
            handleButtonClose={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
          />
        )}
      </>
      )}
      {
        state.modalAssignLater && (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorNotAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }
      {(state.step === 0)
      && (
      <CreateNewTaskStep1
        dispatch={dispatch}
        propertyAddress={state.propertyAddress}
        show={state.show}
        query={state.query}
        data={state.data}
      />
      )}

      {state.step === 1
      && (
      <CreateNewTaskStep2
        dispatch={dispatch}
        formErrors={state.formErrors}
        createTaskInfo={state}
        isValidUnitNumber={state.area !== 'specific' || !!state.unitNumber}
        fromPropertyDashboard={fromPropertyDashboard}
        submitUnpublishedTask={setFinishModal}
      />
      )}

      {state.step === 2
      && (
        <>
          <AssignContractorContainer
            category={state.category.name}
            propertyId={state.propertyId as number}
            onChange={setContractorData}
            infoContractor={state.infoContractor}
            location={state.location}
          />
          <CreateFooter
            nextName={t('createTask.footer.assignTask')}
            showBack
            Icon={null}
            backFn={() => dispatch({ type: 'GO_BACK' })}
            nextFn={setFinishModal}
            disableNextCondition={!state.contractorId}
          />
        </>
      )}

    </div>
  );
};

export { CreateEditNewTask };
