import { ContractorCard } from 'common/contractor-card';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Alert } from '@mapix/common/src/assets/icons/alert-filled.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { PrivateRequest } from 'networking/types';
import { TaskStatus } from '@mapix/common/src/common/enums';
import styles from './status-tab.module.scss';

type ContractorDetailsProps = {
  privateRequest: PrivateRequest,
  t: (key: string) => string,
  translPrefix: string,
  taskStatus: TaskStatus | '',
  changeContractorFn: () => void,
};

const ContractorDetails = ({
  privateRequest, t, translPrefix, taskStatus, changeContractorFn = () => {},
}: ContractorDetailsProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const showChangeContractor = taskStatus === TaskStatus.NEGOTIATING;

  const showContractorMessage = taskStatus === TaskStatus.NEGOTIATING;

  const showDetailsTitle = taskStatus === TaskStatus.UNASSIGNED;

  return (
    <>
      {showDetailsTitle && (<hr className={styles.divider} />)}
      <div className={styles.contractorCardWrapper}>
        <div>
          {showDetailsTitle && (
            <>
              <div className={classnames(
                `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
                styles.lighterText,
              )}
              >
                {t(`${translPrefix}.request`)}
              </div>
              <div className={classnames(
                styles.sentTo,
                `text__body__${mobile ? 'large' : 'medium'}__textNeutral50`,
              )}
              >
                {t(`${translPrefix}.sentTo`)}
              </div>
            </>
          )}
          <ContractorCard
         // TODO change once this value is serialized in backend
            categories={['Electricity']}
            lastName={privateRequest.contractor.lastName}
            name={privateRequest.contractor.name}
            t={t}
            containerClass={styles.contractorCard}
          />
        </div>
        {showChangeContractor && (
        <div>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            className={styles.button}
            onClick={() => changeContractorFn()}
          >
            {t(`${translPrefix}.changeAssignedContractor`)}
          </Button>
          <div className="row">
            <Alert className={styles.alert} />
            <div className="text__body__small__textNeutral30">
              {t(`${translPrefix}.disclaimer`)}
            </div>
          </div>
        </div>
        )}
        {showContractorMessage && (
        <div>
          <div className={classnames('text__body__medium__textNeutral50', styles.contractorMessage)}>
            {`${t('message')}:`}
          </div>
          <div className="text__body__medium__textNeutral30">{privateRequest.contractorMessage}</div>
        </div>
        )}
      </div>
    </>
  );
};

export { ContractorDetails };
