import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ChangeEvent, useEffect } from 'react';
import { checkNumberInput, checkTextInput } from 'helpers/utils';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { PropertyAddress } from 'models/property-address';
import { Coordinates, MapView } from '@mapix/common/src/common/map-view';
import { useGeocoding } from '@mapix/common/src/hooks/geocoding';
import { City, PaginatorContainer } from '@mapix/common/src/types';
import { FilteredSelect, FilteredSelectBehaivor } from '@mapix/common/src/common/filtered-select';
import styles from './form.module.scss';

type AddressFormProps = {
  formErrors: HTMLValidationError,
  onChange: (e: ChangeEvent<HTMLInputElement>, type: string) => void,
  address: PropertyAddress,
  onClickSelect: (option: City) => void,
  getCities: (
    pageNumber: number, pageSize: number, query: string) => Promise<PaginatorContainer<City>>
  showUnitNumber?: boolean,
  onChangeCoordinates: (coordinates: Coordinates) => void,
};

const addressTranslPrefix = 'createBuilding.address';
const AddressForm = ({
  formErrors, onChange, address, onClickSelect, showUnitNumber = false, getCities,
  onChangeCoordinates,
}: AddressFormProps) => {
  const { t } = useTranslation();

  const [coordinates, setLocation] = useGeocoding();

  const onChangeAddress = (input: string, e: ChangeEvent<HTMLInputElement>) => {
    if ((input === 'city' && checkTextInput(e)) || (input === 'civicNumber' && checkNumberInput(e))) {
      onChange(e, 'address');
    }
  };

  const locationInformationCompleted = !!(
    address.civicNumber && address.streetName && address.city);

  useEffect(
    () => {
      if (locationInformationCompleted) {
        setLocation({
          city: address.city,
          street: `${address.civicNumber} ${address.streetName}`,
        });
      }
    },
    [address.civicNumber, address.streetName, address.city],
  );

  useEffect(
    () => {
      if (coordinates) {
        onChangeCoordinates(coordinates);
      }
    },
    [coordinates],
  );

  // Default location is in the middle of Canada
  const location = [58.25, -102.25] as Coordinates;

  return (
    <>
      <div className={styles.formContainer}>

        <MapView
          mapCenter={(address.lat || address.long) ? [address.lat, address.long] : location}
          mapContainerClass={styles.map}
          draggableMarker
          setCenter={onChangeCoordinates}
          containerClass={styles.mapContainer}
          t={t}
          zoom={(address.lat || address.long) ? undefined : 3}
        />

        <div>
          <FilteredSelect
            id="cityId"
            name="cityId"
            getOptionsCall={getCities}
            label={t(`${addressTranslPrefix}.cityLabel`)}
            placeholder={t(`${addressTranslPrefix}.cityPlaceholder`)}
            helperText={t(`${addressTranslPrefix}.cityHelper`)}
            t={t}
            behavior={FilteredSelectBehaivor.PAGINATED_API_CALL}
            query={address.city}
            onSelectOption={(option) => onClickSelect(option)}
            formError={formErrors}
            inputStyle={InputStyle.FORM}
            required
            mapKeyFn={(city: City) => `${city.name}, ${city.provinceId}`}
          />

          <div className={styles.rowWithMargins}>

            <Input
              required
              id="streetName"
              name="streetName"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'address')}
              inputStyle={InputStyle.FORM}
              value={address.streetName}
              label={t(`${addressTranslPrefix}.streetLabel`)}
              placeholder={t(`${addressTranslPrefix}.streetLabel`)}
              helperText={t(`${addressTranslPrefix}.streetHelper`)}
              formError={formErrors}
              containerClass={showUnitNumber ? styles.streetSmall : styles.street}
              t={t}
            />

            <Input
              required
              min={1}
              id="civicNumber"
              name="civicNumber"
              label={t(`${addressTranslPrefix}.civicNumberLabel`)}
              placeholder={t(`${addressTranslPrefix}.civicNumberLabel`)}
              helperText={t(`${addressTranslPrefix}.civicNumberHelper`)}
              formError={formErrors}
              value={address.civicNumber || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeAddress('civicNumber', e)}
              inputStyle={InputStyle.FORM}
              containerClass={styles.smallInput}
              t={t}
            />

            <Input
              required
              type="string"
              id="zipCode"
              name="zipCode"
              label={t(`${addressTranslPrefix}.zipCodeLabel`)}
              placeholder={t(`${addressTranslPrefix}.zipCodeLabel`)}
              helperText={formErrors.zipCode}
              formError={formErrors}
              value={address.zipCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'address')}
              inputStyle={InputStyle.FORM}
              containerClass={styles.smallInput}
              t={t}
            />

            {showUnitNumber && (
            <Input
              id="unitNumber"
              name="unitNumber"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'address')}
              inputStyle={InputStyle.FORM}
              value={address.unitNumber}
              label={t(`${addressTranslPrefix}.unitNumberLabel`)}
              placeholder={t(`${addressTranslPrefix}.unitNumberLabel`)}
              formError={formErrors}
              containerClass={styles.smallInput}
              t={t}
            />
            )}

          </div>
        </div>
      </div>

    </>
  );
};

export { AddressForm };
